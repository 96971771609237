import React, { createContext, useState } from "react";

export const usersConected = createContext();

function CamsConected({ children }) {
  const [users, setUsers] = useState([]);
  const [screens, setScreens] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [tracks, setTracks] = useState();
  const [localScreen, setLocalScreen] = useState();
  const [userConnectedImages, setUserConnectedImages] = useState([]);
  const [isCloudRecording, setIsCloudRecording] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [sID, setSID] = useState();

  return (
    <usersConected.Provider
      value={{
        users,
        setUsers,
        screens,
        setScreens,
        tracks,
        setTracks,
        localScreen,
        setLocalScreen,
        speakers,
        setSpeakers,
        userConnectedImages,
        setUserConnectedImages,
        isCloudRecording,
        setIsCloudRecording,
        sID,
        setSID,
        inProcess,
        setInProcess,
      }}
    >
      {children}
    </usersConected.Provider>
  );
}

export default CamsConected;
