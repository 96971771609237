import React, { useState } from "react";
import VideoNetworkings from "./VideoNetworkings";

function VideoWrapperNetworkings({ token, ids, children }) {
  return (
    <section className="videoMain-2">
      <VideoNetworkings
        token={token}
        ids={ids}
      >
        {children}
      </VideoNetworkings>
    </section>
  );
}

export default VideoWrapperNetworkings;
