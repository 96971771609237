import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { actionsContext } from "../../context/Actions/ActionsControl";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import Button from "../button/button";
import useRegisterActivity from "../../Hooks/useRegisterActivity";

function ControlsNetwork(props) {
  const { room, attendeeid, id } = useParams();
  const { dataUser } = useContext(dataUserContext);
  const { registerActivity } = useRegisterActivity();
  const { event, userSocket } = useContext(EventData);
  const { exitRoomSponsor } = useContext(actionsContext);
  const { rolAuditorium, setActionsUsers } = useContext(EventData);
  const [isOpen, setIsOpen] = useState(false);
  const { controls, variant } = props;

  const mute = async (type) => {
    if (type === "audio") {
      await controls.tracks[0].setEnabled(!controls.trackState.audio);
      controls.setTrackState((ps) => {
        return { ...ps, audio: !controls.trackState.audio };
      });
      setActionsUsers({
        type: "controls",
        room: room,
        command: "STATUS-MIC",
        to: controls.ids.uid,
        id: attendeeid,
        value: !controls.trackState.audio,
      });
    } else if (type === "video") {
      await controls.tracks[1].setEnabled(!controls.trackState.video);
      controls.setTrackState((ps) => {
        return { ...ps, video: !controls.trackState.video };
      });
    }
  };

  const deleteConexion = () => {
    var attendee = {
      command: "attendee",
      name: dataUser.name,
      room: room,
      action: "OUT", // Para indicar que ingreso  "OUT" Para indicar que salio
      id: attendeeid,
    };
    setActionsUsers(attendee);
    setTimeout(() => {
      window.location.href = `/${id}/agenda/attendee/${attendeeid}#networking`;
    }, 1000);
  };

  const leaveChannel = async () => {
    await controls.client.leave();
    controls.client.removeAllListeners();
    controls.desktop.leave();
    controls.desktop.removeAllListeners();
    // we close the tracks to perform cleanup
    controls.tracks[0].close();
    controls.tracks[1].close();
    controls.setStart(false);
    deleteConexion();
  };

  const muteAll = () => {
    var attendee = {
      type: "controls",
      name: dataUser.name,
      room: room,
      command: "MUTE-AUDIO-ALL",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
  };

  useEffect(() => {
    if (exitRoomSponsor) deleteConexion();
  }, [exitRoomSponsor]);

  useEffect(() => {
    if (
      userSocket.type === "controls" &&
      userSocket.command === "MUTE-AUDIO-ALL" &&
      controls.trackState.audio
    ) {
      mute("audio");
    }
    if (userSocket.type === "controls" && userSocket.command === "MUTE-AUDIO") {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      controls.trackState.audio &&
      userSocket.command === "MIC-OFF" &&
      userSocket.to === controls.ids.uid
    ) {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "CAMERA-OFF" &&
      userSocket.to === controls.ids.uid &&
      controls.trackState.video
    ) {
      mute("video");
      //mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "END" &&
      userSocket.to === controls.ids.uid
    ) {
      leaveChannel();
    }
  }, [userSocket]);

  return (
    <div className={`controls${variant}`}>
      <Button
        variant={controls.trackState.audio ? "controls-menu" : "off"}
        onClick={() => {
          mute("audio");
          registerActivity(
            "NETWORKING",
            controls?.trackState?.audio ? "MIC_OFF" : "MIC_ON"
          );
        }}
        icon={
          controls.trackState.audio
            ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
        }
      >
        <p className="tip-text">
          {controls.trackState.audio ? (
            <FormattedMessage id="controls.disabledMic" />
          ) : (
            <FormattedMessage id="controls.enabledMic" />
          )}
        </p>
      </Button>
      <Button
        variant={controls.trackState.video ? "controls-menu" : "off"}
        onClick={() => {
          mute("video");
          registerActivity(
            "NETWORKING",
            controls?.trackState?.video ? "CAM_OFF" : "CAM_ON"
          );
        }}
        icon={
          controls.trackState.video
            ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
        }
      >
        <p className="tip-text">
          {controls.trackState.video ? (
            <FormattedMessage id="controls.disabledCam" />
          ) : (
            <FormattedMessage id="controls.enabledCam" />
          )}
        </p>
      </Button>
      <Button
        variant={controls.localScreen ? "off-social" : "controls-menu"}
        onClick={
          controls.localScreen
            ? () => {
                controls.unSharedScreen();
                registerActivity("NETWORKING", "SCREEN_OFF");
              }
            : () => {
                controls.sharedScreen();
                registerActivity("NETWORKING", "SCREEN_ON");
              }
        }
        icon={
          !controls.localScreen
            ? `${process.env.REACT_APP_EVENT_IMG}/pantalla.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/pantalla-of.svg`
        }
      >
        <p className="tip-text">
          {controls.localScreen ? (
            <FormattedMessage id="controls.unshareScreen" />
          ) : (
            <FormattedMessage id="controls.shareScreen" />
          )}
        </p>
      </Button>
      <Button
        variant={!controls.fullScreen.active ? "controls-menu" : "off-social"}
        onClick={
          !controls.fullScreen.active
            ? () => {
                controls.fullScreen.enter();
                registerActivity("NETWORKING", "FSCREEN_ON");
              }
            : () => {
                controls.fullScreen.exit();
                registerActivity("NETWORKING", "FSCREEN_OFF");
              }
        }
        icon={
          !controls.fullScreen.active
            ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
        }
      >
        <p className="tip-text">
          {!controls.fullScreen.active ? (
            <FormattedMessage id="controls.fullScreen" />
          ) : (
            <FormattedMessage id="controls.closeFullScreen" />
          )}
        </p>
      </Button>
      <div
        className={"button--controls-menu mobile-none"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen && (
          <p className="tip-text2">
            <Button
              variant={"controls-menu2"}
              onClick={() => {
                controls.setBackgroundImage();
                registerActivity("NETWORKING", "BGROUND_ON");
              }}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-image.svg`}
            >
              <p className="tip-text3">Background image</p>
            </Button>
            <Button
              variant={"controls-menu2"}
              onClick={() => {
                controls.setBackgroundBlurring();
                registerActivity("NETWORKING", "BGROUND_ON");
              }}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-blur.svg`}
            >
              <p className="tip-text3">Background Blur</p>
            </Button>
            <Button
              variant={"controls-menu2"}
              onClick={() => {
                controls.setBackgroundColor();
                registerActivity("NETWORKING", "BGROUND_ON");
              }}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-color.svg`}
            >
              <p className="tip-text3">Background Color</p>
            </Button>
            {/* <Button
                variant={"controls-menu"}
                onClick={() => setBackgroundVideo()}
              >
                video
              </Button> */}
            <Button
              variant={"controls-menu2"}
              onClick={() => {
                controls.backgroundDisable();
                registerActivity("NETWORKING", "BGROUND_OFF");
              }}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-disabled.svg`}
            >
              <p className="tip-text3">Background Disable</p>
            </Button>
          </p>
        )}
        <p className="tip-text" style={{ fontWeight: "700" }}>
          Background
        </p>
        <img
          src={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-off.svg`}
          width="30"
        ></img>
      </div>
      {rolAuditorium.rol === "MANAGER" && (
        <Button
          variant="controls-menu"
          onClick={() => {
            muteAll();
            registerActivity("NETWORKING", "MIC_OFF_ALL");
          }}
          icon={`${process.env.REACT_APP_EVENT_IMG}/mics-off.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.muteAllMics" />
          </p>
        </Button>
      )}

      <Button
        variant="controls-menu"
        onClick={() => {
          leaveChannel();
          registerActivity("NETWORKING", "EXIT_ROOM");
        }}
        icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
      >
        <p className="tip-text">
          <FormattedMessage id="controls.exit" />
        </p>
      </Button>
    </div>
  );
}

export default ControlsNetwork;
