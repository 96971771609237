import React, { useContext, useEffect, useRef, useState } from "react";
import useRequests from "../../Hooks/UseRequests";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { FormattedMessage, useIntl } from "react-intl";

function CreateSurvey({ setSurveys, sendMsg, setOpenCreateSurvey }) {
  const intl = useIntl();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { setActionsUsers } = useContext(EventData);
  const { id, attendeeid } = useParams();
  const { postWithToken } = useRequests();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [dataOptions, setDataOptions] = useState({});
  const scroll = useRef();

  function addOption(e) {
    e.preventDefault();
    setOptions((prevOptions) => [
      ...prevOptions,
      `option-${options.length + 1}`,
    ]);
  }

  function handleChangeOptions(e) {
    setDataOptions({ ...dataOptions, [e.target.name]: e.target.value });
  }

  function handleChangeQuestion(e) {
    setQuestion(e.target.value);
  }

  function handleClickSend(e) {
    e.preventDefault();
    const responses = [];

    for (const key in dataOptions) {
      if (Object.hasOwnProperty.call(dataOptions, key)) {
        responses.push(dataOptions[key]);
      }
    }

    const body = {
      question,
      responses,
      votes: responses.map(() => 0),
    };

    postWithToken(
      `${process.env.REACT_APP_URI_API}/${id}/pools?att=${attendeeid}`,
      body
    ).then((result) => {
      setAlertIsOpen(true);
      setSurveys((prevSurveys) => [...prevSurveys, { ...body, id: result.id }]);
      const date = new Date();
      sendMsg({
        command: "CHAT",
        name: "Encuesta",
        message:
          "Se ha creado una nueva encuesta, ve a la pestaña de encuestas",
        date: date.toLocaleTimeString("en-US"),
        id: id,
      });

      setActionsUsers({
        type: "CREATE",
        command: "SURVEY",
        id: attendeeid,
        data: { ...body, id: result.id },
      });

      setTimeout(() => {
        setOpenCreateSurvey(false);
      }, 3000);
    });
  }

  useEffect(() => {
    if (options.length > 0) {
      scroll.current?.scrollIntoView({ block: "end" });
    }
  }, [options]);

  return (
    <div>
      {!alertIsOpen && (
        <form className="form-create-survey">
          <div>
            <input
              placeholder={intl.formatMessage({ id: "survey.question" })}
              className="input-question"
              onChange={handleChangeQuestion}
            ></input>
            <button onClick={handleClickSend} className="btn-lanzar">
              <FormattedMessage id="survey.launch" defaultMessage="Nombre" />
            </button>
          </div>
          <div className="inputs-survey">
            <input
              placeholder={intl.formatMessage({ id: "survey.option1" })}
              onChange={handleChangeOptions}
              name="option-1"
            ></input>
            {options &&
              options.map((item, i) => (
                <input
                  key={item}
                  placeholder={
                    intl.formatMessage({ id: "survey.option#" }) + (i + 2)
                  }
                  name={"option-" + (i + 2)}
                  onChange={handleChangeOptions}
                ></input>
              ))}
            <div>
              <input
                placeholder={
                  intl.formatMessage({ id: "survey.option#" }) +
                  (options.length + 2)
                }
                name={"option-" + (options.length + 2)}
                ref={scroll}
                onChange={handleChangeOptions}
              ></input>
              <button onClick={addOption} className="btn-add">
                +
              </button>
            </div>
          </div>
        </form>
      )}
      {alertIsOpen && (
        <h2>
          <FormattedMessage id="survey.alert" defaultMessage="Nombre" />
        </h2>
      )}
    </div>
  );
}

export default CreateSurvey;
