import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { langContext } from "../../context/lang/langContext";

import { useParams } from "react-router-dom";
import DropdownSponsors from "./DropdownSponsors/DropdownSponsors";
import { EventData } from "../../context/EventData/EventData";

function HeaderDesktop({ data, dataUser }) {
  const { event } = useContext(EventData);
  const { attendeeid } = useParams();
  const { establecerLenguaje, locale } = useContext(langContext);

  return (
    <>
      <header className="header">
        <div className="container flex">
          <img
            src={`${process.env.REACT_APP_EVENT_IMG}/logo-virtual-es.svg`}
            className="logo-header"
            alt="logo"
          />

          <ul
            className="nav"
            style={
              event &&
              event.event !== null &&
              event.event.site &&
              window.location.pathname !== "/" &&
              window.location.pathname !== `/event/${event.event.site}` &&
              window.location.pathname !== `/${event.event.site}`
                ? { width: "auto" }
                : { width: "100px" }
            }
          >
            {dataUser &&
              window.location.pathname !== `/${event.event.site}/agenda` && (
                <li>
                  <FormattedMessage
                    id="header.welcome"
                    defaultMessage="Bienvenido(a) "
                  />
                  {dataUser && dataUser.name ? dataUser.name : ""}
                </li>
              )}
            {dataUser &&
              window.location.pathname !== `/${event.event.site}/agenda` && (
                <li
                  className="nav-item"
                  hidden={window.location.pathname === `/`}
                >
                  |
                </li>
              )}
            {event &&
              event.event !== null &&
              event.event.site &&
              window.location.pathname === `/${event.event.site}/agenda` && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer" }}
                    className="nav-link bold"
                    href={`${event.event.site}`}
                  >
                    <FormattedMessage
                      id="header.home"
                      defaultMessage="Inicio"
                    />
                  </a>
                </li>
              )}{" "}
            {data &&
              event.event !== null &&
              data.event.site &&
              window.location.pathname !== `/${event.event.site}` &&
              window.location.pathname !== `/${event.event.site}/agenda` &&
              !dataUser && (
                <li
                  className="nav-item"
                  hidden={window.location.pathname === `/`}
                >
                  |
                </li>
              )}
            {event &&
              event.event !== null &&
              event.event.site &&
              window.location.pathname !== "/" &&
              window.location.pathname !== `/event/${event.event.site}` &&
              window.location.pathname !== `/${event.event.site}` &&
              attendeeid && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    href={`/${event.event.site}/agenda/attendee/${attendeeid}`}
                  >
                    <FormattedMessage
                      id="header.schedule"
                      defaultMessage="Agenda"
                    />
                  </a>
                </li>
              )}
            {event &&
              event.event !== null &&
              event.event.site &&
              window.location.pathname !== "/" &&
              window.location.pathname !== `/${event.event.site}` && (
                <li className="nav-item">|</li>
              )}
            {event &&
              event.event !== null &&
              event.event.site &&
              window.location.pathname !== "/" &&
              window.location.pathname !== `/${event.event.site}` &&
              window.location.pathname !== `/${event.event.site}/agenda` && (
                <li className="nav-item">
                  <DropdownSponsors
                    eventId={
                      event && event.event.id ? event.event.id : { event: "" }
                    }
                    site={
                      event && event.event.site
                        ? event.event.site
                        : { event: "" }
                    }
                  ></DropdownSponsors>
                </li>
              )}
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div>
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/reino-unido.png`}
                  alt="faq"
                  width="25"
                  height="25"
                  onClick={() => establecerLenguaje("en-US")}
                  style={
                    locale === "en-US"
                      ? {
                          border: "1px solid white",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          marginRight: "8px",
                        }
                      : {}
                  }
                />

                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/colombia.png`}
                  alt="faq"
                  width="25"
                  height="25"
                  onClick={() => establecerLenguaje("es-MX")}
                  style={
                    locale === "es-MX"
                      ? {
                          border: "1px solid white",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          marginLeft: "8px",
                        }
                      : {}
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default HeaderDesktop;
