import { useParams } from "react-router-dom";
import useRequests from "./UseRequests";
import useRegisterActivity from "./useRegisterActivity";
import { useContext, useEffect, useState } from "react";
import { dataUserContext } from "../context/DataUser/DataUser";
import { EventData } from "../context/EventData/EventData";

function useDataRoom() {
  const { attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { registerActivity } = useRegisterActivity();
  const [token, setToken] = useState();
  const [dataInfo, setdataInfo] = useState(false);
  const [dataInfoRoom, setdataInfoRoom] = useState(false);
  const { dataUser } = useContext(dataUserContext);
  const { event, setRolAuditorium } = useContext(EventData);

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${dataInfo[0].room}/token/${dataUser.rol}?name=${dataUser.name}?att=${attendeeid}`
    );
   
    setToken(res);
    setRolAuditorium(dataUser);
    registerActivity("PRINCIPAL", "ACCESS");
  }

  async function getDataInfoRoom() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/sites/${event.event.site}/${dataInfo[0].room}?att=${attendeeid}`
    );
    setdataInfoRoom(res.schedule[0]);
  }

  async function getDataInfo() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/schedules`
    );
    setdataInfo(res.schedule);
  }

  useEffect(() => {
    if (!token && dataInfo && dataUser) {
      getdata();
      getDataInfoRoom();
    }
    if (!dataInfo && event) {
      getDataInfo();
    }
  }, [dataInfo, event, dataUser]);

  return { token, dataInfo, dataInfoRoom };
}

export default useDataRoom;
