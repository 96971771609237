import React from "react";
import { FormattedMessage } from "react-intl";
import "./Footer.css";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className="footer fixed-bottom float-left">
      <div className="container footer-aling">
        <div className="formtextfooter">{`${process.env.REACT_APP_VERSION}`}</div>
        <div className="flex-footer">
          <a href="https://www.virtuales.io/" style={{ height: "100%" }}>
            <img
              src={`${process.env.REACT_APP_EVENT_IMG}/logo-virtual-es.svg`}
              className="logo-footer"
            ></img>
          </a>
          <div>
            <p className="formtextfooter">
              <FormattedMessage id="derechosReservadosp1" />
              {currentYear}
              <FormattedMessage id="derechosReservadosp2" />
              <a
                href="https://www.virtuales.io/privacy.html"
                target="_blank"
                style={{ color: "#00b4cf" }}
              >
                {" "}
                <FormattedMessage id="Politicas" />{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
