import React, { useContext, useEffect, useState } from "react";
import { EventData } from "../../context/EventData/EventData";
import { langContext } from "../../context/lang/langContext";
import ChatComponent from "../ChatComponent/ChatComponent";
import InfoEvent from "../main/BannerLogo/infoEvent";
import VideoWrapperNetworking from "./VideoWrapperNetworking";
import "./LightBox.css";
import { FormattedMessage } from "react-intl";
import { dataUserContext } from "../../context/DataUser/DataUser";
import Loading from "../Loading/Loading";
import CountDown from "../CountDown/CountDown";
import ActionsProvider from "../../context/Actions/ActionsControl";
import { useParams } from "react-router-dom";
import useRequests from "../../Hooks/UseRequests";

function MainNetworking() {
  const dataSponsorGet = localStorage.getItem("dataSponsor");
  const dataSponsor = JSON.parse(dataSponsorGet);
  const { room, attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { setRolAuditorium, controlPopup, event } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const { establecerLenguaje } = useContext(langContext);
  const [token, setToken] = useState(false);

  const getRolUser = async () => {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${dataSponsor.id}/rol?att=${attendeeid}`
    );
    setRolAuditorium(res);
  };

  useEffect(() => {
    if (dataSponsor && event)
      window.document.title = event.event.title + " / " + dataSponsor.name;
  }, [establecerLenguaje, dataSponsor, event]);

  useEffect(() => {
    if (
      dataUser &&
      dataUser.rol !== "MANAGER" &&
      dataUser.rol !== "ATTENDEE" &&
      event
    ) {
      getRolUser();
    } else if (event) {
      setRolAuditorium(dataUser);
    }
  }, [dataUser, event]);

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/token/${dataUser.rol}?name=${dataUser.name}?att=${attendeeid}`
    );
    setToken(res);
  }

  useEffect(() => {
    if (!token && dataUser && event) {
      getdata();
    }
  }, [dataUser, token, event]);

  return (
    <>
      {event ? (
        <ActionsProvider>
          {controlPopup && (
            <div className="light-box">
              <div className="box-light">
                <h1>
                  <FormattedMessage id="popup.closeRoom" />
                </h1>
              </div>
            </div>
          )}
          <InfoEvent
            data={{
              title: event.event.title,
              hours: event.event.hours,
              logo: event.event.logo,
              group: event.event.dates,
              url: event.event.url,
            }}
          />
          {dataUser && token ? (
            <section className="auditorium " style={{ marginTop: "20px" }}>
              <VideoWrapperNetworking data={dataSponsor} token={token} />
              <ChatComponent
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                dataEvent={dataSponsor}
                style={{ width: "100%", backgoundColor: "black" }}
                showAttendees={true}
              >
                <CountDown></CountDown>
              </ChatComponent>
            </section>
          ) : (
            <Loading></Loading>
          )}
        </ActionsProvider>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

export default MainNetworking;
