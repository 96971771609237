import React, { useContext, useEffect, useState } from "react";
import ChatComponent from "../ChatComponent/ChatComponent";
import InfoEvent from "../main/BannerLogo/infoEvent";
import VideoWrapperSponsor from "./VideoWrapperSponsor";
import BannerNextEvent from "../main/bannerNextEvents/marquee";
import "./RoomSponsor.css";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { langContext } from "../../context/lang/langContext";
import { dataUserContext } from "../../context/DataUser/DataUser";
import Loading from "../Loading/Loading";
import CountDown from "../CountDown/CountDown";
import { actionsContext } from "../../context/Actions/ActionsControl";
import { isKeyExists } from "../HomeContent/HomeContent";
import useRequests from "../../Hooks/UseRequests";

function MainRoomSponsor() {
  const { room, attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { establecerLenguaje } = useContext(langContext);
  const { dataSponsor, setDataSponsor, event } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const [validateRol, setValidateRol] = useState(false);
  const [token, setToken] = useState();
  const { startCounter } = useContext(actionsContext);

  const getDataSponsor = async () => {
    const response = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/sponsors/${room}?att=${attendeeid}`
    );

    response.event = event.event.id;
    setDataSponsor(response);
    localStorage.setItem("dataSponsor", JSON.stringify(response));
  };

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/token/SPONSOR?name=${dataUser.name}?att=${attendeeid}`
    );
    setToken(res);
  }

  useEffect(() => {
    if (event) getDataSponsor();
  }, [event]);

  useEffect(() => {
    if (dataSponsor && event) {
      window.document.title = event.event.title + " / " + dataSponsor.name;
    }
  }, [establecerLenguaje, dataSponsor, event]);

  useEffect(() => {
    if (!token && dataUser && event) {
      getdata();
    }
  }, [dataUser, token, event]);

  return dataUser && token && event ? (
    <>
      <InfoEvent
        data={{
          title: event.event.title,
          hours: event.event.hours,
          logo: event.event.logo,
          group: event.event.dates,
          url: event.event.url,
        }}
      />
      {dataSponsor && (
        <section className="auditorium " style={{ marginTop: "20px" }}>
          <VideoWrapperSponsor
            data={dataSponsor}
            validateRol={validateRol}
            setValidateRol={setValidateRol}
            getDataSponsor={getDataSponsor}
            token={token}
          >
            <BannerNextEvent
              marquee={dataSponsor.marquee && dataSponsor.marquee}
            />
          </VideoWrapperSponsor>

          <ChatComponent
            ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
            dataEvent={dataSponsor}
            style={{ width: "100%", backgoundColor: "black" }}
            showAttendees={
              validateRol &&
              (validateRol.rol === "SPONSOR" ||
                validateRol.rol === "SPEAKER" ||
                validateRol.rol === "TEAM" ||
                validateRol.rol === "MANAGER")
                ? true
                : false
            }
          >
            {validateRol.rol === "SPONSOR" && startCounter ? (
              <CountDown></CountDown>
            ) : (
              <></>
            )}
          </ChatComponent>
        </section>
      )}
    </>
  ) : (
    <Loading />
  );
}

export default MainRoomSponsor;
