import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import CamsConected from "../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import RoomType from "../../context/RoomType/RoomType";

import Loading from "../Loading/Loading";
import InfoEvent from "../main/BannerLogo/infoEvent";
import BannerNextEvent from "../main/bannerNextEvents/marquee";
import Main from "../main/main";
import StatisticsCircle from "../Statistics/StatisticsCircle";
import Attendees from "./Attendees";
import DropDownScheduleUSer from "./DropdownSchedule/DropDownScheduleUSer";
import Networkings from "./Networkings";
import Speakers from "./Speakers";
import Sponsors from "./Sponsors";
import { getActualHour } from "./Schedule";
import useRegisterActivity from "../../Hooks/useRegisterActivity";
import useRequests from "../../Hooks/UseRequests";

function AgendaDesktopUser() {
  const { getWithToken } = useRequests();
  const [selected, setSelected] = useState("schedule");
  const { attendeeid } = useParams();
  const { registerActivityGlobal } = useRegisterActivity();
  const [dropDownSchedule, setDropDownSchedule] = useState("");
  const { event } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const intl = useIntl();
  const [dataSpeakers, setDataSpeakers] = useState(false);
  const [dataSponsors, setDataSponsors] = useState(false);

  const getDataSpeakers = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/speakers?att=${attendeeid}&page=HOME_SPEAKERS`
    );
    setDataSpeakers(result);
  };

  const getDataSponsors = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/sponsors?att=${attendeeid}&page=HOME_SPONSORS`
    );
    setDataSponsors(result);
  };

  useEffect(() => {
    if (event && event.event) {
      if (!dataSpeakers) getDataSpeakers();
      if (!dataSponsors) getDataSponsors();
    }
    if (event)
      window.document.title =
        event.event.title +
        " / " +
        intl.formatMessage({ id: "header.schedule" });
  }, [event]);

  useEffect(() => {
    if (event && window.location.href.split("#")[1] === "sponsor")
      setSelected("sponsor");
    if (event && window.location.href.split("#")[1] === "networking")
      setSelected("networking");
  }, [event]);

  return event && event.event && dataUser ? (
    <main className="container main-bg">
      <InfoEvent
        data={{
          title: event.event.title,
          subject: event.event.subtitle,
          logo: event.event.logo,
          url: event.event.url,
        }}
      />

      {event && getActualHour() < event.event.hour_end ? (
        <div>
          <ul className="list-agenda">
            {event &&
              event.event.tabs &&
              event.event.tabs.schedule &&
              event.event.tabs.schedule.visible && (
                <li
                  style={{ display: "flex", position: "relative" }}
                  className={selected === "schedule" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_SCHEDULE");
                    setSelected("schedule");
                  }}
                >
                  {event.event.tabs.schedule.label}
                  <DropDownScheduleUSer setGroup={setDropDownSchedule} />
                </li>
              )}
            {event &&
              event.event.tabs &&
              event.event.tabs.speakers &&
              event.event.tabs.speakers.visible && <li>|</li>}
            {event &&
              event.event.tabs &&
              event.event.tabs.speakers &&
              event.event.tabs.speakers.visible && (
                <li
                  className={selected === "speaker" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_SPEAKERS");
                    setSelected("speaker");
                  }}
                >
                  {event.event.tabs.speakers.label}
                </li>
              )}
            {event &&
              event.event.tabs &&
              event.event.tabs.sponsors &&
              event.event.tabs.sponsors.visible && <li>|</li>}
            {event &&
              event.event.tabs &&
              event.event.tabs.sponsors &&
              event.event.tabs.sponsors.visible && (
                <li
                  className={selected === "sponsor" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_SPONSORS");
                    setSelected("sponsor");
                  }}
                >
                  {event.event.tabs.sponsors.label}
                </li>
              )}
            {dataUser &&
              dataUser.rol === "MANAGER" &&
              event &&
              event.event.tabs &&
              event.event.tabs.statistics &&
              event.event.tabs.statistics.visible && <li>|</li>}
            {dataUser &&
              dataUser.rol === "MANAGER" &&
              event &&
              event.event.tabs &&
              event.event.tabs.statistics &&
              event.event.tabs.statistics.visible && (
                <li
                  className={selected === "statistics" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_STATISTICS");
                    setSelected("statistics");
                  }}
                >
                  {event.event.tabs.statistics.label}
                </li>
              )}
            {dataUser &&
              dataUser.rol === "MANAGER" &&
              event &&
              event.event.tabs &&
              event.event.tabs.attendees &&
              event.event.tabs.attendees.visible && <li>|</li>}
            {dataUser &&
              dataUser.rol === "MANAGER" &&
              event &&
              event.event.tabs &&
              event.event.tabs.attendees &&
              event.event.tabs.attendees.visible && (
                <li
                  className={selected === "attendees" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_ATTENDEES");
                    setSelected("attendees");
                  }}
                >
                  {event.event.tabs.attendees.label}
                </li>
              )}
            {event &&
              event.event.tabs &&
              event.event.tabs.networking &&
              event.event.tabs.networking.visible &&
              event.event.tabs.attendees.visible &&
              event.event.tabs.sponsors.visible && <li>|</li>}
            {event &&
              event.event.tabs &&
              event.event.tabs.networking &&
              event.event.tabs.networking.visible && (
                <li
                  className={selected === "networking" ? "active" : "disabled"}
                  onClick={() => {
                    registerActivityGlobal("HOME", "TAB_NETWORKING");
                    setSelected("networking");
                  }}
                >
                  {event.event.tabs.networking.label}
                </li>
              )}
          </ul>
          <div className="padding-botton">
            <div>
              <div className="picture space">
                {selected === "schedule" && (
                  <CamsConected>
                    <RoomType>
                      <div className="m5px">
                        <Main setSelected={setSelected} />
                      </div>
                    </RoomType>
                  </CamsConected>
                )}
                {selected === "speaker" && <Speakers data={dataSpeakers} />}
                {selected === "sponsor" && (
                  <Sponsors data={dataSponsors} EventSite={event.event.site} />
                )}
                {selected === "statistics" && event && event.event && (
                  <StatisticsCircle EventId={event.event.id} />
                )}
                {selected === "networking" && event && event.event && (
                  <Networkings
                    data={event.event.networking}
                    EventSite={event.event.site}
                  />
                )}
                {selected === "attendees" && event && event.event && (
                  <Attendees EventId={event.event.id} />
                )}
              </div>
              <BannerNextEvent marquee={event.event.marquee} />
            </div>
          </div>
        </div>
      ) : (
        <h1 style={{ width: "100%", textAlign: "center", color: "white" }}>
          <FormattedMessage id="schedule.end" />
        </h1>
      )}
    </main>
  ) : (
    <Loading />
  );
}

export default AgendaDesktopUser;
