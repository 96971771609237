import { createClient, createChannel } from "agora-rtm-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import CameraDistribution from "../main/CameraDistribution/CameraDistribution";
import CameraDistributionNetwork from "../main/CameraDistribution/CameraDistributionNetwork";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");

function ChatAuditorioNetworking({ showAttendees, ids, children }) {
  const { room, attendeeid, id } = useParams();
  const client = useClient();
  const [selected, setSelected] = useState("messages");
  const { dataUser } = useContext(dataUserContext);
  const [messagesReceived, setMessagesReceived] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [handsUp, setHandsUp] = useState([]);
  const [handStatus, setHandStatus] = useState({});
  const [ch, setCh] = useState();
  const messageScroll = useRef();
  const dateUser = new Date().toLocaleTimeString("en-US");

  const { setUserSocket, setUsersName, actionsUsers, rolAuditorium } =
    useContext(EventData);
  const { screens, localScreen } = useContext(usersConected);
  const intl = useIntl();
  const [message, setMessage] = useState({
    command: "CHAT",
    name: "",
    room: room,
    message: "",
    date: "",
    id: attendeeid,
  });

  let login = async () => {
    const uid = `${ids.uid}-${dataUser.name}-${dateUser}-${rolAuditorium.rol}-CHAT`;
    const channel = createChannel(room);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("ChannelMessage", (msg) => {
      const received = JSON.parse(msg.text);
      if (received.command && received.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, received]);
      } else if (
        received.command &&
        (received.command === "HAND-UP" || received.command === "HAND-DOWN")
      ) {
        if (received.command === "HAND-UP") {
          setHandsUp((prevHand) => [...prevHand, received]);
        } else {
          setHandsUp((prevHand) =>
            prevHand.filter((hand) => hand.uid !== received.uid)
          );
        }
      } else {
        setUserSocket(received);
      }
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
      setUserSocket({
        command: "ATTENDEE",
        action: "IN-ROOM",
        name: dataUser.name,
        room: room,
        id: attendeeid,
        date: dateUser,
        uid: ids.uid,
        uid_desktop: ids.uid_desktop,
      });
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "guest") {
        const user = member.split("-");
        if (!result.find((us) => us.name === user[1])) {
          result.push({
            name: user[1],
            date: user[2],
            uid: parseInt(user[0]),
            rol: user[3],
          });
        }
      }
    });
    setOnlineUsers([...result]);
    setUsersName([...result]);
  };

  const sendMsg = async (text) => {
    let message = client.createMessage({
      text: JSON.stringify(text),
      messageType: "TEXT",
    });
    await ch.sendMessage(message).then(() => {
      if (text.command === "CHAT")
        setMessagesReceived((prevMessage) => [...prevMessage, text]);
    });
  };

  const accessUser = (handUid) => {
    if (handStatus[handUid]) {
      sendMsg({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      setUserSocket({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      delete handStatus[handUid];
    } else {
      setHandStatus({ ...handStatus, [handUid]: true });
      sendMsg({
        command: "ACCESS-DEVICES",
        id: attendeeid,
        date: dateUser,
        to: handUid,
        from: ids.uid,
      });
    }
  };

  const accessUserManager = (uid, rol) => {
    if (rol === "SPEAKER") {
      sendMsg({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setUserSocket({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "ATTENDEE" };
          } else {
            return user;
          }
        })
      );
    } else if (rol !== "MANAGER") {
      sendMsg({
        command: "ACCESS-DEVICES-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "SPEAKER" };
          } else {
            return user;
          }
        })
      );
    }
  };

  useEffect(() => {
    login();
  }, []);

  useEffect(() => {
    if (handsUp) {
      const sortUsersOnline = onlineUsers.sort((a, b) => {
        const hasHandA =
          handsUp.find((hand) => hand.uid === a.uid) !== undefined;
        const hasHandB =
          handsUp.find((hand) => hand.uid === b.uid) !== undefined;

        if (hasHandA && !hasHandB) return -1;
        if (!hasHandA && hasHandB) return 1;
        return 0;
      });
      setOnlineUsers(sortUsersOnline);
      setTimeout(() => {
        setOnlineUsers((prevUsers) => [...prevUsers]);
      }, 1000);
    }
  }, [handsUp]);

  useEffect(() => {
    if (actionsUsers) sendMsg(actionsUsers);
  }, [actionsUsers]);

  useEffect(() => {
    if (messagesReceived.length > 0) {
      messageScroll.current?.scrollIntoView({ block: "end" });
    }
  }, [messagesReceived]);

  const handleChange = (e) => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date();
    message.name = dataUser.name;
    message.message = message.message.replace(/\r?\n/g, "<br>");
    message.date = date.toLocaleTimeString("en-US");
    message.room = room;
    sendMsg(message);
    e.target.reset();
  };

  return (
    <div className="chat" style={{ marginTop: "-15px" }}>
      <ul className="chat-list">
        <li
          className={selected === "messages" ? "active" : "disabled"}
          onClick={() => setSelected("messages")}
        >
          Chat
        </li>
        {showAttendees &&
          (rolAuditorium.rol === "MANAGER" ||
            rolAuditorium.rol === "SPEAKER") && <li>|</li>}
        {showAttendees &&
          (rolAuditorium.rol === "MANAGER" ||
            rolAuditorium.rol === "SPEAKER") && (
            <li
              className={selected === "assistants" ? "active" : "disabled"}
              onClick={() => setSelected("assistants")}
            >
              <FormattedMessage id="chat.assistants" defaultMessage="Nombre" />
            </li>
          )}
      </ul>
      <div className="messages">
        {selected === "messages" && (
          <div>
            <div className="messages-container">
              {messagesReceived &&
                messagesReceived.map((message, index) => {
                  return (
                    <div key={index}>
                      <p
                        style={{ marginBottom: "-20px", lineHeight: "90%" }}
                        className={"message-person"}
                      >
                        {message.name}
                        <br></br>
                        <span style={{ fontSize: "10px" }}>{message.date}</span>
                      </p>

                      <div style={{ marginTop: "30px", position: "relative" }}>
                        {message.message.split("<br>").map((line, i) => (
                          <p key={i} ref={messageScroll}>
                            {line}
                          </p>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <textarea
                type="text"
                name="message"
                placeholder={intl.formatMessage({ id: "chat.writeMessage" })}
                onChange={(e) => handleChange(e)}
              ></textarea>
              <input type="submit"></input>
            </form>
          </div>
        )}
        {selected === "assistants" && (
          <div className="messages-container">
            {onlineUsers &&
              onlineUsers.map((user, index) => (
                <div key={user.name + "-" + index} className="conexion">
                  <div className="circle-conexion"></div>
                  <div>
                    {user.name}-{user.date}{" "}
                    {localScreen && ids.uid_desktop === user.uid_desktop && (
                      <FormattedMessage id="chat.Compartir" />
                    )}
                    {screens &&
                      screens.length > 0 &&
                      screens[0].uid === user.uid_desktop && (
                        <FormattedMessage id="chat.Compartir" />
                      )}
                  </div>

                  {ids.uid !== user.uid && (
                    <div
                      className={
                        user.rol === "SPEAKER" || user.rol === "MANAGER"
                          ? "hands"
                          : "hands color-red"
                      }
                      onClick={() => accessUserManager(user.uid, user.rol)}
                    >
                      <img src="https://resources.virtuales.io/eventos/img/host.svg"></img>
                      <div className="tooltip-chat">
                        {user.rol === "SPEAKER" || user.rol === "MANAGER" ? (
                          <FormattedMessage id="chat.RemoveAccessHand" />
                        ) : (
                          <FormattedMessage id="chat.AccessHost" />
                        )}
                      </div>
                    </div>
                  )}

                  {handsUp.find((hand) => hand.uid === user.uid) && (
                    <div
                      className={
                        !handStatus[user.uid] ? "hands" : "hands color-red"
                      }
                      onClick={() => accessUser(user.uid)}
                    >
                      <img src="https://resources.virtuales.io/eventos/img/manoarriba.svg"></img>
                      <div className="tooltip-chat">
                        {!handStatus[user.uid] ? (
                          <FormattedMessage id="chat.AccessWithHand" />
                        ) : (
                          <FormattedMessage id="chat.RemoveAccessHand" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      {window.location.pathname ===
        `/${id}/networkings=${room}&att=${attendeeid}` && (
        <CameraDistributionNetwork />
      )}
      {window.location.pathname !==
        `/${id}/networkings=${room}&att=${attendeeid}` && (
        <CameraDistribution />
      )}
      <div>{children}</div>
    </div>
  );
}

export default ChatAuditorioNetworking;
