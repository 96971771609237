import React, { useContext } from "react";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import { EventData } from "../../../context/EventData/EventData";
import { AgoraVideoPlayer } from "agora-rtc-react";
import Controls from "./Controls";
import ControlsAtendeeRemote from "./ControlsAtendeeRemote";

function RoomAuditorioMobile(props) {
  const { users, screens, controls, type } = props;
  const { dataUser } = useContext(dataUserContext);
  const { rolAuditorium } = useContext(EventData);

  try {
    return (
      <div className="screen-mobile">
        {screens &&
          users &&
          controls.tracks &&
          rolAuditorium &&
          controls.ids &&
          controls.token &&
          controls.setIsStreaming &&
          controls.tracks && (
            <div
              id={controls.fullScreen.active ? "full-screen" : "screen-m"}
              className={
                rolAuditorium &&
                screens &&
                users &&
                (screens.length > 0 || controls.localScreen)
                  ? `video-grid-auditorio-sreen`
                  : `video-grid-auditorio-${users.length}`
                  
              }
            >
              {controls.trackState &&
                controls.tracks.length > 0 &&
                !controls.localScreen &&
                screens.length === 0 && (
                  <div
                    className={
                      users.length === 0
                        ? "screen-manager"
                        : "screen-manager-absolute"
                    }
                  >
                    {controls.trackState.video ? (
                      <AgoraVideoPlayer
                        style={{ height: "calc(100%)", width: "100%" }}
                        videoTrack={controls.tracks[1]}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                        alt="main screen"
                        className="screenVideo"
                      />
                    )}
                    <Controls variant={"-networking"} controls={controls} />
                    <div className="name-user-mobile">{dataUser.name}</div>
                  </div>
                )}

              {screens && screens.length > 1 && (
                <div className="screen-manager">
                  {screens[0].videoTrack !== undefined ? (
                    <AgoraVideoPlayer
                      style={{ height: "calc(100% - 40px)", width: "100%" }}
                      videoTrack={screens[0].videoTrack}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                      alt="main screen"
                      className="screenVideo"
                    />
                  )}
                  {rolAuditorium.rol !== "ATTENDEE" && (
                    <Controls variant={"-networking"} controls={controls} />
                  )}
                </div>
              )}

              {users.length === 1 &&
                !controls.localScreen &&
                screens.length === 0 && (
                  <div key={users[0].uid} className="screen-manager">
                    {!users[0]._video_muted_ &&
                    users[0].videoTrack !== undefined ? (
                      <AgoraVideoPlayer
                        className="screen-manager"
                        videoTrack={users[0].videoTrack}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                        alt="main screen"
                        className="screenVideo"
                      />
                    )}
                    <div className="name-user">{users[0].userName}</div>
                  </div>
                )}

              {users.length > 1 &&
                !controls.localScreen &&
                screens.length === 0 &&
                users.map((user) => (
                  <div key={user.uid}>
                    {!user._video_muted_ && user.videoTrack !== undefined ? (
                      <AgoraVideoPlayer
                        style={{ height: "calc(100% - 40px)", width: "100%" }}
                        videoTrack={user.videoTrack}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                        alt="main screen"
                        className="screenVideo"
                      />
                    )}
                    {(rolAuditorium.rol === "SPEAKER" ||
                      rolAuditorium.rol === "MANAGER") && (
                      <ControlsAtendeeRemote
                        type={type}
                        userUid={user.uid}
                        videoMuted={user._video_muted_}
                        audioMuted={user._audio_muted_}
                      />
                    )}
                    <div className="name-user">{user.userName}</div>
                  </div>
                ))}

              {controls.tracks.length === 0 &&
                users.length === 0 &&
                !controls.localScreen &&
                screens.length === 0 && (
                  <div className="screen-manager">
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo-empezamos-proximamente.jpeg`}
                      alt="main screen"
                    />
                    {rolAuditorium.rol !== "ATTENDEE" && (
                      <Controls variant={"-networking"} controls={controls} />
                    )}
                  </div>
                )}

              {controls.localScreen && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <AgoraVideoPlayer
                    style={{ height: "100%", width: "100%" }}
                    className="vid"
                    videoTrack={controls.localScreen}
                  />
                  <Controls variant={"-networking"} controls={controls} />
                </div>
              )}
            </div>
          )}
      </div>
    );
  } catch (error) {
    console.error(error);
  }
}

export default RoomAuditorioMobile;
