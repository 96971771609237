import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/header/header";
import MainNetworkings from "../components/RoomNetworkings/MainNetworkings";
import ActionsProvider from "../context/Actions/ActionsControl";
import CamsConected from "../context/CamsConected/CamsConected";

function AuditoriumNetworkings() {
  return (
    <CamsConected>
      <div className="App">
        <Header></Header>
        <div className="container" style={{ margin: "auto" }}>
          <ActionsProvider>
            <MainNetworkings />
          </ActionsProvider>
        </div>
        <Footer />
      </div>
    </CamsConected>
  );
}

export default AuditoriumNetworkings;
