import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import InfoSpeaker from "../main/InfoSpeaker/infoSpeaker";
import { usersConected } from "../../context/CamsConected/CamsConected";
import VideoSponsor from "./VideoSponsor";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { FormattedMessage } from "react-intl";
import Button from "../button/button";
import { actionsContext } from "../../context/Actions/ActionsControl";
import useRequests from "../../Hooks/UseRequests";

function VideoWrapperSponsor({
  data,
  children,
  validateRol,
  setValidateRol,
  getDataSponsor,
  token,
}) {
  const { setStartCounter } = useContext(actionsContext);
  const [inCall, setInCall] = useState(false);
  const { room } = useParams();
  const { getWithToken } = useRequests();
  const { screens, speakers, userConnectedImages } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);
  const { setRolAuditorium, event } = useContext(EventData);
  const { attendeeid } = useParams();

  async function getdata() {
    if (
      !validateRol &&
      dataUser &&
      dataUser.rol !== "MANAGER" &&
      dataUser.rol !== "ATTENDEE"
    ) {
      const res = await getWithToken(
        `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/rol?att=${attendeeid}`
      );
      if (res.rol === "ATTENDEE") setInCall(true);
      setValidateRol(res);
      setRolAuditorium(res);
    } else {
      if (dataUser.rol === "ATTENDEE") setInCall(true);
      setValidateRol(dataUser);
      setRolAuditorium(dataUser);
    }
  }

  useEffect(() => {
    if (validateRol.rol === "ATTENDEE") setInCall(true);

    if (event) getdata();
  }, [event]);

  return (
    <section className="videoMain-2">
      <div>
        {inCall ? (
          <div>
            <VideoSponsor
              inCall={inCall}
              setInCall={setInCall}
              validateRol={validateRol}
              token={token}
            ></VideoSponsor>
            {userConnectedImages.length === 0 && (
              <div>
                {data && data.video.is_video ? (
                  <div className="video-sponsor">
                    {validateRol &&
                      validateRol.rol === "ATTENDEE" &&
                      screens.length === 0 &&
                      speakers.length === 0 && (
                        <ReactPlayer
                          className="video-sponsor"
                          height={400}
                          url={data.video.url}
                          muted={true}
                          playing={true}
                          controls={true}
                          loop={true}
                        />
                      )}
                  </div>
                ) : (
                  <div>
                    {validateRol &&
                      validateRol.rol === "ATTENDEE" &&
                      screens.length === 0 &&
                      speakers.length === 0 && (
                        <img
                          src={data.video.url}
                          style={{ width: "100%" }}
                        ></img>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {data && data.video.is_video ? (
              <div className="video-sponsor">
                <ReactPlayer
                  className="video-sponsor"
                  height={400}
                  url={data.video.url}
                  muted={true}
                  playing={true}
                  controls={true}
                  loop={true}
                />
                {validateRol && validateRol.rol !== "ATTENDEE" && (
                  <div className="joi">
                    <button
                      className="join-sponsor"
                      onClick={(e) => {
                        e.preventDefault();
                        setInCall(true);
                        setStartCounter(true);
                      }}
                    >
                      <FormattedMessage id="controls.join" />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <img src={data.video.url} style={{ width: "100%" }}></img>
                {validateRol && validateRol.rol !== "ATTENDEE" && (
                  <div className="joi">
                    <button
                      className="join-sponsor"
                      onClick={(e) => {
                        e.preventDefault();
                        setInCall(true);
                        setStartCounter(true);
                      }}
                    >
                      <FormattedMessage id="controls.join" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {children}
      </div>
      <Button
        variant="primary--regresa"
        icon={`${process.env.REACT_APP_EVENT_IMG}/back.svg`}
        onClick={() =>
          (window.location = `/${event.event.site}/agenda/attendee/${attendeeid}#sponsor`)
        }
      >
        <FormattedMessage id="controls.return" />
      </Button>
      <InfoSpeaker data={data} getDataSponsor={getDataSponsor} />
    </section>
  );
}

export default VideoWrapperSponsor;
