import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { getActualHour, visibility } from "../agenda/Schedule";
import ChatAuditorioNetworking from "../ChatComponent/ChatAuditorioNetworking";
import Loading from "../Loading/Loading";
import InfoEvent from "../main/BannerLogo/infoEvent";
import VideoWrapperAuditoriumNetworking from "./VideoWrapperAuditoriumNetworking";
import useRequests from "../../Hooks/UseRequests";

function MainAuditoriumNetworking() {
  const { id, room, attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { event } = useContext(EventData);
  const { dataEvent, setDataEvent, setRolAuditorium } = useContext(EventData);
  const [dataInfo, setDataInfo] = useState();
  const { dataUser } = useContext(dataUserContext);
  const [token, setToken] = useState();

  const getData = async () => {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/sites/${id}/${room}?att=${attendeeid}`
    );

    setDataEvent(res);
    updateRoom(res);
    setInterval(() => updateRoom(res), 60000);
    validateRol();
  };

  const validateRol = async () => {
    setRolAuditorium(dataUser);
  };

  const updateRoom = async (response) => {
    response.schedule.map((schedule) => {
      let v = visibility(schedule);

      if (v === "live") {
        schedule.subject = null;
        setDataInfo(schedule);
      }

      if (getActualHour() < schedule.hour_ini && v !== "live" && dataUser) {
        schedule.subject = null;
        setDataInfo(schedule);
      }
    });
  };

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/token/${dataUser.rol}?name=${dataUser.name}?att=${attendeeid}`
    );
    setToken(res);
  }

  useEffect(() => {
    if (!token && dataUser && event) {
      getdata();
    }
  }, [dataUser, token, event]);

  useEffect(() => {
    if (dataUser) getData();
  }, [dataUser]);

  return (
    <>
      <div>
        {!dataInfo || !dataEvent || !token ? (
          <Loading />
        ) : (
          <div>
            <InfoEvent data={dataInfo} />
            <section className="auditorium" style={{ marginTop: "15px" }}>
              <VideoWrapperAuditoriumNetworking
                token={token}
                dataSchedule={dataInfo}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
              />
              <ChatAuditorioNetworking
                dataEvent={dataEvent}
                showAttendees={true}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
              >
              </ChatAuditorioNetworking>
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default MainAuditoriumNetworking;
