import React from "react";

function ControlsStatusAudio({ audioMuted }) {
  return (
    <div className="controls-networking">
      <div
        className={
          !audioMuted ? "button--controls-menu2 sp" : "button--off2 sp"
        }
      >
        <img
          src={
            !audioMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
          width={30}
        ></img>
      </div>
    </div>
  );
}

export default ControlsStatusAudio;
