import VirtualBackgroundExtension from "agora-extension-virtual-background";
import AgoraRTC from "agora-rtc-sdk-ng";
import { consoleLogFunction } from "../functions/functionLog";

const extension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([extension]);

function useBackgroundVideo(tracks) {
  let processor = null;

  async function getProcessorInstance() {
    if (!processor && tracks[1]) {
      // Create a VirtualBackgroundProcessor instance
      processor = extension.createProcessor();
      try {
        // Initialize the extension and pass in the URL of the Wasm file
        await processor.init("../../../assets/wasms");
      } catch (e) {
        consoleLogFunction("videoCall:81", "Fail to load WASM resource!");
        return null;
      }

      tracks[1].pipe(processor).pipe(tracks[1].processorDestination);
    }
    return processor;
  }

  async function setBackgroundColor() {
    if (processor !== null) {
      await processor.disable();
    }
    setTimeout(async () => {
      if (tracks[1]) {
        let processor = await getProcessorInstance();
        try {
          processor.setOptions({ type: "color", color: "green" });
          await processor.enable();
        } finally {
          consoleLogFunction("VideoCall:99", "finalizado");
        }
      }
    }, 1000);
  }

  // Blur the user's actual background
  async function setBackgroundBlurring() {
    if (processor !== null) {
      await processor.disable();
    }
    setTimeout(async () => {
      if (tracks[1]) {
        let processor = await getProcessorInstance();

        try {
          processor.setOptions({
            type: "blur",
            blurDegree: 2,
          });
          await processor.enable();
        } finally {
          consoleLogFunction("videoCall:116", "cambio");
        }
      }
    }, 1000);
  }

  // Set an image as the background
  async function setBackgroundImage(image) {
    if (processor !== null) {
      await processor.disable();
    }
    setTimeout(() => {
      const imgElement = document.createElement("img");

      imgElement.onload = async () => {
        let processor = await getProcessorInstance();
        try {
          processor.setOptions({ type: "img", source: imgElement });
          await processor.enable();
        } finally {
          consoleLogFunction("videoCall:132", "cambio");
        }
      };
      imgElement.src = image;
    }, 1000);
  }
  async function backgroundDisable() {
    await processor.disable();
  }

  return {
    backgroundDisable,
    setBackgroundBlurring,
    setBackgroundColor,
    setBackgroundImage,
  };
}

export default useBackgroundVideo;
