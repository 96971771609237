import Loading from "../Loading/Loading";
import React, { useContext, useEffect, useState } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";
import useRequests from "../../Hooks/UseRequests";

function Schedule({ EventId, group }) {
  const api = process.env.REACT_APP_URI_API_CACHE;
  const { getWithToken } = useRequests();
  const [data2, setdata2] = useState();
  const { dataUser } = useContext(dataUserContext);

  const viewableAgenda = (schedule) => {
    let visibilitySchedule = null;
    const horaActual = getActualHour();

    if (horaActual < schedule.hour_end) {
      visibilitySchedule = "visible";
    } else {
      visibilitySchedule = false;
    }

    return visibilitySchedule;
  };

  const getSchedules = async () => {
    const url = `${api}/${EventId}/schedules`;
    const result = await getWithToken(url);
    setdata2(result);
  };
  
  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <div className="">
      {!data2 ? (
        <Loading />
      ) : (
        <div className="schedule">
          {group === "" ? (
            <div style={{ width: "95%" }}>
              {data2.schedule.map((sche) => {
                let v =
                  dataUser && dataUser.rol === "MANAGER"
                    ? "live"
                    : visibility(sche);
                let visibleSchedule = viewableAgenda(sche);

                return (
                  <div key={sche.id}>
                    {visibleSchedule && visibleSchedule === "visible" && (
                      <ul className="list-schedule">
                        <li>
                          <h2>
                            {sche.group} <br></br>
                            <span style={{ fontSize: "1.5vw" }}>
                              {sche.hours}
                            </span>
                          </h2>
                        </li>
                        <li>
                          <h1>{sche.title}</h1>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginTop: "-25px",
                            }}
                          >
                            <img
                              src={sche.photo}
                              alt="imagen de speaker"
                              style={{
                                width: "40px",
                                marginRight: "10px",
                                borderRadius: "50px",
                              }}
                            />
                            <p>{sche.speakers}</p>
                          </div>
                        </li>
                        <li>
                          <p>{sche.subject}</p>
                        </li>
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ width: "95%" }}>
              {data2.schedule.map((sche) => {
                let v =
                  dataUser && dataUser.rol === "MANAGER"
                    ? "live"
                    : visibility(sche);
                let visibleSchedule = viewableAgenda(sche);
                return (
                  <div key={sche.id} hidden={group !== sche.group}>
                    {visibleSchedule === "visible" && (
                      <ul className="list-schedule">
                        <li>
                          <h2>
                            {sche.group} <br></br>
                            <span style={{ fontSize: "1.5vw" }}>
                              {sche.hours}
                            </span>
                          </h2>
                        </li>
                        <li>
                          <h1 style={{ fontZise: "2vw", width: "100%" }}>
                            {sche.title}
                          </h1>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <img
                              src={sche.photo}
                              alt="imagen de speaker"
                              style={{
                                width: "50px",
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />
                            {sche.speakers}
                          </div>
                        </li>
                        <li>{sche.subject}</li>
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Schedule;

export function getActualHour() {
  var d = new Date();
  var s = "" + d.getUTCFullYear();

  if (d.getUTCMonth() + 1 < 10) {
    s += "0" + (d.getUTCMonth() + 1);
  } else {
    s += "" + (d.getUTCMonth() + 1);
  }
  if (d.getUTCDate() < 10) {
    s += "0" + d.getUTCDate();
  } else {
    s += "" + d.getUTCDate();
  }
  if (d.getUTCHours() < 10) {
    s += "0" + d.getUTCHours();
  } else {
    s += "" + d.getUTCHours();
  }
  if (d.getUTCMinutes() < 10) {
    s += "0" + d.getUTCMinutes();
  } else {
    s += "" + d.getUTCMinutes();
  }
  var actualHour = parseInt(s);
  return actualHour;
}

export function visibility(schedule) {
  let visibilityButton = null;
  const horaActual = getActualHour();

  if (horaActual > schedule.hour_ini && horaActual < schedule.hour_end) {
    visibilityButton = "live";
  } else {
    visibilityButton = "visible";
  }

  return visibilityButton;
}
