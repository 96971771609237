import React, { createContext, useState } from 'react';

export const dataUserContext = createContext();

function DataUser({ children }) {

    const [dataUser, setDataUser] = useState(false)
    const [userName, setUserName] = useState()

    return <dataUserContext.Provider value={{ dataUser, setDataUser, userName, setUserName}}>
        {children}
    </dataUserContext.Provider>;
}

export default DataUser;
