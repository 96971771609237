import Loading from "../Loading/Loading";
import React from "react";

function Speakers({ data }) {
  return (
    <div className="formRegistroDesktop">
      {!data ? (
        <Loading />
      ) : (
        <div className="list-speaker">
          <div className="grip-speaker">
            {data.speakers.map((speaker) => (
              <div
                key={speaker.id}
                className="speaker-box"
                hidden={speaker.show === false}
              >
                <a
                  href={speaker.link}
                  target="_blank"
                  className="speaker-box-c"
                >
                  <div className="photo-speaker-div">
                    <img
                      src={
                        speaker.photo ||
                        `${process.env.REACT_APP_EVENT_IMG}/user.svg`
                      }
                      alt="imagen de speaker"
                      className="photo-speaker"
                    />
                  </div>
                  <div>
                    <h1
                      style={{
                        fontSize: "1.7vw",
                        textTransform: "uppercase",
                        lineHeight: "95%",
                      }}
                    >
                      {speaker.name}
                    </h1>
                    <div className="flex-speaker">
                      <div>
                        <img
                          src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                          width="50"
                        />
                      </div>
                      <div className="speaker-name">
                        <p>{speaker.title}</p>
                        <p style={{ marginTop: "-15px" }}>{speaker.job}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Speakers;
