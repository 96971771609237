import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/header/header'
import MainNetworking from '../components/RoomNetworking/MainNetworking'
import CamsConected from '../context/CamsConected/CamsConected'

function AuditoriumNetworking() {
    
    return (
        <CamsConected>
            <div className="App">
                <Header />
                <div className="container" style={{ margin: "auto" }}>
                    <MainNetworking />
                </div>
                <Footer />
            </div>
        </CamsConected>
    )
}

export default AuditoriumNetworking