import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useState } from "react";
import { useContext } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import ControlsAtendeeRemote from "../main/MainScreen/ControlsAtendeeRemote";
import ControlsNetwork from "./ControlsNetwork";
import Loading from "../Loading/Loading";
import ControlsStatusAudio from "../main/MainScreen/ControlsStatusAudio";
import { FormattedMessage } from "react-intl";
import Button from "../button/button";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function RoomAuditoriumNetwork(props) {
  const {
    users,
    localScreen,
    tracks,
    screens,
    trackState,
    controls,
    ids,
    type,
  } = props;
  const { room } = useParams();
  const { userSocket, setActionsUsers } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const { rolAuditorium } = useContext(EventData);
  const [isOpen, setIsOpen] = useState(false);
  const [nameUserAlert, setNameUserAlert] = useState("false");
  const [deviceType, setDeviceType] = useState("");

  const mute = () => {
    if (deviceType === "audio") {
      tracks[0].setEnabled(!trackState.audio);
      controls.setTrackState((ps) => {
        return { ...ps, audio: !trackState.audio };
      });
      setIsOpen(false);
    }
    if (deviceType === "camara") {
      tracks[1].setEnabled(!trackState.video);
      controls.setTrackState((ps) => {
        return { ...ps, video: !trackState.video };
      });
      setIsOpen(false);
    }

    setActionsUsers({
      type: "controls",
      room: room,
      command: "STATUS-DEVICES",
      product: "EVENTS",
    });
  };

  useEffect(() => {
    if (userSocket.command === "MIC-ON" && ids.uid === userSocket.to) {
      setDeviceType("audio");
      setNameUserAlert(userSocket.name);
      setIsOpen(true);
    }
    if (userSocket.command === "CAMERA-ON" && ids.uid === userSocket.to) {
      setDeviceType("camara");
      setNameUserAlert(userSocket.name);
      setIsOpen(true);
    }
  }, [userSocket]);

  return (
    <div className="screen">
      {screens && users && tracks && rolAuditorium ? (
        <div
          id={controls.fullScreen.active ? "full-screen" : "videos2"}
          className={
            rolAuditorium &&
            screens &&
            users &&
            (screens.length > 0 ||
              localScreen ||
              (tracks.length === 0 && users.length > 0))
              ? `video-grid-auditorio-sreen`
              : `video-grid-auditorio-${users.length}`
          }
        >
          {isOpen && (
            <div className="light-popup">
              <div className="popup-alert">
                <h3 className="title-popup">
                  <FormattedMessage id="popup.alert1" />{" "}
                  {nameUserAlert && nameUserAlert}
                  <FormattedMessage id="popup.alert2" />
                  {deviceType && deviceType}
                  <FormattedMessage id="popup.alert3" />
                </h3>
                <div className="buttons-alert">
                  <Button
                    variant={"early-message"}
                    onClick={() => mute(deviceType)}
                  >
                    <FormattedMessage id="popup.yes" />
                  </Button>
                  <Button
                    variant={"early-message"}
                    onClick={() => setIsOpen(false)}
                  >
                    <FormattedMessage id="popup.no" />
                  </Button>
                </div>
              </div>
            </div>
          )}

          {screens && screens.length > 0 && (
            <div style={{ width: "100%", height: "100%" }}>
              {screens[0].videoTrack !== undefined && (
                <AgoraVideoPlayer
                  style={{ height: "calc(100% - 40px)", width: "100%" }}
                  videoTrack={screens[0].videoTrack}
                />
              )}
              <div className="name-user">{screens[0].userName}</div>
              <ControlsNetwork
                variant={"-networking-screen2"}
                controls={controls}
              />
            </div>
          )}

          {trackState &&
            tracks.length > 0 &&
            !localScreen &&
            screens.length === 0 && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                {trackState.video ? (
                  <AgoraVideoPlayer
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                    videoTrack={tracks[1]}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100% - 40px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#3124cc",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                      style={{ width: "50%", borderRadius: "100%" }}
                    ></img>
                  </div>
                )}
                <ControlsNetwork variant={"-networking"} controls={controls} />
                <div className="name-user">{dataUser.name}</div>
              </div>
            )}

          {users.length > 0 &&
            !localScreen &&
            screens.length === 0 &&
            users.map((user) => (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
                key={user.uid}
              >
                {!user._video_muted_ && user.videoTrack !== undefined ? (
                  <AgoraVideoPlayer
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                    videoTrack={user.videoTrack}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100% - 40px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#3124cc",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                      style={{ width: "50%", borderRadius: "100%" }}
                    />
                  </div>
                )}

                {rolAuditorium.rol !== "ATTENDEE" && (
                  <ControlsAtendeeRemote
                    userUid={user.uid}
                    videoMuted={user._video_muted_}
                    audioMuted={user._audio_muted_}
                    type={type}
                  />
                )}
                {rolAuditorium.rol === "ATTENDEE" && (
                  <ControlsStatusAudio
                    userUid={user.uid}
                    audioMuted={user._audio_muted_}
                  />
                )}
                <div className="name-user">{user.userName}</div>
              </div>
            ))}

          {tracks.length === 0 &&
            users.length === 0 &&
            !localScreen &&
            screens.length === 0 && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                {rolAuditorium.rol !== "ATTENDEE" && (
                  <ControlsNetwork
                    variant={"-networking"}
                    controls={controls}
                  />
                )}
              </div>
            )}
          {localScreen && (
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              <AgoraVideoPlayer
                style={{ height: "calc(100% - 40px)", width: "100%" }}
                className="vid"
                videoTrack={localScreen}
              />
              <div className="name-user">{dataUser.name}</div>
              <ControlsNetwork
                variant={"-networking-screen2"}
                controls={controls}
              />
            </div>
          )}
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <img
            src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
            alt="main screen"
            className="screenVideo"
          />
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "calc(50% - 50px)",
            }}
          >
            <Loading></Loading>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoomAuditoriumNetwork;
