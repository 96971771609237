import React, { createContext, useState } from "react";
export const actionsContext = createContext();

const ActionsProvider = ({ children }) => {
  const [exitRoomSponsor, setExitRoomSponsor] = useState(false);
  const [exitRoomNetworking, setExitRoomNetworking] = useState(false);
  const [startCounter, setStartCounter] = useState(false);
  return (
    <actionsContext.Provider
      value={{
        exitRoomSponsor,
        setExitRoomSponsor,
        exitRoomNetworking,
        setExitRoomNetworking,
        startCounter,
        setStartCounter,
      }}
    >
      {children}
    </actionsContext.Provider>
  );
};
export default ActionsProvider;
