import React from 'react'
import { Link } from 'react-router-dom'

function HeaderMobileEmpty() {
  return (
    <>
    <header className="header">
      <div className="wrapper">
        <img
          src={`${process.env.REACT_APP_EVENT_IMG}/logo-virtual-es.svg`}
          className="logo-nav"
          alt="logo"
        />
        <Link to="/">
          <img
            className="imgfaq"
            src={`${process.env.REACT_APP_EVENT_IMG}/faq.svg`}
            alt="faq"
            width="55"
            height="55"
          />
        </Link>
      </div>
    </header>
  </>
  )
}

export default HeaderMobileEmpty