import React from "react";

function InfoEventRecording({ data }) {
  return (
    <div className="flex-box">
      <div style={{ width: "80%" }}>
        <h1 className="title-infoevent">
          {data.title} <br />
          <span className="subtitle-info">
            {data.subject && (
              <div>
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                  width={30}
                ></img>
                {data.subject}
                <br></br>
              </div>
            )}
          </span>
        </h1>
      </div>
      <a target="_blank" href={data.url} style={{ width: "23%" }}>
        <div className="logo-info">
          <img src={data.logo} className={"logo-info-detail"} />
        </div>
      </a>
    </div>
  );
}

export default InfoEventRecording;
