import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventData } from "../../../context/EventData/EventData";
import useRequests from "../../../Hooks/UseRequests";

function DropDownScheduleUSer({ setGroup }) {
  const { attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const [toggle, setToggle] = useState(false);
  const [listDay, setListDay] = useState([]);
  const [data, setData] = useState(false);
  const { event } = useContext(EventData);

  const getData = async () => {
    const req = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/schedules?att=${attendeeid}&page=HOME_SPONSORS`
    );
    setData(req);
  };

  const toggleAction = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  const buttonDropdownAction = (group) => {
    setGroup(group);
    setToggle(false);
  };

  useEffect(() => {
    if (!data && event) getData();
  }, [event]);

  useEffect(() => {
    if (data) {
      let days = [];
      data.schedule.map((group) => {
        let day =
          days.length > 0 &&
          days.find((schedule) => group.group === schedule.group);
        if (!day) {
          days.push(group);
        }
      });
      setListDay([...days]);
    }
  }, [data]);

  return (
    <div>
      {listDay && listDay.length > 1 && (
        <img
          src={`${process.env.REACT_APP_EVENT_IMG}/down-arrow.svg`}
          onClick={toggleAction}
          className="image-dropdown"
        ></img>
      )}

      <div
        hidden={!toggle}
        className="dropdown-schedule"
        id="dropDown"
        onMouseLeave={() => setToggle(false)}
      >
        {listDay.map((group, index) => (
          <div
            className="list-dropdown"
            onClick={() => buttonDropdownAction(group.group)}
            key={index}
          >
            {group.group}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DropDownScheduleUSer;
