import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import ControlsNetworking from "./ControlsNetworking";
import ControlsNetworkingUsers from "./ControlsNetworkingUsers";

function RoomAuditoriumNetworking(props) {
  const { controls } = props;
  const { dataUser } = useContext(dataUserContext);
  const { rolAuditorium } = useContext(EventData);

  return (
    <div className="screen">
      {dataUser && controls.screens && controls.users && controls.tracks && (
        <div
          id={controls.fullScreen.active ? "full-screen" : "videos"}
          className={
            controls.screens &&
            controls.users &&
            (controls.screens.length > 0 || controls.localScreen)
              ? `video-grid-auditorio-sreen`
              : `video-grid-auditorio-${controls.users.length}`
          }
        >
          {controls.screens && controls.screens.length > 0 && (
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              {controls.screens[0].videoTrack !== undefined && (
                <AgoraVideoPlayer
                  style={{ height: "calc(100% - 40px)", width: "100%" }}
                  videoTrack={controls.screens[0].videoTrack}
                />
              )}
              {controls && <ControlsNetworking controls={controls} />}
            </div>
          )}

          {controls.tracks.length > 0 &&
            !controls.localScreen &&
            controls.screens.length === 0 && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <AgoraVideoPlayer
                  style={{ height: "calc(100% - 40px)", width: "100%" }}
                  videoTrack={controls.tracks[1]}
                />
                <ControlsNetworking controls={controls}></ControlsNetworking>
                <div className="name-user">{dataUser.name}</div>
              </div>
            )}

          {controls.users.length > 0 &&
            !controls.localScreen &&
            controls.screens.length === 0 &&
            controls.users.map((user) => (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
                key={user.uid}
              >
                {!user._video_muted_ && user.videoTrack ? (
                  <AgoraVideoPlayer
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                    videoTrack={user.videoTrack}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                    alt="main screen"
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                  />
                )}
                {(rolAuditorium.rol === "MANAGER" ||
                  rolAuditorium.rol === "SPONSOR") && (
                  <ControlsNetworkingUsers
                    userId={user.uid}
                    videoMuted={user._video_muted_}
                    audioMuted={user._audio_muted_}
                    userCommandId={controls.userId}
                  />
                )}
                <div className="name-user">{user.userName}</div>
              </div>
            ))}

          {controls.tracks.length === 0 &&
            controls.users.length === 0 &&
            !controls.localScreen &&
            controls.screens.length === 0 && (
              <div
                style={{
                  width: "100%",
                  height: "calc(100% - 40px)",
                  position: "relative",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                {controls && <ControlsNetworking controls={controls} />}
                <div className="name-user">{dataUser.name}</div>
              </div>
            )}

          {controls.tracks.length === 0 &&
            controls.users.length > 0 &&
            !controls.localScreen &&
            controls.screens.length === 0 && (
              <div
                style={{
                  width: "100%",
                  height: "calc(100% - 40px)",
                  position: "relative",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                {controls && <ControlsNetworking controls={controls} />}
                <div className="name-user">{dataUser.name}</div>
              </div>
            )}
          {controls.localScreen && (
            <div
              style={{
                width: "100%",
                height: "calc(100% - 40px)",
                position: "relative",
              }}
            >
              <AgoraVideoPlayer
                style={{ height: "100%", width: "100%" }}
                className="vid"
                videoTrack={controls.localScreen}
              />
              {controls && <ControlsNetworking controls={controls} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RoomAuditoriumNetworking;
