import { ResponsivePie } from "@nivo/pie";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import Button from "../button/button";
import Loading from "../Loading/Loading";
import useRequests from "../../Hooks/UseRequests";

function StatisticsCircleSponsor() {
  const { attendeeid, room } = useParams();
  const { getWithToken } = useRequests();
  const [dataSponsorxTeam, setDataSponsorxTeam] = useState();
  const { dataSponsor, event } = useContext(EventData);
  const [stateView, setStateView] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleTimeString("en-US")
  );

  const getUsersConnected = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/statistics/attendees/sponsors/${dataSponsor.id}?att=${attendeeid}&page=SPONSOR_${room}_STATISTICS`,
    );
    const newData = result.map((stcs) => {
      if (stcs.asistentes > 0) setStateView(true);
      return {
        ...stcs,
        value: stcs.asistentes,
        label: stcs.title,
        id: stcs.title,
      };
    });
    setDataSponsorxTeam(newData);
  };

  const updateStatistics = () => {
    setCurrentDate(new Date().toLocaleTimeString("en-US"));
    setStateView(false);
    getUsersConnected();
  };

  useEffect(() => {
    if (dataSponsor && event) getUsersConnected();
    return () => {};
  }, [dataSponsor, event]);

  return dataSponsorxTeam ? (
    <div className="statistics">
      <div className="statistics-item-head">
        <p className="text-date">
          <FormattedMessage id="boton.messageUpdate" /> {currentDate}
        </p>
        <Button
          variant="early"
          icon={`${process.env.REACT_APP_EVENT_IMG}/live.svg`}
          onClick={() => {
            updateStatistics();
          }}
        >
          <FormattedMessage id="boton.update" />
        </Button>
      </div>

      <div className={stateView ? "statistics-item" : ""}>
        <h3 className="statistics-items-title">
          <FormattedMessage id="statistics.titleNumberAsistansSponsors" />
        </h3>
        {stateView ? (
          <ResponsivePie
            data={dataSponsorxTeam}
            margin={{ top: 10, right: 90, bottom: 90, left: 90 }}
            innerRadius={0.35}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsDiagonalLength={10}
            arcLinkLabelsStraightLength={5}
            arcLinkLabelsTextOffset={0}
            arcLinkLabelsTextColor="#fff"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        ) : (
          <h3 className="message-statistics">
            <FormattedMessage id="statistics.messageViewSponsors" />
          </h3>
        )}
      </div>
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default StatisticsCircleSponsor;
