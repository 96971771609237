import Loading from "../Loading/Loading";
import { useParams } from "react-router-dom";
import React from "react";

function Sponsors({ data, EventSite }) {
  const { attendeeid } = useParams();

  return (
    <div className="formRegistroDesktop">
      {!data ? (
        <Loading />
      ) : (
        <section className="sponsor">
          <div className="grid-gold">
            {data.sponsors.map((sponsor) => (
              <button
                onClick={
                  window.location.pathname !== `/${EventSite}/agenda`
                    ? () =>
                        (window.location = `/${EventSite}/sponsor/${sponsor.id}/attendee/${attendeeid}`)
                    : (e) => e.preventDefault()
                }
                className={sponsor.type === "SILVER" ? "" : "box-sponsor-gold"}
                style={
                  window.location.pathname === `/${EventSite}/agenda`
                    ? { cursor: "default" }
                    : {}
                }
                key={sponsor.id}
                hidden={sponsor.type === "SILVER"}
              >
                <img className="img-sponsor" src={sponsor.logo}></img>
              </button>
            ))}
          </div>
          <div className="grid-silver">
            {data.sponsors.map((sponsor) => (
              <button
                onClick={() =>
                  (window.location = `/${EventSite}/sponsor/${sponsor.id}/attendee/${attendeeid}`)
                }
                hidden={sponsor.type === "GOLD"}
                key={sponsor.id}
                className={sponsor.type === "GOLD" ? "" : "box-sponsor-silver"}
                style={
                  window.location.pathname === `/${EventSite}/agenda`
                    ? { cursor: "default" }
                    : {}
                }
              >
                <img src={sponsor.logo} className="img-sponsor"></img>
              </button>
            ))}
          </div>
        </section>
      )}
    </div>
  );
}

export default Sponsors;
