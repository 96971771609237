import React, { useContext, useEffect, useState } from 'react'
import { EventData } from '../../context/EventData/EventData';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoEvent from '../main/BannerLogo/infoEvent';
import { getActualHour } from './Schedule';
import Register from './Register';
import BannerNextEvent from '../main/bannerNextEvents/marquee';
import Loading from '../Loading/Loading';

function RegisterMobile() {
    const [selected, setSelected] = useState("register");
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const { event } = useContext(EventData);
    const intl = useIntl();
  
    useEffect(() => {
      if (event)
        window.document.title =
          event.event.title +
          " / " +
          intl.formatMessage({ id: "header.schedule" });
    }, [event]);
  
    return event ? (
      <main className="container main-bg" style={{height: "102vh"}}>
        {popupIsOpen && (
          <div className="light-box-register">
            <div className="popup-register">
              <h3>
                <FormattedMessage
                  id="schedule.messagePopup"
                  defaultMessage="Registro"
                />
              </h3>
              <button
                className="button--early-message"
                onClick={() => setPopupIsOpen(false)}
              >
                Ok
              </button>
            </div>
          </div>
        )}
        <InfoEvent
          data={{
            title: event.event.title,
            subject: event.event.subtitle,
            logo: event.event.logo,
            url: event.event.url,
          }}
        />
  
        {getActualHour() < event.event.hour_end ? (
          <div>
            <ul className="list-agenda">
              {!isRegister && (
                <li
                  className={selected === "register" ? "active" : "disabled"}
                  onClick={() => setSelected("register")}
                >
                  <FormattedMessage
                    id="schedule.register"
                    defaultMessage="Registro"
                  />
                </li>
              )}
              {isRegister && (
                <li
                  className={selected === "principal" ? "active" : "disabled"}
                  onClick={() => setSelected("principal")}
                >
                  Principal
                </li>
              )}
            </ul>
            <div className="padding-botton">
              <div className="picture-register space">
                {selected === "register" && (
                  <Register
                    EventId={event.event.id}
                    setPopupIsOpen={setPopupIsOpen}
                    setIsRegister={setIsRegister}
                    setSelected={setSelected}
                  />
                )}
              </div>
              <BannerNextEvent marquee={event.event.marquee} />
            </div>
          </div>
        ) : (
          <h1 style={{ width: "100%", textAlign: "center", color: "white" }}>
            <FormattedMessage id="schedule.end" />
          </h1>
        )}
      </main>
    ) : (
      <Loading />
    );
}

export default RegisterMobile