import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import ControlsAuditoriumNetworking from "./ControlsAuditoriumNetworking";

function RoomAuditoriumNetworking(props) {
  const { users, screens, controls } = props;
  const { dataUser } = useContext(dataUserContext);
  const { rolAuditorium } = useContext(EventData);
  return (
    <div className="screen">
      {screens && users && controls.tracks && rolAuditorium && (
        <div
          id={controls.fullScreen.active ? "full-screen" : "videos"}
          className={
            rolAuditorium &&
            screens &&
            users &&
            (screens.length > 0 || controls.localScreen)
              ? `video-grid-auditorio-sreen`
              : rolAuditorium.rol === "SPEAKER" ||
                rolAuditorium.rol === "MANAGER"
              ? `video-grid-auditorio-${users.length}`
              : `video-grid-auditorio-${users.length - 1}`
          }
        >
          {screens && screens.length > 0 && (
            <div style={{ width: "100%", height: "100%" }}>
              {screens[0].videoTrack !== undefined && (
                <AgoraVideoPlayer
                  style={{ height: "calc(100% - 40px)", width: "100%" }}
                  videoTrack={screens[0].videoTrack}
                />
              )}
            </div>
          )}

          {controls.trackState &&
            controls.trackState.video &&
            controls.tracks.length > 0 &&
            !controls.localScreen &&
            screens.length === 0 && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <AgoraVideoPlayer
                  style={{ height: "calc(100% - 40px)", width: "100%" }}
                  videoTrack={controls.tracks[1]}
                />
                <ControlsAuditoriumNetworking
                  variant={"-networking"}
                  controls={controls}
                />
                <div className="name-user">{dataUser.name}</div>
              </div>
            )}

          {users.length > 0 &&
            !controls.localScreen &&
            screens.length === 0 &&
            users.map((user) => (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
                key={user.uid}
              >
                {!user._video_muted_ && user.videoTrack !== undefined ? (
                  <AgoraVideoPlayer
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                    videoTrack={user.videoTrack}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                    alt="main screen"
                    className="screenVideo"
                  />
                )}
                {/* {rolAuditorium.rol !== "ATTENDEE" && (
                    <ControlsAtendeeRemote
                      userUid={user.uid}
                      videoMuted={user._video_muted_}
                      audioMuted={user._audio_muted_}
                    />
                  )} */}
                <div className="name-user">{user.userName}</div>
              </div>
            ))}

          {controls.tracks.length === 0 &&
            users.length === 0 &&
            !controls.localScreen &&
            screens.length === 0 && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                {rolAuditorium.rol !== "ATTENDEE" && (
                  <ControlsAuditoriumNetworking
                    variant={"-networking"}
                    controls={controls}
                  />
                )}
              </div>
            )}

          {!controls.localScreen &&
            screens.length === 0 &&
            controls.trackState &&
            users.length === 0 &&
            !controls.trackState.video &&
            (rolAuditorium.rol === "ATTENDEE" ||
              rolAuditorium.rol === "SPONSOR") && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
              </div>
            )}

            {!controls.localScreen &&
            screens.length === 0 &&
            controls.trackState &&
            users.length === 0 &&
            !controls.trackState.video &&
            (rolAuditorium.rol === "MANAGER" ||
              rolAuditorium.rol === "SPEAKER") && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                <ControlsAuditoriumNetworking
                    variant={"-networking"}
                    controls={controls}
                  />
              </div>
            )}
            {!controls.localScreen &&
            screens.length === 0 &&
            controls.trackState &&
            users.length > 0 &&
            !controls.trackState.video &&
            (rolAuditorium.rol === "MANAGER" ||
              rolAuditorium.rol === "SPEAKER") && (
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
                <ControlsAuditoriumNetworking
                    variant={"-networking"}
                    controls={controls}
                  />
              </div>
            )}
          {controls.localScreen && (
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              <AgoraVideoPlayer
                style={{ height: "100%", width: "100%" }}
                className="vid"
                videoTrack={controls.localScreen}
              />
              <ControlsAuditoriumNetworking
                variant={"-networking"}
                controls={controls}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RoomAuditoriumNetworking;
