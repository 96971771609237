import { useParams } from "react-router-dom";
import { createChannel, createClient } from "agora-rtm-react";
import useRegisterActivity from "../../Hooks/useRegisterActivity";
import { useContext, useEffect, useRef, useState } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { FormattedMessage, useIntl } from "react-intl";
import { consoleLogFunction } from "../../functions/functionLog";
import CameraDistribution from "../main/CameraDistribution/CameraDistribution";
import CameraDistributionNetwork from "../main/CameraDistribution/CameraDistributionNetwork";
import Survey from "../Survey";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");
let handStatusGuests = {};

function ChatComponentMobile({ ids, children, roomPrincipal, type, typeRoom }) {
  const { room, attendeeid, id } = useParams();
  const client = useClient();
  const { registerActivity } = useRegisterActivity();
  const { dataUser } = useContext(dataUserContext);
  const [messagesReceived, setMessagesReceived] = useState([]);
  const [handsUp, setHandsUp] = useState([]);
  const [handStatus, setHandStatus] = useState({});
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ch, setCh] = useState();
  const messageScroll = useRef();

  const [dateUser, setDateUser] = useState(
    new Date().toLocaleTimeString("en-US")
  );
  const {
    setUserSocket,
    setUsersName,
    actionsUsers,
    setActionsUsers,
    setUserValidation,
    rolAuditorium,
    selectedChat,
    setSelectedchat,
  } = useContext(EventData);
  const { screens, localScreen } = useContext(usersConected);
  const intl = useIntl();
  const [messageSaved, setMessageSaved] = useState("");
  const [message, setMessage] = useState({
    command: "CHAT",
    name: "",
    room: room,
    message: "",
    date: "",
    id: attendeeid,
  });

  let login = async () => {
    const uid = `${ids.uid}-${btoa(dataUser.name)}-${dateUser}-CHAT`;
    const channel = createChannel(room || roomPrincipal);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state, reason) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("ChannelMessage", (msg) => {
      const received = JSON.parse(msg.text);
      if (received.command && received.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, received]);
      } else if (
        received.command &&
        received.command === "ATTENDEE" &&
        received.action === "VALIDATE" &&
        received.id === attendeeid
      ) {
        setUserValidation(true);
      } else if (
        received.command &&
        (received.command === "HAND-UP" || received.command === "HAND-DOWN")
      ) {
        if (received.command === "HAND-UP") {
          setHandsUp((prevHand) => [...prevHand, received]);
        } else {
          setHandsUp((prevHand) =>
            prevHand.filter((hand) => hand.uid !== received.uid)
          );
        }
      } else if (
        received.command &&
        received.command === "HAND-DOWN-GUEST" &&
        rolAuditorium.rol === "MANAGER"
      ) {
        accessUserGuest(received.id, testChannel);
      } else {
        setUserSocket(received);
      }
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
      setUserSocket({
        command: "ATTENDEE",
        action: "IN-ROOM",
        name: dataUser.name,
        room: room,
        id: attendeeid,
        date: dateUser,
      });
      setActionsUsers({
        command: "ATTENDEE",
        action: "VALIDATE",
        name: dataUser.name,
        room: room,
        id: attendeeid,
        date: dateUser,
      });
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "guest" && member !== "recording") {
        const user = member.split("-");
        if (!result.find((us) => us.name === atob(user[1]))) {
          result.push({
            name: atob(user[1]),
            date: user[2],
            uid: parseInt(user[0]),
          });
        }
      }
    });
    setOnlineUsers([...result]);
    setUsersName([...result]);
  };

  const sendMsg = async (text) => {
    let message = client.createMessage({
      text: JSON.stringify(text),
      messageType: "TEXT",
    });
    await ch.sendMessage(message).then(() => {
      if (text.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, text]);
        registerActivity(type, "CHAT_SEND");
      }
    });
  };

  const accessUserGuest = (handUid, ch) => {
    const sendMsgGuest = async (text) => {
      let message = client.createMessage({
        text: JSON.stringify(text),
        messageType: "TEXT",
      });
      await ch.sendMessage(message);
    };

    if (handStatusGuests[handUid]) {
      sendMsgGuest({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      setUserSocket({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      delete handStatus[handUid];
      delete handStatusGuests[handUid];
    } else {
      setHandStatus({ ...handStatus, [handUid]: true });
      sendMsgGuest({
        command: "ACCESS-DEVICES",
        id: attendeeid,
        date: dateUser,
        to: handUid,
        from: ids.uid,
      });
    }
  };

  const accessUser = (handUid) => {
    if (handStatus[handUid]) {
      sendMsg({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      setUserSocket({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      delete handStatus[handUid];
    } else {
      setHandStatus({ ...handStatus, [handUid]: true });
      sendMsg({
        command: "ACCESS-DEVICES",
        id: attendeeid,
        date: dateUser,
        to: handUid,
        from: ids.uid,
      });
    }
  };

  const accessUserManager = (uid, rol) => {
    if (rol === "SPEAKER") {
      sendMsg({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setUserSocket({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "ATTENDEE" };
          } else {
            return user;
          }
        })
      );
    } else if (rol !== "MANAGER") {
      sendMsg({
        command: "ACCESS-DEVICES-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "SPEAKER" };
          } else {
            return user;
          }
        })
      );
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMessage({
        ...message,
        message: message.message + "\n",
      });
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    const date = new Date();
    if (dataUser) message.name = dataUser.name;
    message.message =
      message.message !== ""
        ? message.message.replace(/\r?\n/g, "<br>")
        : messageSaved.replace(/\r?\n/g, "<br>");
    message.date = date.toLocaleTimeString("en-US");

    sendMsg(message);
    setMessageSaved(message.message);
    setMessage({
      ...message,
      message: "",
    });
  };

  useEffect(() => {
    login();

    return () => {
      client.logout();
    };
  }, []);

  useEffect(() => {
    if (actionsUsers && actionsUsers.command === "HAND-DOWN-GUEST-ADMIN") {
      accessUser(actionsUsers.id);
    } else {
      if (actionsUsers) sendMsg(actionsUsers);
    }
  }, [actionsUsers]);

  useEffect(() => {
    if (messagesReceived.length > 0) {
      messageScroll.current?.scrollIntoView({ block: "end" });
    }
  }, [messagesReceived]);

  useEffect(() => {
    handStatusGuests = handStatus;
  }, [handStatus]);

  useEffect(() => {
    if (handsUp) {
      const sortUsersOnline = onlineUsers.sort((a, b) => {
        const hasHandA =
          handsUp.find((hand) => hand.uid === a.uid) !== undefined;
        const hasHandB =
          handsUp.find((hand) => hand.uid === b.uid) !== undefined;

        if (hasHandA && !hasHandB) return -1;
        if (!hasHandA && hasHandB) return 1;
        return 0;
      });
      setOnlineUsers(sortUsersOnline);
      setTimeout(() => {
        setOnlineUsers((prevUsers) => [...prevUsers]);
      }, 1000);
    }
  }, [handsUp]);

  return (
    <>
      {selectedChat.isOpen && (
        <section className="ligth-box">
          <div className="container-chat">
            <button
              className="close-chat"
              onClick={() => {
                setSelectedchat({ ...selectedChat, isOpen: false });
              }}
            >
              x
            </button>
            <div className={`chat-mobile`}>
              <ul className="chat-list">
                <li className={"active"}>
                  {selectedChat.selection === "messages" && "Chat"}
                  {selectedChat.selection === "assistants" && "Asistentes"}
                  {selectedChat.selection === "surveys" && "Encuentas"}
                </li>
              </ul>
              <div className={`messages`}>
                {selectedChat.selection === "messages" && (
                  <div>
                    <div className="messages-container">
                      {messagesReceived &&
                        messagesReceived.map((message, index) => {
                          return (
                            <div key={index}>
                              <p
                                style={{
                                  marginBottom: "-20px",
                                  lineHeight: "90%",
                                }}
                                className={"message-person"}
                              >
                                {message.name}
                                <br></br>
                                <span style={{ fontSize: "10px" }}>
                                  {message.date}
                                </span>
                              </p>

                              <div
                                style={{
                                  marginTop: "30px",
                                  position: "relative",
                                }}
                              >
                                {message.message
                                  .split("<br>")
                                  .map((line, i) => (
                                    <p key={i} ref={messageScroll}>
                                      {line}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <form>
                      <textarea
                        type="text"
                        name="message"
                        value={message.message}
                        placeholder={intl.formatMessage({
                          id: "chat.writeMessage",
                        })}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                      />
                    </form>
                  </div>
                )}
                {selectedChat.selection === "assistants" && (
                  <div className="messages-container">
                    {onlineUsers &&
                      onlineUsers.map((user, index) => (
                        <div key={user.name + "-" + index} className="conexion">
                          <div className="circle-conexion" />
                          <div>
                            {user.name}-{user.date}{" "}
                            {localScreen &&
                              ids.uid_desktop === user.uid_desktop && (
                                <FormattedMessage id="chat.Compartir" />
                              )}
                            {screens &&
                              screens.length > 0 &&
                              screens[0].uid === user.uid_desktop && (
                                <FormattedMessage id="chat.Compartir" />
                              )}
                          </div>
                          {ids.uid !== user.uid &&
                            rolAuditorium.rol !== "SPEAKER-GUEST" &&
                            typeRoom &&
                            typeRoom === "AUDITORIO_NETWORKING" && (
                              <div
                                className={
                                  user.rol === "SPEAKER" ||
                                  user.rol === "MANAGER"
                                    ? "hands"
                                    : "hands color-red"
                                }
                                onClick={() =>
                                  accessUserManager(user.uid, user.rol)
                                }
                              >
                                <img src="https://resources.virtuales.io/eventos/img/host.svg"></img>
                                <div className="tooltip-chat">
                                  {user.rol === "SPEAKER" ||
                                  user.rol === "MANAGER" ? (
                                    <FormattedMessage id="chat.RemoveAccessHand" />
                                  ) : (
                                    <FormattedMessage id="chat.AccessHost" />
                                  )}
                                </div>
                              </div>
                            )}
                          {handsUp.find((hand) => hand.uid === user.uid) &&
                            rolAuditorium.rol !== "SPEAKER-GUEST" &&
                            typeRoom &&
                            typeRoom === "AUDITORIO_NETWORKING" && (
                              <div
                                className={
                                  !handStatus[user.uid]
                                    ? "hands"
                                    : "hands color-red"
                                }
                                onClick={() => accessUser(user.uid)}
                              >
                                <img src="https://resources.virtuales.io/eventos/img/manoarriba.svg"></img>
                                <div className="tooltip-chat">
                                  {!handStatus[user.uid] ? (
                                    <FormattedMessage id="chat.AccessWithHand" />
                                  ) : (
                                    <FormattedMessage id="chat.RemoveAccessHand" />
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                )}
                {selectedChat.selection === "surveys" && (
                  <div>
                    <div style={{ height: "100%" }}>
                      <Survey rol={rolAuditorium.rol} sendMsg={sendMsg} />
                    </div>
                  </div>
                )}
              </div>
              {window.location.pathname ===
                `/${id}/networkings=${room}&att=${attendeeid}` && (
                <CameraDistributionNetwork />
              )}
              {window.location.pathname !==
                `/${id}/networkings=${room}&att=${attendeeid}` && (
                <CameraDistribution />
              )}
              <div>{children}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ChatComponentMobile;
