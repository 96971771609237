import React from "react";

function ControlStatusAudioDistribution({ audioMuted }) {

  return (
    <div className="controls-networking mn">
      <div>
        <img
          src={
            !audioMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
          width={30}
        ></img>
      </div>
    </div>
  );
}

export default ControlStatusAudioDistribution;
