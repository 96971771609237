import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { FormattedMessage } from "react-intl";
import Loading from "../Loading/Loading";
import { isKeyExists } from "../HomeContent/HomeContent";
import useRegisterActivity from "../../Hooks/useRegisterActivity";
import useRequests from "../../Hooks/UseRequests";

function Attendees({ EventId }) {
  const { attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { registerActivityGlobal } = useRegisterActivity();
  const [data, setData] = useState(false);
  const csvLinkRef = useRef(null);
  const [dataActivity, setDataActivity] = useState([]);
  const dateFile = new Date().toDateString().replace(" ", "_");
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
    { label: "Date", key: "date" },
  ];

  const headers2 = [
    { label: "Name", key: "attendee" },
    { label: "Screen", key: "screen" },
    { label: "Action", key: "action" },
    { label: "Date", key: "date" },
  ];

  async function getData() {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${EventId}/attendees?att=${attendeeid}&list=ALL`
    );
    setData(result.attendees);
  }

  const getDataActivitys = (userId) => {
    getWithToken(
      `${process.env.REACT_APP_URI_API}/${EventId}/activity?att=${userId}`,
    )
      .then((data) => {
        setDataActivity(data === null ? [] : data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (csvLinkRef && csvLinkRef.current) csvLinkRef.current.link.click();
  }, [dataActivity]);

  return (
    <div>
      <div>
        {data && (
          <CSVLink
            data={data}
            headers={headers}
            filename={`attendees_${EventId}_${dateFile}.csv`}
            className="btn-primary"
            onClick={() => {
              registerActivityGlobal("HOME", "DOWN_ATTENDEES");
            }}
          >
            <FormattedMessage id="schedule.download" />
          </CSVLink>
        )}
      </div>
      {data ? (
        <div className="attendees">
          <table className="table-attendees">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="table.name" />
                </th>
                <th>
                  <FormattedMessage id="table.Email" />
                </th>
                <th>
                  <FormattedMessage id="table.Company" />
                </th>
                <th>
                  <FormattedMessage id="table.Date" />
                </th>
                <th>
                  <FormattedMessage id="table.Activity" />
                </th>
              </tr>
            </thead>

            <tbody>
              {data &&
                data.map((user, i) => {
                  const date = new Date(user.date);
                  return (
                    <tr key={i}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.company}</td>
                      <td>{user.date}</td>
                      <td className="cell-activity">
                        <button
                          className="btn-primary-download-activity"
                          onClick={() => {
                            getDataActivitys(user.id);
                          }}
                        >
                          <FormattedMessage id="schedule.download" />
                        </button>
                        <CSVLink
                          data={dataActivity}
                          headers={headers2}
                          ref={csvLinkRef}
                          style={{ display: "none" }}
                          filename={`activity_${user.name.replaceAll(
                            " ",
                            "_"
                          )}_${new Date().toISOString()}.csv`}
                        >
                          <FormattedMessage id="schedule.download" />
                        </CSVLink>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Attendees;
