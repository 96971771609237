import React, { useContext } from "react";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { EventData } from "../../../context/EventData/EventData";
import { createChannel, createClient } from "agora-rtm-react";
import { useState } from "react";
import { consoleLogFunction } from "../../../functions/functionLog";
import { useEffect } from "react";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");

function CameraDistributionRecording({ room }) {
  const client = useClient();
  const [ch, setCh] = useState();

  const { setUsersName } = useContext(EventData);
  const { speakers, screens } = useContext(usersConected);

  let login = async () => {
    const uid = `recording`;
    const channel = createChannel(room);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state, reason) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "guest" && member !== "recording") {
        const user = member.split("-");
        if (!result.find((us) => us.name === atob(user[1]))) {
          result.push({
            name: atob(user[1]),
            date: user[2],
            uid: parseInt(user[0]),
          });
        }
      }
    });
    setUsersName([...result]);
  };

  useEffect(() => {
    login();

    return () => {
      client.logout();
    };
  }, []);

  return (
    <div className={"marginTop"} style={{ width: "100%" }}>
      {screens.length > 0 &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams marginTop" key={user.uid} style={{backgroundColor: "var(--header_bg)"}}>
            {!user._video_muted_ && user.videoTrack !== undefined ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="vid-minicams-localscreen"
              />
            )}
            <div className="minicams-name">{user?.userName}</div>
          </div>
        ))}
    </div>
  );
}

export default CameraDistributionRecording;
