import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Hamburger from "hamburger-react";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { EventData } from "../../context/EventData/EventData";

function HeaderMobile({ dataUser }) {
  const { attendeeid } = useParams();
  const { event, setSelected } = useContext(EventData);
  const [isOpen, setOpen] = useState(false);

  const isToggled = (toggled) => {
    const navbar = document.getElementById("nav");
    if (toggled) {
      navbar.className = "nav";
    } else {
      navbar.className = "nav hidden";
    }
  };

  const onClickSelected = (selection) => {
    setSelected(selection);
    isToggled(!isOpen);
  };

  return (
    <>
      {event && (
        <header className="header">
          <div className="wrapper">
            <Hamburger
              rounded
              toggled={isOpen}
              toggle={setOpen}
              onToggle={isToggled}
              color="#fff"
            />
            <img
              src={event && event.event.logo}
              className="logo-nav"
              alt="logo"
            />
            <Link to="/">
              <img
                className="imgfaq"
                src={`${process.env.REACT_APP_EVENT_IMG}/faq.svg`}
                alt="faq"
                width="55"
                height="55"
              />
            </Link>
          </div>
          <ul className="nav hidden" id="nav">
            <li className="nav-item">
              <Link
                to={`/${event.event.site}/agenda/attendee/${attendeeid}`}
                className="nav-link bold"
                onClick={() => onClickSelected("principal")}
              >
                Principal
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/${event.event.site}/agenda/attendee/${attendeeid}`}
                className="nav-link"
                onClick={() => onClickSelected("sponsor")}
              >
                Patrocinadores
              </Link>
            </li>
            {/* <li className="nav-item menum">
            <div
              className="nav-link"
              onClick={() => onClickSelected("sponsor")}
            >
              Estadisticas
            </div>
          </li> */}
            <li className="nav-item menum">
              <Link
                to={`/${event.event.site}/agenda/attendee/${attendeeid}`}
                className="nav-link"
                onClick={() => onClickSelected("networking")}
              >
                Networking
              </Link>
            </li>
            <li className="nav-item menum">
              <Link
                to={`/${event.event.site}/agenda/attendee/${attendeeid}`}
                className="nav-link"
                onClick={() => onClickSelected("networking")}
              >
                Networking
              </Link>
            </li>
            {dataUser &&
              window.location.pathname !== `/${event.event.site}/agenda` && (
                <li className="name-user-nav">
                  <FormattedMessage
                    id="header.welcome"
                    defaultMessage="Bienvenido(a) "
                  />
                </li>
              )}

            <li></li>

            {dataUser &&
              window.location.pathname !== `/${event.event.site}/agenda` && (
                <li style={{ width: "100%", textAlign: "center" }}>
                  {dataUser && dataUser.name ? dataUser.name : ""}
                </li>
              )}
          </ul>
        </header>
      )}
    </>
  );
}

export default HeaderMobile;
