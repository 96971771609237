import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionsProvider from "../../context/Actions/ActionsControl";
import { isKeyExists } from "../HomeContent/HomeContent";
import { EventData } from "../../context/EventData/EventData";
import VideoInfoWrapperRecording from "../main/VideoWrapperRecording";
import InfoEventRecording from "./BannerLogo/InfoEventRecording";
import CameraDistributionRecording from "./CameraDistribution/CameraDistributionRecording";
import useRequests from "../../Hooks/UseRequests";

function MainRecording() {
  const { attendeeid, id } = useParams();
  const { getWithToken } = useRequests();
  const [token, setToken] = useState();
  const [dataInfo, setdataInfo] = useState(false);
  const [dataInfoRoom, setdataInfoRoom] = useState(false);
  const [data2, setData2] = useState();
  const { event, setEvent } = useContext(EventData);

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${dataInfo[0].room}/token/MANAGER?name=${event.event.name}?att=${id}`
    );
    setToken(res);
  }

  async function getDataInfoRoom() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/sites/${event.event.site}/${dataInfo[0].room}?att=${attendeeid}`
    );
    setdataInfoRoom(res.schedule[0]);
  }

  async function getDataInfo() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/schedules`
    );
    delete res.schedule[0].subject;
    res.schedule[0].subject = event.event.subtitle;
    setdataInfo(res.schedule);
  }

  useEffect(() => {
    if (!data2 && id) getData();
  }, [data2]);

  useEffect(() => {
    if (id) getDataEvent();
  }, []);

  const getDataEvent = async () => {
    const req = await getWithToken(
      `${process.env.REACT_APP_URI_API}/sites/${id}`
    );
    setEvent(req);
  };

  const getData = async () => {
    const response = await getWithToken(
      `${process.env.REACT_APP_URI_API}/sites/${id}`
    );
    setData2(response);
  };

  useEffect(() => {
    if (!token && dataInfo) {
      getdata();
      getDataInfoRoom();
    }
    if (!dataInfo && event) {
      getDataInfo();
    }
  }, [dataInfo, event]);

  try {
    return (
      <>
        {dataInfo && <InfoEventRecording data={dataInfo[0]} />}
        <ActionsProvider>
          {token && dataInfo && (
            <section className="auditorium">
              <VideoInfoWrapperRecording
                token={token}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                dataInfo={dataInfoRoom}
              />
              <div className="chat-2">
                <CameraDistributionRecording
                  room={dataInfo && dataInfo[0].room}
                />
              </div>
            </section>
          )}
        </ActionsProvider>
      </>
    );
  } catch (error) {
    return <h1>Ocurrio un Error</h1>;
  }
}

export default MainRecording;
