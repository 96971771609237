import React from "react";

import useWindowSize from "../../utils/useWindowSize";
import AgendaDesktop from "./agendaDesktop";
import AgendaMobile from "./agendaMobile";
import RegisterMobile from "./RegisterMobile";

function Agenda() {
  const { width } = useWindowSize();
  const breakpointWidth = 768;

  return <>{width >= breakpointWidth ? <AgendaDesktop /> : <RegisterMobile />}</>;
}

export default Agenda;
