import { AgoraVideoPlayer } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { createChannel, createClient } from "agora-rtm-react";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import Loading from "../Loading/Loading";
import { getActualHour } from "./Schedule";
import useRequests from "../../Hooks/UseRequests";

const create_client = createClient("e20fe2fdad9c40f98db4f2f99206a40a");

function Networkings({ data, EventSite }) {
  
  const { attendeeid } = useParams();
  const { event } = useContext(EventData);
  const { getWithToken } = useRequests();
  const [dataNetwork, setDataNetwork] = useState(false);
  const [roomUser, setRoomUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [roomSelected, setRoomSelected] = useState(null);
  const [tracks, setTracks] = useState(false);
  const [dataNetworkings, setDataNetworkings] = useState(false);
  const getDataNetwork = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/networking/attendees`
    );
    setDataNetwork(result);
  };
  consoleLogFunction("salones de NETWORKING", data);
  const testCamera = (room) => {
    setRoomSelected(room);
    setIsOpen(true);

    AgoraRTC.getDevices().then(async (devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });

      var selectedMicrophoneId = audioDevices[0].deviceId;
      var selectedCameraId = videoDevices[0].deviceId;
      const getDevices = [
        await AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
        await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: selectedMicrophoneId,
        }),
      ];
      setTracks(getDevices);
    });
  };

  const writeSheets = (sh, room) => {
    let s = [];
    const search = dataNetwork.find((d) => d.room === room);
    let counter = 0;
    if (sh > 10)
      for (let i = 0; i <= 23; i++) {
        if (i === 0 || i === 7 || i === 16 || i === 23 || (i >= 9 && i <= 14)) {
          if (i >= 9 && i <= 14) {
            s.push(
              <div
                key={i}
                className={
                  search.asistentes === 0
                    ? "human-online-mesa12-disable"
                    : "human-online-mesa12"
                }
              ></div>
            );
          } else {
            s.push(<div key={i} className="human-online-space"></div>);
          }
        } else {
          if (i > 16) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro180"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 8) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro45"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 15) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro90"
                ></img>
              </div>
            );
            counter++;
          }
          if (i < 8) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online"
                ></img>
              </div>
            );
            counter++;
          }
        }
      }

    if (sh >= 7 && sh <= 10)
      for (let i = 0; i <= 17; i++) {
        if (
          i === 0 ||
          i === 5 ||
          i === 7 ||
          i === 8 ||
          i === 9 ||
          i === 10 ||
          i === 12 ||
          i === 17
        ) {
          if (i >= 7 && i <= 10) {
            s.push(
              <div
                key={i}
                className={
                  search.asistentes === 0
                    ? "human-online-mesa12-disable"
                    : "human-online-mesa12"
                }
              ></div>
            );
          } else {
            s.push(<div key={i} className="human-online-space"></div>);
          }
        } else {
          if (i > 12) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro180"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 6) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro45"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 11) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro90"
                ></img>
              </div>
            );
            counter++;
          }
          if (i < 6) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online"
                ></img>
              </div>
            );
            counter++;
          }
        }
      }

    if (sh < 7 && sh > 3)
      for (let j = 0; j <= 17; j++) {
        if (
          j === 0 ||
          j === 5 ||
          j === 1 ||
          j === 4 ||
          j === 6 ||
          j === 8 ||
          j === 9 ||
          j === 11 ||
          j === 12 ||
          j === 16 ||
          j === 13 ||
          j === 17
        ) {
          if (j >= 8 && j <= 9) {
            s.push(
              <div
                key={j}
                className={
                  search.asistentes === 0
                    ? "human-online-mesa12-disable"
                    : "human-online-mesa12"
                }
              ></div>
            );
          } else {
            s.push(<div key={j} className="human-online-space"></div>);
          }
        } else {
          if (j > 12) {
            s.push(
              <div className="human-online" key={j}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro180"
                ></img>
              </div>
            );
            counter++;
          }
          if (j === 7 && sh === 4) {
            s.push(<div key={j} className="human-online-space"></div>);
          }
          if (j === 7 && sh !== 4) {
            s.push(
              <div className="human-online" key={j}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro45"
                ></img>
              </div>
            );
            counter++;
          }

          if (j === 10 && sh !== 4 && sh !== 5) {
            s.push(
              <div className="human-online" key={j}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro90"
                ></img>
              </div>
            );
            counter++;
          }

          if (j === 10 && (sh === 4 || sh === 5)) {
            s.push(<div key={j} className="human-online-space"></div>);
          }

          if (j < 6) {
            s.push(
              <div className="human-online" key={j}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online"
                ></img>
              </div>
            );
            counter++;
          }
        }
      }

    if (sh <= 3)
      for (let i = 0; i <= 17; i++) {
        if (
          i === 0 ||
          i === 1 ||
          i === 3 ||
          i === 4 ||
          i === 5 ||
          i === 7 ||
          i === 6 ||
          i === 8 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 13 ||
          i === 17
        ) {
          if (i >= 8 && i <= 8) {
            s.push(
              <div
                key={i}
                className={
                  search.asistentes === 0
                    ? "human-online-mesa-circle-disable"
                    : "human-online-mesa-circle"
                }
              ></div>
            );
          } else {
            s.push(<div key={i} className="human-online-space"></div>);
          }
        } else {
          if (i === 2) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 12) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro230"
                ></img>
              </div>
            );
            counter++;
          }
          if (i === 16 && sh === 2) {
            s.push(<div key={i} className="human-online-space"></div>);
          }
          if (i === 16 && sh !== 2) {
            s.push(
              <div className="human-online" key={i}>
                {counter < search.asistentes && (
                  <p className="tooltip-network">
                    {roomUser[room].length > 0 &&
                      roomUser[room][counter].name &&
                      roomUser[room][counter].name}
                  </p>
                )}
                <img
                  src={
                    counter < search.asistentes
                      ? "https://resources.virtuales.io/eventos/img/silla-blanco.svg"
                      : "https://resources.virtuales.io/eventos/img/silla-azul.svg"
                  }
                  className="human-online giro80"
                ></img>
              </div>
            );
            counter++;
          }
        }
      }
    return s;
  };

  const login = async (index) => {
    if (
      event &&
      event.event &&
      event.event.networking &&
      index < event.event.networking.length
    ) {
      const client = create_client();
      const channel = createChannel(event.event.networking[index].room);
      const testChannel = channel(client);
      await client.login({
        uid: "guest",
      });
      await testChannel.join();
      const members = await testChannel.getMembers();
      await client.logout().then(() => {
        login(index + 1);
      });
      const result = [];
      members.map((member) => {
        if (member !== "guest") {
          const user = member.split("-");
          if (!result.find((us) => us.name === atob(user[1]))) {
            result.push({
              name: atob(user[1]),
              date: user[2],
              uid: parseInt(user[0]),
            });
          }
        }
      });
      setRoomUser((prevUser) => {
        return {
          ...prevUser,
          [event.event.networking[index].room]: [...result],
        };
      });
    }
  };

  useEffect(() => {
    login(0);
    setDataNetworkings(data);
    setInterval(() => {
      getDataNetwork();
      login(0);
    }, 30000);
  }, []);

  useEffect(() => {
    if (!dataNetwork)
      setTimeout(() => {
        getDataNetwork();
      }, 2000);
  }, [dataNetwork]);

  try {
    return dataNetwork ? (
      <div className="networking">
        {isOpen && (
          <div className="light-popup-network">
            <div className="popup-network">
              <div className="camera-network">
                {tracks ? (
                  <AgoraVideoPlayer
                    style={{
                      height: "calc(100%)",
                      width: "100%",
                      borderRadius: "10px !important",
                    }}
                    videoTrack={tracks[0]}
                  />
                ) : (
                  <Loading />
                )}
              </div>
              <div className="buttons-popup">
                <a
                  href={`/${EventSite}/networkings/${roomSelected}/attendee/${attendeeid}`}
                  className="button-network"
                >
                  <FormattedMessage id="event.button" />
                </a>
                <div
                  className="button-network"
                  onClick={() => {
                    if (tracks && tracks[0]) {
                      tracks[0].setEnabled(false);
                      tracks[1].setEnabled(false);
                    }
                    setIsOpen(false);
                  }}
                >
                  <FormattedMessage id="networ.cancel" />
                </div>
              </div>
            </div>
          </div>
        )}
        {dataNetworkings &&
          dataNetworkings.map((network, i) => (
            <div className="box-network" key={i}>
              <section className="network-stn1">
                <div>
                  <h3>{network.title}</h3>
                </div>
                {network.online && (
                  <div className="alert-red">
                    <p>
                      ◉ <FormattedMessage id="networking.live" />
                    </p>
                  </div>
                )}
              </section>
              <section
                className={
                  network.sheets <= 10 ? "network-stn2" : "network-m15"
                }
              >
                {writeSheets(network.sheets, network.room).map((img) => img)}
              </section>
              <section className="network-stn3">
                <div>
                  <h3>
                    {dataNetwork &&
                      dataNetwork.map((networking) => {
                        if (network.room === networking.room) {
                          return networking.asistentes;
                        }
                      })}{" "}
                    <FormattedMessage id="networking.of" /> {network.sheets}
                  </h3>
                  <h6>
                    <FormattedMessage id="networking.UsersActive" />
                  </h6>
                </div>
                {dataNetwork &&
                  network.online &&
                  dataNetwork.map((networking) => {
                    if (
                      network.room === networking.room &&
                      networking.asistentes < network.sheets &&
                      getActualHour() > network.begin_utc / 100 &&
                      getActualHour() < network.end_utc / 100
                    )
                      return (
                        <div
                          onClick={() => testCamera(network.room)}
                          key={i}
                          className="button-network"
                        >
                          <FormattedMessage id="event.button" />
                        </div>
                      );
                    if (
                      network.room === networking.room &&
                      networking.asistentes === network.sheets
                    )
                      return (
                        <h3>
                          <FormattedMessage id="event.messageFull" />
                        </h3>
                      );
                  })}
              </section>
            </div>
          ))}
      </div>
    ) : (
      <Loading />
    );
  } catch (error) {
    return <Loading />;
  }
}

export default Networkings;
