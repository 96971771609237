import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import Event from "./views/Event";
import Agenda from "./views/agenda";
import Home from "./views/Home";
import Error404 from "./views/Error404";
import AuditoriumSponsor from "./views/AuditoriumSponsor";
import AuditoriumNetworking from "./views/AuditoriumNetworking";
import AgendaUser from "./views/AgendaUser";
import AuditoriumNetworkings from "./views/AuditoriumNetworkings";
import { Routes } from "react-router-dom/dist";
import ViewAuditoriumNetworking from "./views/ViewAuditoriumNetworking";
import AuditoriumRecording from "./views/AuditoriumRecording";

function App() {
  return (
    <Router>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/:id" element={<Event />} />
        <Route index path="/:id/agenda" element={<Agenda />} />
        <Route
          index
          path="/:id/agenda/attendee/:attendeeid"
          element={<AgendaUser />}
        />
        <Route
          index
          path="/:id/:room_type/:room/attendee/:attendeeid"
          element={<AuditoriumSponsor />}
        />
        <Route
          index
          path="/:id/networkings/:room/attendee/:attendeeid"
          element={<AuditoriumNetworkings />}
        />
        <Route
          index
          path="/:id/:room/attendee/:attendeeid"
          element={<ViewAuditoriumNetworking />}
        />
        <Route
          index
          path="/:id/:room_type/:room_networking_type/:room/attendee/:attendeeid/active/:active"
          element={<AuditoriumNetworking />}
        />
        <Route
          index
          path="/:id/recording"
          element={<AuditoriumRecording />}
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;
