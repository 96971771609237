import React from 'react'
import VideoCallNetworking from './VideoCallNetworking'

function VideoNetworking({ children, token }) {
    return (
        <section className="marginT" style={{ borderRadius: "10px", overflow: "hidden", position: "relative" }}>
            <div className={"display-video"}>
                <VideoCallNetworking token={token}></VideoCallNetworking>
            </div>
            {children}
        </section>
    )
}

export default VideoNetworking