import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import { EventData } from "../../../context/EventData/EventData";
import ControlStatusAudioDistribution from "../MainScreen/ControlStatusAudioDistribution";
import "./CameraDistribution.css";

function CameraDistribution() {
  const { speakers, screens, tracks, localScreen } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);
  const { rolAuditorium } = useContext(EventData);
  const [classStyle, setClassStyle] = useState();
  const { room_type, id, room, attendeeid } = useParams();
  useEffect(() => {
    if (room_type === "networking" || room_type === "networking-private") {
      if (tracks && tracks.enabled)
        setClassStyle(`marginTop video-grid-auditorio-${speakers.lengt}`);
      if (!tracks)
        setClassStyle(`marginTop video-grid-auditorio-${speakers.length - 1}`);
    }
    if (
      (room_type !== "networking" || room_type !== "networking-private") &&
      window.location.pathname !==
        `/${id}/networkings=${room}&att=${attendeeid}` &&
      rolAuditorium &&
      rolAuditorium.rol !== "ATTENDEE"
    ) {
      setClassStyle(`marginTop video-grid-auditorio-${speakers.length}`);
    } else if (
      (room_type !== "networking" || room_type !== "networking-private") &&
      window.location.pathname !==
        `/${id}/networkings=${room}&att=${attendeeid}`
    ) {
      let value = speakers.length - 1;
      setClassStyle(`marginTop video-grid-auditorio-${value}`);
    } else if (
      window.location.pathname ===
      `/${id}/networkings=${room}&att=${attendeeid}`
    ) {
      let value = speakers.length;
      setClassStyle(`marginTop video-grid-auditorio-${value}`);
    }
  }, [room_type, speakers]);

  return (
    <div
      className={classStyle ? classStyle : " marginTop video-grid-auditorio-0"}
    >
      {rolAuditorium &&
        rolAuditorium.rol !== "ATTENDEE" &&
        tracks &&
        tracks.length > 0 &&
        tracks[1].enabled &&
        (screens.length > 0 || localScreen) &&
        speakers.length > 0 && (
          <div className="miniCams">
            <AgoraVideoPlayer className="vid-minicams" videoTrack={tracks[1]} />
            <div className="minicams-name">{dataUser.name}</div>
          </div>
        )}

      {rolAuditorium &&
        rolAuditorium.rol !== "ATTENDEE" &&
        tracks &&
        tracks.length > 0 &&
        tracks[1].enabled &&
        (screens.length > 0 || localScreen) &&
        speakers.length === 0 && (
          <div className="vid-minicams-local">
            <AgoraVideoPlayer
              className="vid-minicams-localscreen"
              videoTrack={tracks[1]}
            />
            <div className="minicams-name">{dataUser.name}</div>
          </div>
        )}

      {rolAuditorium &&
        rolAuditorium.rol !== "ATTENDEE" &&
        localScreen &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {!user._video_muted_ && user.videoTrack !== undefined ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="vid-minicams-localscreen"
              />
            )}
            <ControlStatusAudioDistribution audioMuted={user._audio_muted_} />
            <div className="minicams-name">{user.userName}</div>
          </div>
        ))}

      {rolAuditorium &&
        rolAuditorium.rol === "ATTENDEE" &&
        screens.length > 0 &&
        speakers.length > 1 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {!user._video_muted_ && user.videoTrack !== undefined  ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="vid-minicams-localscreen"
              />
            )}
            <ControlStatusAudioDistribution audioMuted={user._audio_muted_} />
            <div className="minicams-name">{user.userName}</div>
          </div>
        ))}

      {rolAuditorium &&
        rolAuditorium.rol !== "ATTENDEE" &&
        screens.length > 0 &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {!user._video_muted_ && user.videoTrack !== undefined  ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="vid-minicams-localscreen"
              />
            )}
            <ControlStatusAudioDistribution audioMuted={user._audio_muted_} />
            <div className="minicams-name">{user.userName}</div>
          </div>
        ))}

      {rolAuditorium &&
        screens.length > 0 &&
        rolAuditorium.rol === "ATTENDEE" &&
        speakers.length === 1 && (
          <div className="vid-minicams-local">
            {!speakers[0]._video_muted_ && speakers[0].videoTrack ? (
              <AgoraVideoPlayer
                className="vid-minicams-localscreen"
                videoTrack={speakers[0].videoTrack}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="vid-minicams-localscreen"
              />
            )}
            <ControlStatusAudioDistribution
              userUid={speakers[0].uid}
              audioMuted={speakers[0]._audio_muted_}
            />
            <div className="minicams-name">
              {speakers[0].userName}
            </div>
          </div>
        )}
    </div>
  );
}

export default CameraDistribution;
