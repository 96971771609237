import AgoraRTC, { createClient } from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { consoleLogFunction } from "../../../functions/functionLog";
import { EventData } from "./../../../context/EventData/EventData";
import { usersConected } from "./../../../context/CamsConected/CamsConected";
import { dataUserContext } from "./../../../context/DataUser/DataUser";
import { useParams } from "react-router-dom";
import RoomAuditorium from "../../RoomAuditorium/RoomAuditorium";
import RoomAuditoriumRecording from "./RoomAuditoriumRecording";

const config = {
  mode: "live",
  codec: "vp8",
};

const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallRecording(props) {
  const { token } = props;

  const { usersName } = useContext(EventData);
  const { screens, setScreens, localScreen, setSpeakers, speakers } =
    useContext(usersConected);

  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const client = useClient();
  let screenClient = useDesktopClient();

  const getUserName = async (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return await response;
  };
  const clientJoin = async () => {
    if (token && !loginUser)
      await client
        .join(token.app, token.channel, token.token, token.uid)
        .then(() => {
          setLoginUser(true);
          consoleLogFunction("VideoCallNotCamera:96", "::conectado::");
        })
        .catch(() => {
          setLoginUser(false);
          consoleLogFunction("VideoCallNotCamera:100", "::desconectado::");
        });
  };

  const screenClientJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCall:165", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCall:169", "::desconectado::");
      });
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        setTimeout(async () => {
          const userName = await getUserName(user.uid);

          await client.subscribe(user, mediaType);

          user.userName = userName.name;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 1000);
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (token && !loginUser) {
        client.setClientRole("host", function (e) {
          if (!e) {
            consoleLogFunction("VideoCall:223", "::setHost success");
          } else {
            consoleLogFunction("VideoCall:225", "::setHost error", e);
          }
        });

        clientJoin();
      }
    };

    if (token) {
      init();
    }
  }, [client, token, loginUser]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      await screenClient.subscribe(user, mediaType);
      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio") {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });

    screenClientJoin();
  }, [loginScreen]);

  useEffect(() => {
    return () => {
      screenClient.removeAllListeners();
      screenClient.leave();
      client.removeAllListeners();
      client.leave();
    };
  }, []);

  try {
    return (
      <div className="vid">
        <RoomAuditoriumRecording
          users={speakers}
          screens={screens}
          controls={{ tracks: [] }}
        />
      </div>
    );
  } catch (error) {
    return <h1>Ocurrio un error</h1>;
  }
}

export default VideoCallRecording;
