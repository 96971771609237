import React, { useContext } from "react";
import useDataRoom from "../../../Hooks/useDataRoom";
import ActionsProvider from "../../../context/Actions/ActionsControl";
import Loading from "../../Loading/Loading";
import VideoInfoWrapperMobile from "../VideoInfoWrapperMobile";
import ChatComponentMobile from "../../ChatComponent/ChatComponentMobile";
import { EventData } from "../../../context/EventData/EventData";

function MainMobile() {
  const { dataEvent, rolAuditorium } = useContext(EventData);
  const { token, dataInfo, dataInfoRoom } = useDataRoom();
  try {
    return (
      <>
        <ActionsProvider>
          {token && dataInfo ? (
            <section className="auditorium">
              <VideoInfoWrapperMobile
                token={token}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                dataInfo={dataInfoRoom}
                typeRoom={dataInfo[0].type}
              />

              <ChatComponentMobile
                roomPrincipal={dataInfo[0].room}
                dataEvent={dataEvent}
                showAttendees={rolAuditorium.rol !== "ATTENDEE" ? true : false}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                type={"PRINCIPAL"}
                typeRoom={dataInfo[0].type}
              />
            </section>
          ) : (
            <Loading />
          )}
        </ActionsProvider>
      </>
    );
  } catch (error) {
    console.error(error);
  }
}

export default MainMobile;
