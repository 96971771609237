import React, { useContext, useEffect, useState } from "react";
import VideoCallSponsor from "./VideoCallSponsor";
import ChannelFormSponsor from "./ChannelFormSponsor";
import VideoCallNotCameraSponsor from "./VideoCallNotCameraSponsor";
import { dataUserContext } from "../../context/DataUser/DataUser";

function VideoSponsor({ inCall, setInCall, validateRol, token }) {
  const { dataUser } = useContext(dataUserContext);

  return (
    <section
      style={{ borderRadius: "10px", overflow: "hidden", position: "relative" }}
    >
      {validateRol && dataUser && validateRol.rol !== "ATTENDEE" ? (
        <div>
          {inCall && validateRol.rol !== "ATTENDEE" ? (
            <div>
              <VideoCallSponsor setInCall={setInCall} token={token} validateRol={validateRol}/>
            </div>
          ) : (
            <div>
              <ChannelFormSponsor setInCall={setInCall} />
            </div>
          )}
        </div>
      ) : (
        <div>
          <VideoCallNotCameraSponsor token={token} validateRol={validateRol}></VideoCallNotCameraSponsor>
        </div>
      )}
    </section>
  );
}

export default VideoSponsor;
