import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { langContext } from "../../context/lang/langContext";

function HeaderDesktopEmpty() {
  const { establecerLenguaje, locale } = useContext(langContext);

  return (
    <>
      <header className="header">
        <div className="container flex">
          <img
            src={`${process.env.REACT_APP_EVENT_IMG}/logo-virtual-es.svg`}
            className="logo-header"
            alt="logo"
          />

          <ul className="nav" style={{ width: "auto" }}>
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div>
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/reino-unido.png`}
                  alt="faq"
                  width="25"
                  height="25"
                  onClick={() => establecerLenguaje("en-US")}
                  style={
                    locale === "en-US"
                      ? {
                          border: "1px solid white",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          marginRight: "8px",
                        }
                      : {}
                  }
                />

                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_EVENT_IMG}/colombia.png`}
                  alt="faq"
                  width="25"
                  height="25"
                  onClick={() => establecerLenguaje("es-MX")}
                  style={
                    locale === "es-MX"
                      ? {
                          border: "1px solid white",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          marginLeft: "8px",
                        }
                      : {}
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default HeaderDesktopEmpty;
