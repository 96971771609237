import React from 'react'
import CamsConected from '../context/CamsConected/CamsConected';
import Footer from '../components/Footer/Footer';
import MainRecording from '../components/main/MainRecording';
import { useEffect } from 'react';

function AuditoriumRecording() {
  useEffect(() => {
    window.document.title = "Recording";
  }, []);
  
  return (
    <CamsConected>
        <div className="App">
          <div className="container" style={{ margin: "auto" }}>
            <MainRecording/>
          </div>
          <Footer />
        </div>
    </CamsConected>
  );
}

export default AuditoriumRecording