import React from 'react'
import { useParams } from 'react-router-dom';
import VideoNetworking from './VideoNetworking';
import BannerNextEvent from '../main/bannerNextEvents/marquee'

function VideoWrapperNetworking({data, token}) {

    return (
        <section className="videoWrapper">
            <VideoNetworking token={token}>
                <BannerNextEvent marquee={data.marquee && data.marquee}></BannerNextEvent>
            </VideoNetworking>
        </section>);
}

export default VideoWrapperNetworking