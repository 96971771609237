import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { dataUserContext } from "../../context/DataUser/DataUser";
import CameraDistribution from "../main/CameraDistribution/CameraDistribution";
import "./ChatComponent.css";
import { FormattedMessage, useIntl } from "react-intl";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import CameraDistributionNetwork from "../main/CameraDistribution/CameraDistributionNetwork";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { useRef } from "react";
import { createChannel, createClient } from "agora-rtm-react";
import useRegisterActivity from "../../Hooks/useRegisterActivity";
import Survey from "../Survey";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");
let handStatusGuests = {};

function ChatComponent({
  dataEvent,
  showAttendees,
  ids,
  children,
  roomPrincipal,
  type,
  typeRoom,
}) {
  const { room, room_type, attendeeid, id } = useParams();
  const client = useClient();
  const { registerActivity } = useRegisterActivity();
  const [selected, setSelected] = useState("messages");
  const { dataUser } = useContext(dataUserContext);
  const [messagesReceived, setMessagesReceived] = useState([]);
  const [handsUp, setHandsUp] = useState([]);
  const [handStatus, setHandStatus] = useState({});
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ch, setCh] = useState();
  const messageScroll = useRef();

  const [dateUser, setDateUser] = useState(
    new Date().toLocaleTimeString("en-US")
  );
  const {
    setUserSocket,
    event,
    setUsersName,
    actionsUsers,
    setActionsUsers,
    setUserValidation,
    rolAuditorium,
  } = useContext(EventData);
  const { screens, localScreen } = useContext(usersConected);
  const intl = useIntl();
  const [messageSaved, setMessageSaved] = useState("");
  const [message, setMessage] = useState({
    command: "CHAT",
    name: "",
    room: room,
    message: "",
    date: "",
    id: attendeeid,
  });

  let login = async () => {
    const uid = `${ids.uid}-${btoa(dataUser.name)}-${dateUser}-CHAT`;
    const channel = createChannel(room || roomPrincipal);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state, reason) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("ChannelMessage", (msg) => {
      const received = JSON.parse(msg.text);
      if (received.command && received.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, received]);
      } else if (
        received.command &&
        received.command === "ATTENDEE" &&
        received.action === "VALIDATE" &&
        received.id === attendeeid
      ) {
        setUserValidation(true);
      } else if (
        received.command &&
        (received.command === "HAND-UP" || received.command === "HAND-DOWN")
      ) {
        if (received.command === "HAND-UP") {
          setHandsUp((prevHand) => [...prevHand, received]);
        } else {
          setHandsUp((prevHand) =>
            prevHand.filter((hand) => hand.uid !== received.uid)
          );
        }
      } else if (
        received.command &&
        received.command === "HAND-DOWN-GUEST" &&
        rolAuditorium.rol === "MANAGER"
      ) {
        accessUserGuest(received.id, testChannel);
      } else {
        setUserSocket(received);
      }
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
      setUserSocket({
        command: "ATTENDEE",
        action: "IN-ROOM",
        name: dataUser.name,
        room: room,
        id: attendeeid,
        date: dateUser,
      });
      setActionsUsers({
        command: "ATTENDEE",
        action: "VALIDATE",
        name: dataUser.name,
        room: room,
        id: attendeeid,
        date: dateUser,
      });
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "guest" && member !== "recording") {
        const user = member.split("-");
        if (!result.find((us) => us.name === atob(user[1]))) {
          result.push({
            name: atob(user[1]),
            date: user[2],
            uid: parseInt(user[0]),
          });
        }
      }
    });
    setOnlineUsers([...result]);
    setUsersName([...result]);
  };

  const sendMsg = async (text) => {
    let message = client.createMessage({
      text: JSON.stringify(text),
      messageType: "TEXT",
    });
    await ch.sendMessage(message).then(() => {
      if (text.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, text]);
        registerActivity(type, "CHAT_SEND");
      }
    });
  };

  const accessUserGuest = (handUid, ch) => {
    const sendMsgGuest = async (text) => {
      let message = client.createMessage({
        text: JSON.stringify(text),
        messageType: "TEXT",
      });
      await ch.sendMessage(message);
    };

    if (handStatusGuests[handUid]) {
      sendMsgGuest({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      setUserSocket({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      delete handStatus[handUid];
      delete handStatusGuests[handUid];
    } else {
      setHandStatus({ ...handStatus, [handUid]: true });
      sendMsgGuest({
        command: "ACCESS-DEVICES",
        id: attendeeid,
        date: dateUser,
        to: handUid,
        from: ids.uid,
      });
    }
  };

  const accessUser = (handUid) => {
    if (handStatus[handUid]) {
      sendMsg({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      setUserSocket({
        command: "ACCESS-FINISH",
        id: attendeeid,
        date: dateUser,
        to: handUid,
      });
      delete handStatus[handUid];
    } else {
      setHandStatus({ ...handStatus, [handUid]: true });
      sendMsg({
        command: "ACCESS-DEVICES",
        id: attendeeid,
        date: dateUser,
        to: handUid,
        from: ids.uid,
      });
    }
  };

  const accessUserManager = (uid, rol) => {
    if (rol === "SPEAKER") {
      sendMsg({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setUserSocket({
        command: "ACCESS-FINISH-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "ATTENDEE" };
          } else {
            return user;
          }
        })
      );
    } else if (rol !== "MANAGER") {
      sendMsg({
        command: "ACCESS-DEVICES-M",
        id: attendeeid,
        date: dateUser,
        to: uid,
        from: ids.uid,
      });
      setOnlineUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.uid === uid) {
            return { ...user, rol: "SPEAKER" };
          } else {
            return user;
          }
        })
      );
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMessage({
        ...message,
        message: message.message + "\n",
      });
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    const date = new Date();
    if (dataUser) message.name = dataUser.name;
    message.message =
      message.message !== ""
        ? message.message.replace(/\r?\n/g, "<br>")
        : messageSaved.replace(/\r?\n/g, "<br>");
    message.date = date.toLocaleTimeString("en-US");

    sendMsg(message);
    setMessageSaved(message.message);
    setMessage({
      ...message,
      message: "",
    });
  };

  useEffect(() => {
    login();

    return () => {
      client.logout();
    };
  }, []);

  useEffect(() => {
    if (actionsUsers && actionsUsers.command === "HAND-DOWN-GUEST-ADMIN") {
      accessUser(actionsUsers.id);
    } else {
      if (actionsUsers) sendMsg(actionsUsers);
    }
  }, [actionsUsers]);

  useEffect(() => {
    if (messagesReceived.length > 0) {
      messageScroll.current?.scrollIntoView({ block: "end" });
    }
  }, [messagesReceived]);

  useEffect(() => {
    handStatusGuests = handStatus;
  }, [handStatus]);

  useEffect(() => {
    if (handsUp) {
      const sortUsersOnline = onlineUsers.sort((a, b) => {
        const hasHandA =
          handsUp.find((hand) => hand.uid === a.uid) !== undefined;
        const hasHandB =
          handsUp.find((hand) => hand.uid === b.uid) !== undefined;

        if (hasHandA && !hasHandB) return -1;
        if (!hasHandA && hasHandB) return 1;
        return 0;
      });
      setOnlineUsers(sortUsersOnline);
      setTimeout(() => {
        setOnlineUsers((prevUsers) => [...prevUsers]);
      }, 1000);
    }
  }, [handsUp]);
  
  return (
    <div className={`chat`} style={{ marginTop: "-15px" }}>
      {dataEvent &&
        room &&
        room_type === "sponsor" &&
        dataEvent.logo !== event.event.logo && (
          <div className="logo-sponsor">
            <a href={dataEvent.link} target={"_blank"}>
              <img className="img-sponsor" src={dataEvent.logo}></img>
            </a>
          </div>
        )}
      {dataEvent &&
        room &&
        room_type === "networking" &&
        dataEvent.logo !== event.event.logo && (
          <div className="logo-sponsor">
            <a href={dataEvent.link} target={"_blank"}>
              <img className="img-sponsor" src={dataEvent.logo}></img>
            </a>
          </div>
        )}

      <ul className="chat-list">
        <li
          className={selected === "messages" ? "active" : "disabled"}
          onClick={() => {
            setSelected("messages");
            registerActivity(type, "CHAT");
          }}
        >
          Chat
        </li>
        {showAttendees &&
          (rolAuditorium.rol === "SPEAKER" ||
            rolAuditorium.rol === "MANAGER") && <li>|</li>}
        {showAttendees &&
          (rolAuditorium.rol === "SPEAKER" ||
            rolAuditorium.rol === "MANAGER") && (
            <li
              className={selected === "assistants" ? "active" : "disabled"}
              onClick={() => {
                setSelected("assistants");
                registerActivity(type, "ATTENDEES");
              }}
            >
              <FormattedMessage id="chat.assistants" defaultMessage="Nombre" />
            </li>
          )}
        <li>|</li>
        <li
          className={selected === "surveys" ? "active" : "disabled"}
          onClick={() => {
            setSelected("surveys");
          }}
        >
          <FormattedMessage id="tab.surveys" defaultMessage="Encuestas" />
        </li>
      </ul>
      <div className={`messages ${roomPrincipal && "border"}`}>
        {selected === "messages" && (
          <div>
            <div className="messages-container">
              {messagesReceived &&
                messagesReceived.map((message, index) => {
                  return (
                    <div key={index}>
                      <p
                        style={{ marginBottom: "-20px", lineHeight: "90%" }}
                        className={"message-person"}
                      >
                        {message.name}
                        <br></br>
                        <span style={{ fontSize: "10px" }}>{message.date}</span>
                      </p>

                      <div style={{ marginTop: "30px", position: "relative" }}>
                        {message.message.split("<br>").map((line, i) => (
                          <p key={i} ref={messageScroll}>
                            {line}
                          </p>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>

            <form>
              <textarea
                type="text"
                name="message"
                value={message.message}
                placeholder={intl.formatMessage({ id: "chat.writeMessage" })}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </form>
          </div>
        )}
        {selected === "assistants" && (
          <div className="messages-container">
            {onlineUsers &&
              onlineUsers.map((user, index) => (
                <div key={user.name + "-" + index} className="conexion">
                  <div className="circle-conexion" />
                  <div>
                    {user.name}-{user.date}{" "}
                    {localScreen && ids.uid_desktop === user.uid_desktop && (
                      <FormattedMessage id="chat.Compartir" />
                    )}
                    {screens &&
                      screens.length > 0 &&
                      screens[0].uid === user.uid_desktop && (
                        <FormattedMessage id="chat.Compartir" />
                      )}
                  </div>
                  {ids.uid !== user.uid &&
                    rolAuditorium.rol !== "SPEAKER-GUEST" &&
                    typeRoom &&
                    typeRoom === "AUDITORIO_NETWORKING" && (
                      <div
                        className={
                          user.rol === "SPEAKER" || user.rol === "MANAGER"
                            ? "hands"
                            : "hands color-red"
                        }
                        onClick={() => accessUserManager(user.uid, user.rol)}
                      >
                        <img src="https://resources.virtuales.io/eventos/img/host.svg"></img>
                        <div className="tooltip-chat">
                          {user.rol === "SPEAKER" || user.rol === "MANAGER" ? (
                            <FormattedMessage id="chat.RemoveAccessHand" />
                          ) : (
                            <FormattedMessage id="chat.AccessHost" />
                          )}
                        </div>
                      </div>
                    )}
                  {handsUp.find((hand) => hand.uid === user.uid) &&
                    rolAuditorium.rol !== "SPEAKER-GUEST" &&
                    typeRoom &&
                    typeRoom === "AUDITORIO_NETWORKING" && (
                      <div
                        className={
                          !handStatus[user.uid] ? "hands" : "hands color-red"
                        }
                        onClick={() => accessUser(user.uid)}
                      >
                        <img src="https://resources.virtuales.io/eventos/img/manoarriba.svg"></img>
                        <div className="tooltip-chat">
                          {!handStatus[user.uid] ? (
                            <FormattedMessage id="chat.AccessWithHand" />
                          ) : (
                            <FormattedMessage id="chat.RemoveAccessHand" />
                          )}
                        </div>
                      </div>
                    )}
                </div>
              ))}
          </div>
        )}
        {selected === "surveys" && (
          <div>
            <div style={{ height: "100%" }}>
              <Survey rol={rolAuditorium.rol} sendMsg={sendMsg} />
            </div>
          </div>
        )}
      </div>
      {window.location.pathname ===
        `/${id}/networkings=${room}&att=${attendeeid}` && (
        <CameraDistributionNetwork />
      )}
      {window.location.pathname !==
        `/${id}/networkings=${room}&att=${attendeeid}` && (
        <CameraDistribution />
      )}
      <div>{children}</div>
    </div>
  );
}

export default ChatComponent;
