import React, { useState } from "react";
import "./style.css";
import { EventData } from "../../../context/EventData/EventData";
import { useContext } from "react";

function DropdownMenuMobile() {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const { setSelectedchat, rolAuditorium } = useContext(EventData);

  const handleClickSelect = (selected) => {
    setSelectedchat({ isOpen: true, selection: selected });
    setDropdownIsOpen(false);
  };
  return (
    <div className="dropdown">
      <div
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
        className="title-drop"
      >
        <img
          src="https://cdn.icon-icons.com/icons2/624/PNG/512/Menu_2-80_icon-icons.com_57301.png"
          height={40}
        ></img>
      </div>
      {dropdownIsOpen && (
        <div className="dropdown-content">
          <ul>
            <li onClick={() => handleClickSelect("messages")}>Chat</li>
            {(rolAuditorium.rol === "MANAGER" ||
              rolAuditorium.rol === "SPEAKER") && (
              <li onClick={() => handleClickSelect("assistants")}>
                Asistentes
              </li>
            )}

            <li onClick={() => handleClickSelect("surveys")}>Encuestas</li>

            <li onClick={() => handleClickSelect("info")}>Información</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownMenuMobile;
