import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import ChatComponent from "../ChatComponent/ChatComponent";
import CountDownNetwork from "../CountDown/CountDownNetwork";
import { isKeyExists } from "../HomeContent/HomeContent";
import Loading from "../Loading/Loading";
import InfoEvent from "../main/BannerLogo/infoEvent";
import BannerNextEvent from "../main/bannerNextEvents/marquee";
import VideoWrapperNetworkings from "./VideoWrapperNetworkings";
import useRegisterActivity from "../../Hooks/useRegisterActivity";
import useRequests from "../../Hooks/UseRequests";

function MainNetworkings() {
  const { room, attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const { registerActivity } = useRegisterActivity();
  const { event, setRolAuditorium, rolAuditorium } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const [dataNetwork, setDataNetwork] = useState(false);
  const [token, setToken] = useState();

  const getRolUser = async () => {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/rol?att=${attendeeid}`
    );

    setRolAuditorium(res);
  };

  async function getdata() {
    const res = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/rooms/${room}/token/${dataUser.rol}?name=${dataUser.name}?att=${attendeeid}`
    );
    setToken(res);
  }

  useEffect(() => {
    if (event) getRolUser();
    if (event) {
      const data = event.event.networking.find(
        (networking) => networking.room === room
      );
      setDataNetwork(data);
    }
  }, [event]);

  useEffect(() => {
    if (!token && dataUser) {
      getdata();
      registerActivity("NETWORKING", "ACCESS");
    }
  }, [dataUser, token]);

  return dataUser && token && dataNetwork && rolAuditorium ? (
    <>
      <InfoEvent
        data={{
          title: dataNetwork.title,
          hours: dataNetwork.subtitle,
          logo: event.event.logo,
          group: " ",
          url: event.event.url,
        }}
      />
      {dataNetwork && (
        <section className="auditorium " style={{ marginTop: "20px" }}>
          <VideoWrapperNetworkings
            token={token}
            ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
          >
            <BannerNextEvent marquee={event.event.marquee} />
          </VideoWrapperNetworkings>

          <ChatComponent
            ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
            dataEvent={{ event: event.id }}
            style={{ width: "100%", backgoundColor: "black" }}
            showAttendees={true}
            type={"NETWORKING"}
          >
            <CountDownNetwork></CountDownNetwork>
          </ChatComponent>
        </section>
      )}
    </>
  ) : (
    <Loading></Loading>
  );
}

export default MainNetworkings;
