import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { EventData } from "../../../context/EventData/EventData";
import Button from "../../button/button";
import useRegisterActivity from "../../../Hooks/useRegisterActivity";

function ControlsAtendeeRemote({ userUid, videoMuted, audioMuted, type }) {
  const { room } = useParams();
  const { registerActivity } = useRegisterActivity();
  const { event, setActionsUsers, usersName } = useContext(EventData);
  const [nameUser, setNameUser] = useState();

  const muteUser = async (comm) => {
    setActionsUsers({
      type: "controls",
      room: room,
      command: comm,
      to: userUid,
      product: "EVENTS",
      product_id: event.event.id,
    });
  };

  const handDownUser = () => {
    setActionsUsers({
      command: "HAND-DOWN-GUEST-ADMIN",
      id: userUid,
    });
  };

  useEffect(() => {
    if (usersName.length > 0) {
      const name = usersName.findIndex((user) => user.uid === userUid);
      if (name !== -1) {
        setNameUser(usersName[name].name);
      }
    }
  }, [usersName]);

  return (
    nameUser && (
      <div className="controls-networking">
        <Button
          variant={!audioMuted ? "controls-menu" : "off"}
          onClick={() => {
            muteUser(audioMuted ? "MIC-ON" : "MIC-OFF");
            registerActivity(
              type,
              audioMuted ? "MIC_OFF_ATT" : "MIC_ON_ATT",
              nameUser
            );
          }}
          icon={
            !audioMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
        >
          <p className="tip-text">
            {!audioMuted ? (
              <FormattedMessage id="controls.disabledMic" />
            ) : (
              <FormattedMessage id="controls.enabledMic" />
            )}
          </p>
        </Button>
        <Button
          variant={!videoMuted ? "controls-menu" : "off"}
          onClick={() => {
            muteUser(videoMuted ? "CAMERA-ON" : "CAMERA-OFF");
            registerActivity(
              type,
              audioMuted ? "CAM_OFF_ATT" : "CAM_ON_ATT",
              nameUser
            );
          }}
          icon={
            !videoMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
          }
        >
          <p className="tip-text">
            {!videoMuted ? (
              <FormattedMessage id="controls.disabledCam" />
            ) : (
              <FormattedMessage id="controls.enabledCam" />
            )}
          </p>
        </Button>
        <Button
          variant="off-social"
          onClick={handDownUser}
          icon={`${process.env.REACT_APP_EVENT_IMG}/manoarriba.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.handDown" />
          </p>
        </Button>
        <Button
          variant="controls-menu"
          onClick={() => {
            muteUser("END");
            registerActivity(type, "EXIT_ATT", nameUser);
          }}
          icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.exit" />
          </p>
        </Button>
      </div>
    )
  );
}

export default ControlsAtendeeRemote;
