import React from "react";
import HeaderMobile from "./headerMobile";
import HeaderDesktopEmpty from "./HeaderDesktopEmpty";
import useWindowSize from "./../../utils/useWindowSize";
import HeaderMobileEmpty from "./HeaderMobileEmpty";

function HeaderEmpty() {
  const { width } = useWindowSize();
  const breakpointWidth = 768;

  return (
    <>{width >= breakpointWidth ? <HeaderDesktopEmpty /> : <HeaderMobileEmpty />}</>
  );
}

export default HeaderEmpty;
