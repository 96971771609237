import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import EventDataProvider from "./context/EventData/EventData";
import { LangProvider } from "./context/lang/langContext";
import DataUser from "./context/DataUser/DataUser";
import SurveyProvider from "./context/SurveyContext/SurveyContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LangProvider>
    <EventDataProvider>
      <DataUser>
        <SurveyProvider>
          <App />
        </SurveyProvider>
      </DataUser>
    </EventDataProvider>
  </LangProvider>
);
