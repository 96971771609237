import React from "react";
import VideoInfo from "./MainScreen/video";
import DescriptionEvent from "./InfoSpeaker/infoSpeaker";

function VideoInfoWrapper({ token, ids, dataInfo, setSelected, typeRoom }) {
  return (
    <div className="videoWrapper">
      <VideoInfo
        token={token}
        ids={ids}
        setSelected={setSelected}
        typeRoom={typeRoom}
      />
      <DescriptionEvent data={dataInfo} />
    </div>
  );
}

export default VideoInfoWrapper;
