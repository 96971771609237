import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useContext, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router-dom";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import useBackgroundVideo from "../../Hooks/useBackgroundVideo";
import RoomAuditoriumNetworking from "./RoomAuditoriumNetworking";

const config = {
  mode: "live",
  codec: "vp8",
};
let configCamera = {
  encoderConfig: "1080_p",
};

let useMicrophoneAndCameraTracks =
  createMicrophoneAndCameraTracks(configCamera);

let stateDevices = false;

async function getDevices() {
  await navigator.mediaDevices
    .enumerateDevices()
    .then(() => (stateDevices = true));
}

getDevices();

let namesUsers = [];
const useClient = createClient(config);
const useDesktopClient = createClient(config);

function VideoCallNetworking({ token }) {
  let microphoneAndCamera = useMicrophoneAndCameraTracks();
  let ready = false;
  let tracks = false;

  if (stateDevices) {
    ready = microphoneAndCamera.ready;
    tracks = microphoneAndCamera.tracks;
  }

  const { room, attendeeid } = useParams();
  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
  } = useContext(usersConected);

  const { dataUser } = useContext(dataUserContext);
  const { setActionsUsers, event, usersName, userSocket } =
    useContext(EventData);
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const { setBackgroundColor, setBackgroundImage, setBackgroundBlurring } =
    useBackgroundVideo(tracks);
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const client = useClient();
  let screenClient = useDesktopClient();
  const handle = useFullScreenHandle();
  let screen = null;

  const sharedScreen = async () => {
    if (!loginScreen) {
      screenClient.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCall:84", "::setHost success");
        } else {
          consoleLogFunction("VideoCall:86", "::setHost error" + e);
        }
      });
      screenClientJoin();
    }

    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: token.resolution_desktop,
      },
      "auto"
    );
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-SHARING",
      id: attendeeid,
    };
    setActionsUsers(attendee);
    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    screenClient.publish(screen);

    if (screen instanceof Array)
      screen[0].on("track-ended", () => {
        unSharedScreen();
      });
    else
      screen.on("track-ended", () => {
        unSharedScreen();
      });
  };

  const unSharedScreen = async () => {
    screenClient.leave();
    screenClient.removeAllListeners();
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-UNSHARING",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }
    setLocalScreen(false);
    setLoginScreen(false);
  };

  const getUserName = (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return response;
  };
  const getUserDesktop = (uid) => {
    const response = namesUsers.find((user) => user.uid_desktop === uid);
    return response;
  };

  const screenClientJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCall:165", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCall:169", "::desconectado::");
      });
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        setTimeout(async () => {
          const userName = getUserName(user.uid);

          await client.subscribe(user, mediaType);

          user.userName = userName.name;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 2000);
      });

      client.on("user-unpublished", (user, type) => {
        const userName = getUserName(user.uid);
        user.userName = userName.name;
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (token && !loginUser) {
        client.setClientRole("host", function (e) {
          if (!e) {
            consoleLogFunction("VideoCall:223", "::setHost success");
          } else {
            consoleLogFunction("VideoCall:225", "::setHost error", e);
          }
        });
        await client
          .join(token.app, token.channel, token.token, token.uid)
          .then(() => {
            setLoginUser(true);
            consoleLogFunction("VideoCall:232", "::conectado camara::");
          })
          .catch(() => {
            setLoginUser(false);
            consoleLogFunction("VideoCall:236", "::desconectado::");
          });
      }
    };

    if (!loginUser) init();
  }, [loginUser]);

  useEffect(() => {
    if (tracks && loginUser) {
      tracks[1].setEncoderConfiguration(token.resolution);
      tracks[1].userName = dataUser.name;
      setTracks(tracks);
      client.publish([tracks[0], tracks[1]]);
    }
  }, [loginUser, tracks]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      //const userName = getUserDesktop(user.uid);
      await screenClient.subscribe(user, mediaType);

      //user.userName = userName.name;

      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    consoleLogFunction("VideoCall:298", userSocket);
    if (userSocket) {
      if (userSocket.command === "ATTENDEE" && localScreen) {
        var attendee = {
          type: "screen",
          name: dataUser.name,
          room: room,
          command: "DESKTOP-SHARING",
          id: attendeeid,
          product: "EVENTS",
          product_id: event.event.id,
        };
        setActionsUsers(attendee);
      }
      if (userSocket.type === "screen") {
        if (
          userSocket.command === "DESKTOP-SHARING" &&
          userSocket.name !== dataUser.name
        ) {
          screenClientJoin();
        } else if (
          userSocket.command === "DESKTOP-UNSHARING" &&
          userSocket.name !== dataUser.name
        ) {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  return (
    <div className="vid">
      <FullScreen handle={handle}>
        <RoomAuditoriumNetworking
          controls={
            token && {
              trackState: trackState,
              setBackgroundColor,
              setBackgroundImage,
              setBackgroundBlurring,
              setTrackState: setTrackState,
              tracks: tracks,
              sharedScreen: sharedScreen,
              unSharedScreen: unSharedScreen,
              users: speakers,
              localScreen: localScreen,
              client: client,
              desktop: screenClient,
              fullScreen: handle,
              userId: token.uid,
              screens: screens,
            }
          }
        ></RoomAuditoriumNetworking>
      </FullScreen>
    </div>
  );
}

export default VideoCallNetworking;
