import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "../../button/button";
import { EventData } from "../../../context/EventData/EventData";
import { dataUserContext } from "../../../context/DataUser/DataUser";

function ControlAttende({
  handle,
  client,
  screenClient,
  setSpeakers,
  setLoginUser,
  setLoginScreen,
  setScreens,
  ids,
  setSelected,
  typeRoom,
}) {
  const { setActionsUsers, rolAuditorium } = useContext(EventData);
  const { dataUser } = useContext(dataUserContext);
  const [handStatus, setHandStatus] = useState(false);

  async function deleteConexion() {
    await screenClient.removeAllListeners();
    await screenClient.leave();
    await client.removeAllListeners();
    await client.leave();
    setLoginUser(false);
    setLoginScreen(false);
    setSpeakers([]);
    setScreens([]);
    setSelected("networking");
  }
  const handUp = () => {
    if (handStatus) {
      setActionsUsers({
        command: "HAND-DOWN",
        name: dataUser.name,
        uid: ids.uid,
        uid_desktop: ids.uid_desktop,
      });
      setHandStatus(!handStatus);
    } else {
      setActionsUsers({
        command: "HAND-UP",
        name: dataUser.name,
        uid: ids.uid,
        uid_desktop: ids.uid_desktop,
      });
      setHandStatus(!handStatus);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setActionsUsers({
        command: "ATTENDEE",
        to: ids.uid,
      });
    }, 2000);
  }, []);

  return (
    <div className="controls">
      <Button
        variant={!handle.active ? "controls-menu" : "off-social"}
        onClick={handle.active ? handle.exit : handle.enter}
        icon={
          !handle.active
            ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
        }
      >
        <p className="tip-text">
          {!handle.active ? (
            <FormattedMessage id="controls.fullScreen" />
          ) : (
            <FormattedMessage id="controls.closeFullScreen" />
          )}
        </p>
      </Button>
      {rolAuditorium.rol !== "MANAGER" &&
        rolAuditorium.rol !== "SPEAKER" &&
        typeRoom &&
        typeRoom === "AUDITORIO_NETWORKING" && (
          <Button
            variant={!handStatus ? "controls-menu" : "off-social"}
            onClick={handUp}
            icon={`${process.env.REACT_APP_EVENT_IMG}/manoarriba.svg`}
          >
            <p className="tip-text">
              {!handStatus ? (
                <FormattedMessage id="controls.handUp" />
              ) : (
                <FormattedMessage id="controls.handDown" />
              )}
            </p>
          </Button>
        )}
      <Button
        variant="controls-menu"
        onClick={() => deleteConexion()}
        icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
      >
        <p className="tip-text">
          <FormattedMessage id="controls.exit" />
        </p>
      </Button>
    </div>
  );
}

export default ControlAttende;
