import React from "react";
import VideoMobile from "./MainScreen/VideoMobile";

function VideoInfoWrapperMobile({
  token,
  ids,
  setSelected,
  typeRoom,
  dataInfo,
}) {
  return (
    <div className="videoWrapperMobile">
      <VideoMobile
        dataInfo={dataInfo}
        token={token}
        ids={ids}
        setSelected={setSelected}
        typeRoom={typeRoom}
      />
    </div>
  );
}

export default VideoInfoWrapperMobile;
