import Agenda from "../components/agenda/agendaMain";
import Header from "../components/header/header";
import Footer from "../components/Footer/Footer";
import React from "react";

function AgendaMain() {
  return (
    <div className="App">
      <Header />
      <Agenda />
      <Footer />
    </div>
  );
}

export default AgendaMain;
