import React from "react";
import VideoCallMobile from "./VideoCallMobile";

function VideoMobile({ token, ids, setSelected, typeRoom, dataInfo }) {
  return (
    <section className="videoMainMobile">
      <div className={"display-video-mobile"}>
        <VideoCallMobile
          setSelected={setSelected}
          channelName={"principal"}
          token={token}
          ids={ids}
          typeRoom={typeRoom}
          dataInfo={dataInfo}
        />
      </div>
    </section>
  );
}

export default VideoMobile;
