import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { consoleLogFunction } from "../../functions/functionLog";
import { isKeyExists } from "../HomeContent/HomeContent";
import useRequests from "../../Hooks/UseRequests";

function Register({ EventId, setPopupIsOpen, setIsRegister, setSelected }) {
  const intl = useIntl();
  const { postWithToken } = useRequests();
  const [countries, setCountries] = useState(false);
  const [formData, setFormData] = useState({
    inputName: "",
    inputCountry: "",
    inputCompany: "",
    inputEmail: "",
    termsAndConditions: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChecked = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (!formData.termsAndConditions)
      alert("Acepta los terminos y condiciones");

    if (formData.termsAndConditions) {
      postRegister(EventId, formData);
    }
  };

  function postRegister(eventId, formData) {
    const data = {
      name: formData.inputName,
      company: formData.inputCompany,
      email: formData.inputEmail,
      country: formData.inputCountry,
      privacy_policies: { accept: formData.termsAndConditions },
    };

    const getResult = async () => {
      await postWithToken(`${process.env.REACT_APP_URI_API}/${eventId}/attendees`, data)
        .then(() => {
          setPopupIsOpen(true);
          setIsRegister(true);
          setSelected("principal");
        })
        .catch((error) => consoleLogFunction("error al registrar", error));
    };
    getResult();
  }

  return (
    <div className="formRegistroDesktop">
      <h3 className="formTitle">
        <FormattedMessage
          id="register.title"
          defaultMessage="¡Registrate ahora!"
        />
      </h3>
      <form className="form-register">
        <ul className="form-grid">
          <li>
            <label htmlFor="inputName" className="formLabelDesktop">
              <span>
                <FormattedMessage id="register.name" defaultMessage="Nombre" />
              </span>
              <strong>
                <abbr title="required"> *</abbr>
              </strong>
            </label>
            <input
              type="text"
              className="form-input "
              placeholder={intl.formatMessage({
                id: "register.PlaceHolderName",
              })}
              id="inputName"
              name="inputName"
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label htmlFor="inputEmail" className="formLabelDesktop">
              <FormattedMessage id="register.email" />
              <strong>
                <abbr title="required"> *</abbr>
              </strong>
            </label>
            <input
              type="email"
              className="form-input"
              placeholder={intl.formatMessage({
                id: "register.PlaceHolderEmail",
              })}
              id="inputEmail"
              name="inputEmail"
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label htmlFor="inputCompany" className="formLabelDesktop">
              <FormattedMessage
                id="register.company"
                defaultMessage="Empresa"
              />
            </label>
            <input
              type="text"
              className="form-input"
              id="inputCompany"
              onChange={handleChange}
              placeholder={intl.formatMessage({
                id: "newAppointment.placeHolderCompany",
              })}
              name="inputCompany"
            />
          </li>
          <li style={{ display: "none" }}>
            <label htmlFor="inputCountry" className="formLabelDesktop">
              <FormattedMessage id="register.country" defaultMessage="Pais" />
            </label>
            <select
              className="form-select"
              name="inputCountry"
              onChange={handleChange}
            >
              <option>
                {intl.formatMessage({ id: "register.PlaceHolderCountry" })}
              </option>
              {!countries
                ? ""
                : countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
            </select>
          </li>
        </ul>
        <div className="check-input">
          <label htmlFor="termsAndConditions" className="formSubmit">
            <input
              id="tac"
              type="checkbox"
              name="termsAndConditions"
              onChange={handleChecked}
              required
            />
            <FormattedMessage id="register.terms" />
            <a
              href="https://www.virtuales.io/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00b4cf" }}
            >
              <FormattedMessage id="register.privacy" />
            </a>
          </label>
        </div>
        <button
          type="submit"
          className="btn btn-submit"
          onClick={handleClick}
          disabled={!formData.termsAndConditions}
          style={
            !formData.termsAndConditions
              ? { backgroundColor: "rgba(255, 255, 255, 0.2)" }
              : {}
          }
        >
          <FormattedMessage id="register.button" />
        </button>
      </form>
    </div>
  );
}

export default Register;
