import React from "react";
import CamsConected from "../context/CamsConected/CamsConected";
import RoomType from "../context/RoomType/RoomType";
import Header from "../components/header/header";
import Footer from "../components/Footer/Footer";
import MainRoomSponsor from "../components/RoomSponsors/MainRoomSponsor";
import ActionsProvider from "../context/Actions/ActionsControl";

function AuditoriumSponsor() {
  return (
    <CamsConected>
      <RoomType>
        <div className="App">
          <Header></Header>
          <div className="container" style={{ margin: "auto" }}>
            <ActionsProvider>
              <MainRoomSponsor />
            </ActionsProvider>
          </div>
          <Footer />
        </div>
      </RoomType>
    </CamsConected>
  );
}

export default AuditoriumSponsor;
