import React, { useContext } from "react";
import "./main.css";
import VideoInfoWrapper from "./mainVideoChat";
import ChatComponent from "../ChatComponent/ChatComponent";
import { EventData } from "../../context/EventData/EventData";
import ActionsProvider from "../../context/Actions/ActionsControl";
import Loading from "../Loading/Loading";
import useDataRoom from "../../Hooks/useDataRoom";

function Main({ setSelected }) {
  const { token, dataInfo, dataInfoRoom } = useDataRoom();
  const { dataEvent, rolAuditorium } = useContext(EventData);

  try {
    return (
      <>
        <ActionsProvider>
          {token && dataInfo ? (
            <section className="auditorium">
              <VideoInfoWrapper
                token={token}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                dataInfo={dataInfoRoom}
                setSelected={setSelected}
                typeRoom={dataInfo[0].type}
              />

              <ChatComponent
                roomPrincipal={dataInfo[0].room}
                dataEvent={dataEvent}
                showAttendees={rolAuditorium.rol !== "ATTENDEE" ? true : false}
                ids={{ uid: token.uid, uid_desktop: token.uid_desktop }}
                type={"PRINCIPAL"}
                typeRoom={dataInfo[0].type}
              />
            </section>
          ) : (
            <Loading />
          )}
        </ActionsProvider>
      </>
    );
  } catch (error) {
    console.error(error);
  }
}

export default Main;
