import React from "react";

function InfoEvent({ data }) {
  return (
    <div className="flex-box">
      <div style={{width: "70%"}}>
        <h1 className="title-infoevent">
          {data.title} <br />
          <span className="subtitle-info">
            {data.subject && (
              <div>
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                  width={30}
                ></img>
                {data.subject}
                <br></br>
              </div>
            )}
            {data.speakers && (
              <div>
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                  width={30}
                ></img>
                {data.speakers}
                <br></br>
              </div>
            )}

            {data.group && data.hours && (
              <div className="hour-event">
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/reloj-contorno-circular.svg`}
                  width={25}
                ></img>
                <span style={{ position: "relative", left: "10px" }}>
                  {data.group}
                </span>

                <span style={{ position: "relative", left: "30px" }}>
                  {data.hours}
                </span>
              </div>
            )}
          </span>
        </h1>
      </div>
      <a target="_blank" href={data.url} style={{width: "29%"}}>
        <div className="logo-info">
          <img src={data.logo} className={"logo-info-detail"}/>
        </div>
      </a>
    </div>
  );
}

export default InfoEvent;
