import "./EventDetail.css";

import BannerNextEvent from "../main/bannerNextEvents/marquee";
import Button from "../button/button";
import Loading from "../Loading/Loading";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import InfoEvent from "../main/BannerLogo/infoEvent";
import { useState } from "react";
import useRequests from "../../Hooks/UseRequests";

function EventDetail() {
  const { id } = useParams();
  const { tokenCognito } = useRequests();
  const [data, setData] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const getData = async () => {
    const token = await tokenCognito();
    const result = await fetch(`${process.env.REACT_APP_URI_API}/sites/${id}`, {
      headers: { Autorization: `Bearer ${token.access_token}` },
    });

    if (result.status === 401) {
      setIsPrivate(true);
      setData(true);
    } else {
      const dataSave = await result.json();
      setData(dataSave);
    }
  };

  useEffect(() => {
    if (data && !isPrivate) window.document.title = data.event.title;
  }, [data]);

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
  });

  useEffect(() => {
    getData();
  }, []);

  const ingresar = () => {
    window.location.pathname = `/${data.event.site}/agenda`;
  };

  return (
    <main className="container main-bg" style={{height: "105vh"}}>
      {!data ? (
        <Loading />
      ) : data && data.event && !isPrivate ? (
        <>
          <InfoEvent
            data={{
              title: data.event.title,
              subject: data.event.subtitle,
              logo: data.event.logo,
              url: data.event.url,
            }}
          />
          <div className="grid margin25">
            <div>
              <picture className="picture">
                <img src={data.event.images[0].entrada} className="picture" />
              </picture>
              <BannerNextEvent marquee={data.event.marquee} />
            </div>
            <div className="box">
              <div className="date-box">
                <h2>{data.event.dates}</h2>
                <p>
                  <img
                    src={`${process.env.REACT_APP_EVENT_IMG}/reloj-contorno-circular.svg`}
                    className="watch-circle"
                  />
                  {data.event.hours}
                </p>
              </div>
              <Button
                variant={"get-into"}
                icon={`${process.env.REACT_APP_EVENT_IMG}/ingresar.svg`}
                name={"ingresar"}
                onClick={ingresar}
              >
                <FormattedMessage id="event.button" />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <h1 style={{ color: "#fff", marginTop: "10%", textAlign: "center" }}>
          Evento Privado, si requiere acceso contacte al administrador del
          evento o verifique en su email la invitación al mismo
        </h1>
      )}
    </main>
  );
}

export default EventDetail;
