import React, { useContext, useEffect, useState } from "react";

import "./agenda.css";
import { EventData } from "../../context/EventData/EventData";
import Networkings from "./Networkings";
import useRequests from "../../Hooks/UseRequests";
import { useParams } from "react-router-dom";
import Sponsors from "./Sponsors";
import MainMobile from "../main/MainScreen/MainMobile";
import CamsConected from "../../context/CamsConected/CamsConected";
import RoomType from "../../context/RoomType/RoomType";

function AgendaMobile() {
  const { attendeeid } = useParams();
  const { event, selected } = useContext(EventData);
  const { getWithToken } = useRequests();
  const [dataSpeakers, setDataSpeakers] = useState(false);
  const [dataSponsors, setDataSponsors] = useState(false);

  const getDataSpeakers = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/speakers?att=${attendeeid}&page=HOME_SPEAKERS`
    );
    setDataSpeakers(result);
  };

  const getDataSponsors = async () => {
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/sponsors?att=${attendeeid}&page=HOME_SPONSORS`
    );
    setDataSponsors(result);
  };

  useEffect(() => {
    if (event && event.event) {
      if (!dataSpeakers) getDataSpeakers();
      if (!dataSponsors) getDataSponsors();
    }
  }, [event]);
  
  return (
    <div>
      {selected !== "principal" && (
        <div className="agendaMobile">
          {selected === "networking" && (
            <h1 className="title-shedule-mobile">NETWORKING</h1>
          )}
          {selected === "sponsor" && (
            <h1 className="title-shedule-mobile">PATROCINADORES</h1>
          )}
          <div className="picture space">
            {event && selected === "sponsor" && (
              <Sponsors data={dataSponsors} EventSite={event.event.site} />
            )}
            {event && selected === "networking" && (
              <Networkings
                data={event && event.event.networking}
                EventSite={event && event.event.site}
              />
            )}
          </div>
        </div>
      )}
      {selected === "principal" && (
        <div className="principal">
          <CamsConected>
            <RoomType>
              <MainMobile />
            </RoomType>
          </CamsConected>
        </div>
      )}
    </div>
  );
}

export default AgendaMobile;
