import { useContext } from "react";
import { useParams } from "react-router-dom";
import { dataUserContext } from "../context/DataUser/DataUser";
import useRequests from "./UseRequests";

function useRegisterActivity() {
  const { room, id, attendeeid } = useParams();
  const { dataUser } = useContext(dataUserContext);
  const { tokenCognito } = useRequests();

  async function registerActivity(screen, action, attendee_action) {
    const token = await tokenCognito()
    const body = attendee_action
      ? {
          attendee: dataUser.name,
          screen: screen,
          action: action,
          attendee_action: attendee_action,
        }
      : {
          attendee: dataUser.name,
          screen: screen,
          action: action,
        };

    fetch(
      `${process.env.REACT_APP_URI_API}/${id}/rooms/${
        room ? room : "principal"
      }/activity?att=${attendeeid}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
  }

  async function registerActivityGlobal(screen, action) {
    const token = await tokenCognito()
    const body = {
      attendee: dataUser.name,
      screen: screen,
      action: action,
    };

    fetch(
      `${process.env.REACT_APP_URI_API}/${id}/activity?att=${attendeeid}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
  }
  return { registerActivity, registerActivityGlobal };
}

export default useRegisterActivity;
