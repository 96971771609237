import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./DropdownSponsors.css";
import useRequests from "../../../Hooks/UseRequests";

function DropdownSponsors({ eventId, site }) {
  const { attendeeid } = useParams();
  const [data, setData] = useState(false);
  const { getWithToken } = useRequests();

  const getData = async () => {
    const result = await getWithToken(
      attendeeid
        ? `${process.env.REACT_APP_URI_API}/${eventId}/sponsors?att=${attendeeid}&page=HOME_SPONSORS`
        : `${process.env.REACT_APP_URI_API_CACHE}/${eventId}/sponsors`
    );
    setData(result);
  };

  useEffect(() => {
    if (!data) getData();
  }, []);

  return data && data.sponsors.length > 0 ? (
    <div className="Dropdown">
      <div className="flex-s">
        <Link
          to={`/${site}/agenda/att=${attendeeid}#sponsor`}
          style={{ color: "white" }}
        >
          <FormattedMessage id="schedule.sponsors" />
        </Link>
        <img
          className="arrowDown-left"
          src={`${process.env.REACT_APP_EVENT_IMG}/down-arrow.svg`}
          alt="arrow dropdown"
          width="15"
          height="15"
        />
      </div>

      <div className="Dropdown-content">
        <ul>
          {data &&
            data.sponsors.map((sponsor) => (
              <li key={sponsor.id} style={{ color: "black" }}>
                <Link
                  to={`/${site}/sponsor=${sponsor.id}&attendee=${attendeeid}`}
                >
                  {sponsor.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default DropdownSponsors;
