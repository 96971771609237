import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import Button from "../button/button";

function ControlsNetworkingUsers({
  userId,
  userCommandId,
  videoMuted,
  audioMuted,
}) {
  const { room } = useParams();
  const { event, setActionsUsers} = useContext(EventData);
  
  const muteUser = async (type) => {
    if (type === "audio") {
      setActionsUsers({
        type: "cmd",
        room: room,
        cmd: "MIC-OFF",
        to: userId,
        from: userCommandId,
        product: "EVENTS",
        product_id: event.event.id,
      });
    }
  };

  return (
    <div>
      <div className="controls-networking">
        <Button
          variant={!audioMuted ? "controls-menu" : "off"}
          onClick={(e) => muteUser("MIC-OFF")}
          icon={
            !audioMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
        >
          <p className="tip-text">
            {!audioMuted ? (
              <FormattedMessage id="controls.disabledMic" />
            ) : (
              <FormattedMessage id="controls.enabledMic" />
            )}
          </p>
        </Button>
        <Button
          variant={!videoMuted ? "controls-menu" : "off"}
          onClick={(e) => muteUser("CAMERA-OFF")}
          icon={
            !videoMuted
              ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
          }
        >
          <p className="tip-text">
            {!videoMuted ? (
              <FormattedMessage id="controls.disabledCam" />
            ) : (
              <FormattedMessage id="controls.enabledCam" />
            )}
          </p>
        </Button>
        <Button
          variant="controls-menu"
          onClick={() => {
            muteUser("END");
          }}
          icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.exit" />
          </p>
        </Button>
      </div>
    </div>
  );
}

export default ControlsNetworkingUsers;
