import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import HeaderMobile from "./headerMobile";
import HeaderDesktop from "./headerDesktop";
import useWindowSize from "../../utils/useWindowSize";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { isKeyExists } from "../HomeContent/HomeContent";
import useRequests from "../../Hooks/UseRequests";

function Header() {
  const { width } = useWindowSize();
  const { getWithToken, tokenCognito } = useRequests();
  const breakpointWidth = 768;
  const { setEvent, event } = useContext(EventData);
  const { dataUser, setDataUser } = useContext(dataUserContext);
  const { attendeeid, id } = useParams();
  const [data2, setData2] = useState();
  const [attId, setAttId] = useState(attendeeid);
  const abortController = new AbortController();

  useEffect(() => {
    if (!data2 && id) getData();

    return function cleanup() {
      abortController.abort();
    };
  }, [data2]);

  useEffect(() => {
    if (id) getDataEvent();
  }, []);

  useEffect(() => {
    if (attId && event && event.event.site && !dataUser) {
      getDataUser();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [event]);

  const getDataEvent = async () => {
    const result = await getWithToken(
      attendeeid !== undefined
        ? `${process.env.REACT_APP_URI_API}/sites/${id}?att=${attendeeid}`
        : `${process.env.REACT_APP_URI_API}/sites/${id}`
    );
    setEvent(result);
  };

  const getData = async () => {
    const response = await getWithToken(
      attendeeid !== undefined
        ? `${process.env.REACT_APP_URI_API}/sites/${id}?att=${attendeeid}`
        : `${process.env.REACT_APP_URI_API}/sites/${id}`
    );
    setData2(response);
  };

  const getDataUser = async () => {
    const token = await tokenCognito();
    let headers = { headers: { Autorization: `Bearer ${token}` } };
    if (
      !isKeyExists(headers.headers, "Referer") &&
      process.env.REACT_APP_LOCAL === "false"
    ) {
      headers.headers.Referer = `${process.env.REACT_APP_REFERER}`;
    }
    await fetch(
      `${process.env.REACT_APP_URI_API}/sites/${event.event.site}/attendees/${attId}`,
      headers
    ).then(async (response) => {
      if (response.status === 200) {
        const result = await response.json();
        const resRol = await fetch(
          `${process.env.REACT_APP_URI_API}/${event.event.id}/rol?att=${attId}`,
          {
            headers: {
              Autorization: `Bearer ${token}`,
              Referer: `${process.env.REACT_APP_DOMAIN}`,
            },
          }
        );
        const rol = await resRol.json();
        result.rol = rol.rol;
        setDataUser(result);
      } else {
        window.location = `/${event.event.site}/agenda`;
      }
    });
  };

  return (
    <>
      {width >= breakpointWidth ? (
        <HeaderDesktop data={event} dataUser={dataUser} />
      ) : (
        <HeaderMobile dataUser={dataUser}/>
      )}
    </>
  );
}

export default Header;
