import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext } from "react";
import { dataUserContext } from "../../context/DataUser/DataUser";

function AuditoriumSponsor(props) {
  const {
    users,
    tracks,
    screens,
    fullScreen,
    localScreen,
    trackState,
  } = props;
  const { dataUser } = useContext(dataUserContext);

  return (
    <div>
      <div
        id={fullScreen.active ? "full-screen" : "videos"}
        className={
          screens && users && (screens.length > 0 || localScreen)
            ? `video-grid-auditorio-sreen`
            : tracks && tracks.length > 0
            ? `video-grid-auditorio-${users.length}`
            : `video-grid-auditorio-${users.length - 1}`
        }
      >
        {trackState &&
          trackState.video &&
          tracks &&
          !localScreen &&
          screens.length === 0 && (
            <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
              <AgoraVideoPlayer
                style={{ height: "calc(100% - 40px)", width: "100%" }}
                videoTrack={tracks[1]}
              />
              <div className="name-user">{dataUser.name}</div>
            </div>
          )}
        {users.length === 0 && screens.length === 0 && <div></div>}
        {users.length === 1 && !localScreen && screens.length === 0 && (
          <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
            {!users[0]._video_muted_ && users[0].videoTrack ? (
              <AgoraVideoPlayer
                style={{ height: "calc(100% - 40px)", width: "100%" }}
                videoTrack={users[0].videoTrack}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                style={{ width: "100%", height: "calc(100% - 40px)" }}
              ></img>
            )}
            <div className="name-user">{users[0].userName}</div>
          </div>
        )}

        {screens.length > 0 && (
          <div style={{ width: "100%", height: "100%" }}>
            {screens[0].videoTrack !== undefined && (
              <AgoraVideoPlayer
                style={{ height: "calc(100% - 80px)", width: "100%" }}
                videoTrack={screens[0].videoTrack}
              />
            )}
            <div className="name-user">{screens[0].userName}</div>
          </div>
        )}
        {localScreen && (
          <div style={{ width: "100%", height: "100%" }}>
            <AgoraVideoPlayer
              style={{ height: "calc(100% - 80px)", width: "100%" }}
              videoTrack={localScreen}
            />
            <div className="name-user">{dataUser.name}</div>
          </div>
        )}
        {users.length > 1 &&
          !localScreen &&
          screens.length === 0 &&
          users.map((user) => (
            <div
              style={{
                height: "calc(100% - 80px)",
                width: "100%",
                backgroundColor: "var(--header_bg)",
              }}
              key={user.uid}
            >
              {!user._video_muted_ && user.videoTrack !== undefined ? (
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  style={{ height: "100%", width: "100%" }}
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
                  style={{ width: "100%", height: "calc(100% - 40px)" }}
                ></img>
              )}
              <div className="name-user">{user.userName}</div>
            </div>
          ))}

        {trackState && !trackState.video && (
          <img
            src={`${process.env.REACT_APP_EVENT_IMG}/fondo-sin-cam-1.jpg`}
            style={{ width: "100%", height: "calc(100% - 40px)" }}
          ></img>
        )}
      </div>
    </div>
  );
}

export default AuditoriumSponsor;
