import React, { useContext } from "react";
import AgendaMainUser from "../components/agenda/AgendaMainUser";
import Footer from "../components/Footer/Footer";
import Header from "../components/header/header";
import { EventData } from "../context/EventData/EventData";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function AgendaUser() {
  const { event, userValidation } = useContext(EventData);

  return (
    <div className="App">
      <Header />
      {userValidation && (
        <div className="width-100">
          <div className="popup-register" style={{ marginTop: "0" }}>
            <h3>
              <FormattedMessage id="validation.messagePopup" />
            </h3>
            <div className="flex wid-100">
              <Link
                className="button--early-message"
                to={`/${event.event.site}/agenda`}
              >
                <FormattedMessage id="register.button" />
              </Link>
              <Link className="button--early-message" to={`/${event.event.site}`}>
                <FormattedMessage id="controls.exit" />
              </Link>
            </div>
          </div>
        </div>
      )}
      {!userValidation && <AgendaMainUser />}
      <Footer />
    </div>
  );
}

export default AgendaUser;
