import React, { useContext } from 'react'
import { EventData } from '../../context/EventData/EventData';
import { langContext } from '../../context/lang/langContext';
import BannerNextEvent from '../main/bannerNextEvents/marquee';
import VideoAuditoriumNetworking from './VideoAuditoriumNetworking';

function VideoWrapperAuditoriumNetworking({token, ids}) {
    const { event } = useContext(EventData);
    const { establecerLenguaje } = useContext(langContext);
  
    // useEffect(() => {
    //   if (event)
    //     window.document.title =
    //       event.event.title + " / " + dataSchedule.title;
    // }, [establecerLenguaje, event]);
  
    return (
      <div className="videoWrapper">
        <VideoAuditoriumNetworking token={token} ids={ids}>
          <BannerNextEvent
            marquee={event.event.marquee && event.event.marquee}
          ></BannerNextEvent>
        </VideoAuditoriumNetworking>
        {/* <DescriptionEvent data={dataSchedule} /> */}
      </div>
    );
}

export default VideoWrapperAuditoriumNetworking