import "./Marquee.css";
import React from "react";

function BannerNextEvent({ marquee }) {
  return (
    <div className={`marquee`}>
      <marquee>{marquee}</marquee>
    </div>
  );
}

export default BannerNextEvent;
