import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { actionsContext } from "../../context/Actions/ActionsControl";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import Button from "../button/button";

function ControlsAuditoriumNetworking(props) {
  const { room, attendeeid } = useParams();
  const { dataUser } = useContext(dataUserContext);
  const { event, userSocket, setActionsUsers, rolAuditorium } =
    useContext(EventData);
  const { exitRoomSponsor } = useContext(actionsContext);
  const [handStatus, setHandStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { variant, controls } = props;

  const mute = async (type) => {
    if (type === "audio") {
      await controls.tracks[0].setEnabled(!controls.trackState.audio);
      controls.setTrackState((ps) => {
        return { ...ps, audio: !controls.trackState.audio };
      });
    } else if (type === "video") {
      await controls.tracks[1].setEnabled(!controls.trackState.video);
      controls.setTrackState((ps) => {
        return { ...ps, video: !controls.trackState.video };
      });
      if (!controls.trackState.video) {
        controls.client.publish(controls.tracks[1]);
        controls.client.publish(controls.tracks[0]);
      }
    }
  };

  const deleteConexion = () => {
    var attendee = {
      command: "attendee",
      name: dataUser.name,
      room: room,
      action: "OUT", // Para indicar que ingreso  "OUT" Para indicar que salio
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
  };

  const leaveChannel = async () => {
    await controls.client.leave();
    controls.client.removeAllListeners();
    controls.desktop.leave();
    controls.desktop.removeAllListeners();
    // we close the tracks to perform cleanup
    controls.tracks[0].close();
    controls.tracks[1].close();
    controls.setStart(false);
    controls.setInCall(false);
    deleteConexion();
  };

  const handleClientJoin = (command) => {
    setActionsUsers({
      type: "controls",
      room: room,
      command: "STATUS-STREAMING-ALL",
      product: "EVENTS",
      product_id: event.event.id,
      id: attendeeid,
      to: controls.ids.uid,
      value:
        controls.isStreaming === "DISCONNECTED" ? "CONNECTED" : "DISCONNECTED",
    });

    controls.setIsStreaming(command);

    if (controls.localScreen && controls.isStreaming === "CONNECTED") {
      var attendee = {
        type: "screen",
        name: dataUser.name,
        room: room,
        command: "DESKTOP-UNSHARING",
        id: attendeeid,
        product: "EVENTS",
        product_id: event.event.id,
      };
      setActionsUsers(attendee);
    } else if (
      controls.localScreen &&
      controls.isStreaming === "DISCONNECTED"
    ) {
      var attendee = {
        type: "screen",
        name: dataUser.name,
        room: room,
        command: "DESKTOP-SHARING",
        id: attendeeid,
        product: "EVENTS",
        product_id: event.event.id,
      };
      setActionsUsers(attendee);
    }
  };

  const muteAll = () => {
    var attendee = {
      type: "controls",
      name: dataUser.name,
      room: room,
      command: "MUTE-AUDIO-ALL",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
  };

  const handUp = () => {
    if (handStatus) {
      setActionsUsers({
        command: "HAND-DOWN",
        name: dataUser.name,
        id: attendeeid,
        uid: controls.ids.uid,
        uid_desktop: controls.ids.uid_desktop,
      });
      setHandStatus(!handStatus);
    } else {
      setActionsUsers({
        command: "HAND-UP",
        name: dataUser.name,
        id: attendeeid,
        uid: controls.ids.uid,
        uid_desktop: controls.ids.uid_desktop,
      });
      setHandStatus(!handStatus);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setActionsUsers({
        type: "controls",
        room: room,
        command: "STATUS-STREAMING-CONTROL",
        product: "EVENTS",
        product_id: event.event.id,
        id: attendeeid,
        to: controls.ids.uid,
        value: controls.isStreaming,
      });
    }, 2000);
  }, []);

  useEffect(() => {
    if (exitRoomSponsor) deleteConexion();
  }, [exitRoomSponsor]);

  useEffect(() => {
    if (
      userSocket.type === "controls" &&
      userSocket.command === "MUTE-AUDIO-ALL" &&
      controls.trackState.audio
    ) {
      mute("audio");
    }
    if (userSocket.type === "controls" && userSocket.command === "MUTE-AUDIO") {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      (userSocket.command === "MIC-OFF" || userSocket.command === "MIC-ON") &&
      userSocket.to === controls.ids.uid
    ) {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      (userSocket.command === "CAMERA-OFF" ||
        userSocket.command === "CAMERA-ON") &&
      userSocket.to === controls.ids.uid &&
      controls.trackState.video
    ) {
      mute("video");
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "END" &&
      userSocket.to === controls.ids.uid
    ) {
      leaveChannel();
    }

    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING-CONTROL"
    ) {
      setActionsUsers({
        type: "controls",
        room: room,
        command: "STATUS-STREAMING",
        id: attendeeid,
        to: userSocket.to,
        value: controls.isStreaming,
      });
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING"
    ) {
      controls.setIsStreaming(userSocket.value);
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING-ALL"
    ) {
      controls.setIsStreaming(userSocket.value);
    }
  }, [userSocket]);

  return (
    <div className={`controls${variant}`}>
      {(rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER") && (
        <Button
          variant={controls.trackState.audio ? "controls-menu" : "off"}
          onClick={() => mute("audio")}
          icon={
            controls.trackState.audio
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
        >
          <p className="tip-text">
            {controls.trackState.audio ? (
              <FormattedMessage id="controls.disabledMic" />
            ) : (
              <FormattedMessage id="controls.enabledMic" />
            )}
          </p>
        </Button>
      )}
      {(rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER") && (
        <Button
          variant={controls.trackState.video ? "controls-menu" : "off"}
          onClick={() => mute("video")}
          icon={
            controls.trackState.video
              ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
          }
        >
          <p className="tip-text">
            {controls.trackState.video ? (
              <FormattedMessage id="controls.disabledCam" />
            ) : (
              <FormattedMessage id="controls.enabledCam" />
            )}
          </p>
        </Button>
      )}
      {(rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER") && (
        <Button
          variant={controls.localScreen ? "off-social" : "controls-menu"}
          onClick={
            controls.localScreen
              ? () => controls.unSharedScreen()
              : () => controls.sharedScreen()
          }
          icon={
            !controls.localScreen
              ? `${process.env.REACT_APP_EVENT_IMG}/pantalla.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/pantalla-of.svg`
          }
        >
          <p className="tip-text">
            {controls.localScreen ? (
              <FormattedMessage id="controls.unshareScreen" />
            ) : (
              <FormattedMessage id="controls.shareScreen" />
            )}
          </p>
        </Button>
      )}
      <Button
        variant={!controls.fullScreen.active ? "controls-menu" : "off-social"}
        onClick={
          !controls.fullScreen.active
            ? controls.fullScreen.enter
            : controls.fullScreen.exit
        }
        icon={
          !controls.fullScreen.active
            ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
            : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
        }
      >
        <p className="tip-text">
          {!controls.fullScreen.active ? (
            <FormattedMessage id="controls.fullScreen" />
          ) : (
            <FormattedMessage id="controls.closeFullScreen" />
          )}
        </p>
      </Button>
      <div
        className={"button--controls-menu mobile-none"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen && (
          <p className="tip-text2">
            <Button
              variant={"controls-menu2"}
              onClick={() => controls.setBackgroundImage()}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-image.svg`}
            >
              <p className="tip-text3">Background image</p>
            </Button>
            <Button
              variant={"controls-menu2"}
              onClick={() => controls.setBackgroundBlurring()}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-blur.svg`}
            >
              <p className="tip-text3">Background Blur</p>
            </Button>
            <Button
              variant={"controls-menu2"}
              onClick={() => controls.setBackgroundColor()}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-color.svg`}
            >
              <p className="tip-text3">Background Color</p>
            </Button>
            {/* <Button
                variant={"controls-menu"}
                onClick={() => setBackgroundVideo()}
              >
                video
              </Button> */}
            <Button
              variant={"controls-menu2"}
              onClick={() => controls.backgroundDisable()}
              icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-disabled.svg`}
            >
              <p className="tip-text3">Background Disable</p>
            </Button>
          </p>
        )}
        <p className="tip-text" style={{ fontWeight: "700" }}>
          Background
        </p>
        <img
          src={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-off.svg`}
          width="30"
        ></img>
      </div>
      {rolAuditorium.rol !== "MANAGER" && rolAuditorium.rol !== "SPEAKER" && (
        <Button
          variant={!handStatus ? "controls-menu" : "off-social"}
          onClick={handUp}
          icon={`${process.env.REACT_APP_EVENT_IMG}/manoarriba.svg`}
        >
          <p className="tip-text">
            {!handStatus ? (
              <FormattedMessage id="controls.handUp" />
            ) : (
              <FormattedMessage id="controls.handDown" />
            )}
          </p>
        </Button>
      )}
      {(rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER") && (
        <Button
          variant={
            controls.isStreaming === "CONNECTED" ? "controls-menu" : "off"
          }
          onClick={
            controls.isStreaming === "CONNECTED"
              ? () => handleClientJoin("DISCONNECTED")
              : () => handleClientJoin("CONNECTED")
          }
          icon={
            controls.isStreaming === "DISCONNECTED"
              ? `${process.env.REACT_APP_EVENT_IMG}/broadcast-on.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/broadcast-off.svg`
          }
        >
          <p className="tip-text">
            {controls.isStreaming === "DISCONNECTED" ? (
              <FormattedMessage id="controls.startStreaming" />
            ) : (
              <FormattedMessage id="controls.stopStreaming" />
            )}
          </p>
        </Button>
      )}
      {(rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER") && (
        <Button
          variant="controls-menu"
          onClick={() => muteAll()}
          icon={`${process.env.REACT_APP_EVENT_IMG}/mics-off.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.muteAllMics" />
          </p>
        </Button>
      )}
      <Button
        variant="controls-menu"
        onClick={() => leaveChannel()}
        icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
      >
        <p className="tip-text">
          <FormattedMessage id="controls.exit" />
        </p>
      </Button>
    </div>
  );
}

export default ControlsAuditoriumNetworking;
