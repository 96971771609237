import React, { createContext, useState } from "react";
export const surveyContext = createContext();

function SurveyProvider({ children }) {
  const [surveys, setSurveys] = useState([]);
  const [votos, setVotos] = useState([]);
  const [votado, setVotado] = useState({});

  return (
    <surveyContext.Provider
      value={{ surveys, setSurveys, votos, setVotos, votado, setVotado }}
    >
      {children}
    </surveyContext.Provider>
  );
}

export default SurveyProvider;
