import React, { createContext, useState } from "react";
export const EventData = createContext({});

function EventDataProvider({ children }) {
  const [data, setData] = useState({});
  const [dataEvent, setDataEvent] = useState();
  const [dataSponsor, setDataSponsor] = useState();
  const [rolAuditorium, setRolAuditorium] = useState(false);
  const [controlPopup, setControlPopup] = useState(false);
  const [userSocket, setUserSocket] = useState(false);
  const [event, setEvent] = useState(false);
  const [usersName, setUsersName] = useState([]);
  const [actionsUsers, setActionsUsers] = useState(false);
  const [userValidation, setUserValidation] = useState(false);
  const [selected, setSelected] = useState("principal");
  const [selectedChat, setSelectedchat] = useState({
    isOpen: false,
    selection: "chat",
  });

  return (
    <EventData.Provider
      value={{
        data,
        setData,
        dataEvent,
        setDataEvent,
        rolAuditorium,
        setRolAuditorium,
        dataSponsor,
        setDataSponsor,
        controlPopup,
        setControlPopup,
        userSocket,
        setUserSocket,
        event,
        setEvent,
        usersName,
        setUsersName,
        actionsUsers,
        setActionsUsers,
        userValidation,
        setUserValidation,
        selected,
        setSelected,
        selectedChat,
        setSelectedchat,
      }}
    >
      {children}
    </EventData.Provider>
  );
}

export default EventDataProvider;
