import { createClient } from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FormattedMessage } from "react-intl";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { EventData } from "../../context/EventData/EventData";
import Button from "../button/button";
import AuditoriumSponsor from "./AuditoriumSponsor";
import { consoleLogFunction } from "../../functions/functionLog";
import { useParams } from "react-router-dom";

const config = {
  mode: "live",
  codec: "vp8",
};
const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallNotCameraSponsor({ token, validateRol }) {
  const { room } = useParams();
  const {
    screens,
    setScreens,
    setSpeakers,
    speakers,
    userConnectedImages,
    setUserConnectedImages,
  } = useContext(usersConected);

  const { userSocket, usersName } = useContext(EventData);
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);

  const client = useClient();
  const screenClient = useDesktopClient();
  const handle = useFullScreenHandle();

  const getUserName = async (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return await response;
  };

  const getUserNameDesktop = (uid) => {
    const response = namesUsers.find((user) => user.uid_desktop === uid);
    return response;
  };

  const loginScreenJoin = async () => {
    if (!loginScreen)
      await screenClient
        .join(
          token.app,
          token.channel_desktop,
          token.token_desktop,
          token.uid_desktop
        )
        .then(() => {
          setLoginScreen(true);
          consoleLogFunction("VideoCallNotCameraSponsor:58", "::conectado::");
        })
        .catch(() => {
          setLoginScreen(false);
          consoleLogFunction(
            "VideoCallNotCameraSponsor:62",
            "::desconectado::"
          );
        });
  };

  const loginClientJoin = async () => {
    if (!loginUser) {
      client.setClientRole("audience", function (e) {
        if (!e) {
          consoleLogFunction(
            "VideoCallNotCameraSponsor:70",
            "::setHost success"
          );
        } else {
          consoleLogFunction(
            "VideoCallNotCameraSponsor:72",
            "::setHost error",
            e
          );
        }
      });
      await client
        .join(token.app, token.channel, token.token, token.uid)
        .then(() => {
          setLoginUser(true);
          consoleLogFunction(
            "VideoCallNotCameraSponsor:79",
            "::conectado camara::"
          );
        })
        .catch(() => {
          setLoginUser(false);
          consoleLogFunction(
            "VideoCallNotCameraSponsor:83",
            "::desconectado::"
          );
        });
    }
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    if (loginUser) {
      client.on("user-published", async (user, mediaType) => {
        const userName = await getUserName(user.uid);
        await client.subscribe(user, mediaType);
        user.userName = userName.name;

        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
        if (mediaType === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
        setUserConnectedImages((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
    }
  }, [loginUser]);

  useEffect(() => {

    screenClient.on("user-published", async (userScreen, mediaType) => {
      // const userName = getUserNameDesktop(userScreen.uid);
      await screenClient.subscribe(userScreen, mediaType);
      // userScreen.userName = userName.name;
      if (mediaType === "video" && userScreen.videoTrack !== undefined)
        setScreens((prevUsers) => {
          return [...prevUsers, userScreen];
        });
      if (mediaType === "audio" && userScreen.audioTrack !== undefined) {
        userScreen.audioTrack.play();
      }
    });

    screenClient.on("user-left", (userScreen) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== userScreen.uid);
      });
    });

    screenClient.on("user-unpublished", (userScreen, type) => {
      if (type === "audio" && userScreen.audioTrack !== undefined) {
        userScreen.audioTrack.play();
      }
      if (type === "video" && userScreen.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          userScreen.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== userScreen.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    if (userSocket) {
      consoleLogFunction("VideoCallNotCameraSponsor:191", userSocket);
      if (userSocket.type === "join-client") {
        if (userSocket.command === "CONNECTED") {
          loginClientJoin();
        }
        if (userSocket.command === "DISCONNECTED") {
          setUserConnectedImages([]);
          setSpeakers([]);
          client.leave();
          client.removeAllListeners();
          setLoginUser(false);
        }
      }

      if (userSocket.type === "controls") {
        if (userSocket.command === "STATUS-VIDEO") {
          setUserConnectedImages((prevConnectedImages) => {
            return [...prevConnectedImages, userSocket];
          });
        }
      }

      if (userSocket.type === "screen") {
        if (userSocket.command === "DESKTOP-SHARING") {
          loginScreenJoin();
        } else if (userSocket.command === "DESKTOP-UNSHARING") {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  return (
    <div
      style={
        speakers && speakers.length > 0 ? { width: "100%", height: "70vh" } : {}
      }
    >
      {validateRol &&
        (speakers.length > 0 ||
          screens.length > 0 ||
          userConnectedImages.length > 0) && (
          <FullScreen handle={handle}>
            <AuditoriumSponsor
              userConnectedImages={userConnectedImages}
              users={speakers.length > 0 ? speakers : []}
              localScreen={false}
              tracks={false}
              rol={validateRol.rol}
              screens={screens}
              fullScreen={handle}
            />
            <div className="controls">
              <Button
                variant={!handle.active ? "controls-menu" : "off-social"}
                onClick={handle.active ? handle.exit : handle.enter}
                icon={
                  !handle.active
                    ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
                    : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
                }
              >
                <p className="tip-text">
                  {!handle.active ? (
                    <FormattedMessage id="controls.fullScreen" />
                  ) : (
                    <FormattedMessage id="controls.closeFullScreen" />
                  )}
                </p>
              </Button>
            </div>
          </FullScreen>
        )}
    </div>
  );
}

export default VideoCallNotCameraSponsor;
