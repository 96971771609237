import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import Footer from "../components/Footer/Footer";
import Header from "../components/header/header";
import HomeContent from "../components/HomeContent/HomeContent";
import { langContext } from "../context/lang/langContext";

function Home() {
  const intl = useIntl();
  const { establecerLenguaje } = useContext(langContext);
  useEffect(() => {
    window.document.title = intl.formatMessage({ id: "header.home" });
  }, [establecerLenguaje]);
  return (
    <div>
      <Header />
      <HomeContent />
      <Footer />
    </div>
  );
}

export default Home;
