import React from "react";
import VideoCallRecording from "./MainScreen/VideoCallRecording";

function VideoWrapperRecording({ token, ids }) {
  return (
    <div className="videoWrapper" style={{marginTop: "25px"}}>
      <section className="videoMain">
        <div className={"display-video"}>
          <VideoCallRecording
            channelName={"principal"}
            token={token}
            ids={ids}
          />
        </div>
      </section>
    </div>
  );
}

export default VideoWrapperRecording;
