import React, { useContext, useEffect, useState } from "react";
import Button from "../button/button";
import { useParams } from "react-router-dom";
import { EventData } from "../../context/EventData/EventData";
import { FormattedMessage } from "react-intl";
import { consoleLogFunction } from "../../functions/functionLog";
import { actionsContext } from "../../context/Actions/ActionsControl";
import useRequests from "../../Hooks/UseRequests";

function ControlsNetworking(props) {
  const { exitRoomNetworking } = useContext(actionsContext);
  const { putWithToken } = useRequests();
  const dataStorageSponsor = localStorage.getItem("dataSponsor");
  const dataStorageGetSponsor = JSON.parse(dataStorageSponsor);
  const { room, active, room_networking_type, attendeeid } = useParams();
  const { rolAuditorium, setControlPopup, event, setActionsUsers } =
    useContext(EventData);

  const [roomActive, setRoomActive] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { controls } = props;
  const { userSocket } = useContext(EventData);

  const mute = async (type) => {
    if (type === "audio" && controls.users.length < 16) {
      await controls.tracks[0].setEnabled(!controls.trackState.audio);
      controls.setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video" && controls.users.length < 16) {
      await controls.tracks[1].setEnabled(!controls.trackState.video);
      controls.setTrackState((ps) => {
        return { ...ps, video: !controls.trackState.video };
      });
    }
  };

  const handleSocket = (command) => {
    setActionsUsers(command);
  };

  const closeRoom = () => {
    setControlPopup(true);
    setTimeout(() => window.history.back(), 5000);
  };

  const enabledRoom = async (isActive) => {
    await putWithToken(
      `${process.env.REACT_APP_URI_API}/${event.event.id}/sponsors/${dataStorageGetSponsor.id}/rooms/${room}/status`,
      { active: isActive }
    ).catch(() => consoleLogFunction("ControlsNetworking:91", "se actualizo"));

    setRoomActive(isActive);
    if (!isActive)
      handleSocket({
        type: "cmd",
        room: room,
        cmd: "ROOM_TERMINATE",
        from: controls.userId,
        product_id: event.event.id,
        product: "EVENTS",
        id: attendeeid,
      });
  };

  const leaveChannel = async () => {
    await controls.client.leave();
    controls.client.removeAllListeners();
    controls.desktop.leave();
    controls.desktop.removeAllListeners();
    window.history.back();
  };

  useEffect(() => {
    setRoomActive(active === "true" ? true : false);
  }, [active]);

  useEffect(() => {
    if (exitRoomNetworking) {
      if (rolAuditorium.rol !== "ATTENDEE") enabledRoom(false);
      window.history.back();
    }
  }, [exitRoomNetworking]);

  useEffect(() => {
    switch (userSocket.cmd) {
      case "CAMERA-OFF":
        if (userSocket.to === controls.userId && controls.trackState.video) {
          mute("video");
          mute("audio");
        }
        break;
      case "MIC-OFF":
        if (userSocket.to === controls.userId) {
          mute("audio");
        }
        break;
      case "END":
        if (userSocket.to === controls.userId) window.history.go(-1);
        break;
      case "SCREEN-OFF":
        controls.unSharedScreen();
        break;
      case "ROOM_TERMINATE":
        closeRoom();
        break;
      default:
        break;
    }
  }, [userSocket]);

  return (
    <div>
      <div className="controls-networking">
        <Button
          variant={
            controls.trackState && controls.trackState.audio
              ? "controls-menu"
              : "off"
          }
          onClick={() => mute("audio")}
          icon={
            controls.trackState && controls.trackState.audio
              ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
          }
        >
          <p className="tip-text">
            {controls.trackState && controls.trackState.audio ? (
              <FormattedMessage id="controls.disabledMic" />
            ) : (
              <FormattedMessage id="controls.enabledMic" />
            )}
          </p>
        </Button>
        <Button
          variant={
            controls.trackState && controls.trackState.video
              ? "controls-menu"
              : "off"
          }
          onClick={() => mute("video")}
          icon={
            controls.trackState && controls.trackState.video
              ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
          }
        >
          <p className="tip-text">
            {controls.trackState && controls.trackState.video ? (
              <FormattedMessage id="controls.disabledCam" />
            ) : (
              <FormattedMessage id="controls.enabledCam" />
            )}
          </p>
        </Button>
        {controls.screens.length < 2 &&
          rolAuditorium &&
          rolAuditorium.rol !== "ATTENDEE" &&
          room_networking_type === "networking" && (
            <Button
              variant={controls.localScreen ? "off-social" : "controls-menu"}
              onClick={
                controls.localScreen
                  ? () => controls.unSharedScreen()
                  : () => controls.sharedScreen()
              }
              icon={
                !controls.localScreen
                  ? `${process.env.REACT_APP_EVENT_IMG}/pantalla.svg`
                  : `${process.env.REACT_APP_EVENT_IMG}/pantalla-of.svg`
              }
            >
              <p className="tip-text">
                {controls.localScreen ? (
                  <FormattedMessage id="controls.unshareScreen" />
                ) : (
                  <FormattedMessage id="controls.shareScreen" />
                )}
              </p>
            </Button>
          )}
        {controls.screens.length < 2 &&
          room_networking_type === "networking-private" && (
            <Button
              variant={controls.localScreen ? "off-social" : "controls-menu"}
              onClick={
                controls.localScreen
                  ? () => controls.unSharedScreen()
                  : () => controls.sharedScreen()
              }
              icon={
                !controls.localScreen
                  ? `${process.env.REACT_APP_EVENT_IMG}/pantalla.svg`
                  : `${process.env.REACT_APP_EVENT_IMG}/pantalla-of.svg`
              }
            >
              <p className="tip-text">
                {controls.localScreen ? (
                  <FormattedMessage id="controls.unshareScreen" />
                ) : (
                  <FormattedMessage id="controls.shareScreen" />
                )}
              </p>
            </Button>
          )}
        <Button
          variant={
            controls.fullScreen && !controls.fullScreen.active
              ? "controls-menu"
              : "off-social"
          }
          onClick={
            controls.fullScreen && !controls.fullScreen.active
              ? controls.fullScreen.enter
              : controls.fullScreen.exit
          }
          icon={
            controls.fullScreen && !controls.fullScreen.active
              ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
              : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
          }
        >
          <p className="tip-text">
            {controls.fullScreen && !controls.fullScreen.active ? (
              <FormattedMessage id="controls.fullScreen" />
            ) : (
              <FormattedMessage id="controls.closeFullScreen" />
            )}
          </p>
        </Button>
        <div
          className={"button--controls-menu mobile-none"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen && (
            <p className="tip-text2">
              <Button
                variant={"controls-menu2"}
                onClick={() => controls.setBackgroundImage()}
                icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-image.svg`}
              >
                <p className="tip-text3">Background image</p>
              </Button>
              <Button
                variant={"controls-menu2"}
                onClick={() => controls.setBackgroundBlurring()}
                icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-blur.svg`}
              >
                <p className="tip-text3">Background Blur</p>
              </Button>
              <Button
                variant={"controls-menu2"}
                onClick={() => controls.setBackgroundColor()}
                icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-color.svg`}
              >
                <p className="tip-text3">Background Color</p>
              </Button>
              {/* <Button
                variant={"controls-menu"}
                onClick={() => setBackgroundVideo()}
              >
                video
              </Button> */}
              <Button
                variant={"controls-menu2"}
                onClick={() => controls.backgroundDisable()}
                icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-disabled.svg`}
              >
                <p className="tip-text3">Background Disable</p>
              </Button>
            </p>
          )}
          <p className="tip-text" style={{ fontWeight: "700" }}>
            Background
          </p>
          <img
            src={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-off.svg`}
            width="30"
          ></img>
        </div>
        {rolAuditorium && rolAuditorium.rol !== "ATTENDEE" && (
          <Button
            variant={!roomActive ? "off" : "off-social"}
            onClick={
              roomActive ? () => enabledRoom(false) : () => enabledRoom(true)
            }
            icon={
              !roomActive
                ? `${process.env.REACT_APP_EVENT_IMG}/network-on.svg`
                : `${process.env.REACT_APP_EVENT_IMG}/network-off.svg`
            }
          >
            <p className="tip-text">
              <FormattedMessage
                id={
                  roomActive
                    ? "controls.desactivar-networking"
                    : "controls.activar-networking"
                }
              />
            </p>
          </Button>
        )}
        <Button
          variant="controls-menu"
          onClick={() => leaveChannel()}
          icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
        >
          <p className="tip-text">
            <FormattedMessage id="controls.exit" />
          </p>
        </Button>
      </div>
    </div>
  );
}

export default ControlsNetworking;
