import React from "react";
import { useState, useEffect, useContext } from "react";
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AgoraRTC from "agora-rtc-sdk-ng";

import { EventData } from "../../context/EventData/EventData";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { consoleLogFunction } from "../../functions/functionLog";
import RoomAuditoriumNetwork from "./RoomAuditoriumNetwork";
import { useParams } from "react-router-dom";
import useBackgroundVideo from "../../Hooks/useBackgroundVideo";
import useRegisterActivity from "../../Hooks/useRegisterActivity";

const config = {
  mode: "live",
  codec: "vp8",
};
let configCamera = {
  encoderConfig: "720p_1",
};

let devices = null;
let useMicrophoneAndCameraTracks = null;
async function getDevices() {
  devices = await navigator.mediaDevices.enumerateDevices();
  useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks(configCamera);
}
getDevices();

const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallNetworkings(props) {
  const { channelName, token, ids } = props;
  let microphoneAndCamera = useMicrophoneAndCameraTracks();
  let tracks = microphoneAndCamera.tracks;
  const { dataUser } = useContext(dataUserContext);
  const { userSocket, event, usersName, setActionsUsers } =
    useContext(EventData);

  const [start, setStart] = useState(false);
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [isStreaming, setIsStreaming] = useState("DISCONNECTED");

  const { room, attendeeid } = useParams();
  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
  } = useContext(usersConected);

  const client = useClient();
  const { registerActivity } = useRegisterActivity();
  let screenClient = useDesktopClient();
  const {
    setBackgroundColor,
    setBackgroundImage,
    setBackgroundBlurring,
    backgroundDisable,
  } = useBackgroundVideo(tracks);
  const handle = useFullScreenHandle();
  let screen = null;

  const sharedScreen = async () => {
    if (!loginScreen) {
      screenClient.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCall:84", "::setHost success");
        } else {
          consoleLogFunction("VideoCall:86", "::setHost error" + e);
        }
      });
      screenClientJoin();
    }

    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: token.resolution_desktop,
      },
      "auto"
    );
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-SHARING",
      id: attendeeid,
    };
    setActionsUsers(attendee);
    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    screenClient.publish(screen);

    if (screen instanceof Array)
      screen[0].on("track-ended", () => {
        unSharedScreen();
      });
    else
      screen.on("track-ended", () => {
        unSharedScreen();
      });
  };

  const unSharedScreen = async () => {
    screenClient.leave();
    screenClient.removeAllListeners();
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-UNSHARING",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    registerActivity("NETWORKING", "SCREEN_OFF");
    setActionsUsers(attendee);
    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }
    setLocalScreen(false);
    setLoginScreen(false);
  };

  const getUserName = (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return response;
  };
  const getUserDesktop = (uid) => {
    const response = namesUsers.find((user) => user.uid_desktop === uid);
    return response;
  };

  const screenClientJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCall:165", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCall:169", "::desconectado::");
      });
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        setTimeout(async () => {
          const userName = getUserName(user.uid);

          await client.subscribe(user, mediaType);

          user.userName = userName.name;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 2000);
      });

      client.on("user-unpublished", (user, type) => {
        const userName = getUserName(user.uid);
        user.userName = userName.name;
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (token && !loginUser) {
        client.setClientRole("host", function (e) {
          if (!e) {
            consoleLogFunction("VideoCall:223", "::setHost success");
          } else {
            consoleLogFunction("VideoCall:225", "::setHost error", e);
          }
        });
        await client
          .join(token.app, token.channel, token.token, token.uid)
          .then(() => {
            setLoginUser(true);
            consoleLogFunction("VideoCall:232", "::conectado camara::");
          })
          .catch(() => {
            setLoginUser(false);
            consoleLogFunction("VideoCall:236", "::desconectado::");
          });
      }
    };

    if (!loginUser) init(channelName);
  }, [loginUser]);

  useEffect(() => {
    if (tracks && loginUser) {
      if (tracks.length <= 2 && tracks.length > 1) {
        tracks[1].setEncoderConfiguration(token.resolution);
        tracks[1].userName = dataUser.name;
        setTracks(tracks);
        client.publish([tracks[0], tracks[1]]);
      } else if (tracks.length <= 1) {
        setTracks(tracks);
        client.publish([tracks[0]]);
      } else {
        setTracks([]);
      }
    } else if (!tracks || tracks === null) {
      setTracks([]);
    }
  }, [loginUser, tracks]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      //const userName = getUserDesktop(user.uid);
      await screenClient.subscribe(user, mediaType);

      //user.userName = userName.name;

      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    consoleLogFunction("VideoCall:298", userSocket);
    if (userSocket) {
      if (userSocket.command === "ATTENDEE" && localScreen) {
        var attendee = {
          type: "screen",
          name: dataUser.name,
          room: room,
          command: "DESKTOP-SHARING",
          id: attendeeid,
          product: "EVENTS",
          product_id: event.event.id,
        };
        setActionsUsers(attendee);
      }
      if (userSocket.type === "screen") {
        if (
          userSocket.command === "DESKTOP-SHARING" &&
          userSocket.name !== dataUser.name
        ) {
          screenClientJoin();
        } else if (
          userSocket.command === "DESKTOP-UNSHARING" &&
          userSocket.name !== dataUser.name
        ) {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  return (
    <div className="vid">
      <FullScreen handle={handle}>
        <div>
          <RoomAuditoriumNetwork
            users={speakers.length > 0 ? speakers : []}
            localScreen={localScreen}
            tracks={tracks ? tracks : []}
            type={"NETWORKING"}
            screens={screens}
            fullScreen={handle}
            trackState={trackState}
            ids={ids}
            controls={{
              tracks: tracks,
              setStart: setStart,
              setBackgroundColor,
              setBackgroundImage,
              setBackgroundBlurring,
              sharedScreen: sharedScreen,
              backgroundDisable: backgroundDisable,
              unSharedScreen: unSharedScreen,
              users: speakers,
              localScreen: localScreen,
              client: client,
              desktop: screenClient,
              fullScreen: handle,
              trackState: trackState,
              setTrackState: setTrackState,
              isStreaming: isStreaming,
              setIsStreaming: setIsStreaming,
              ids: ids,
            }}
          />
        </div>
      </FullScreen>
    </div>
  );
}

export default VideoCallNetworkings;
