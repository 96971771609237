import "./HomeContent.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import { FormattedMessage } from "react-intl";
import useRequests from "../../Hooks/UseRequests";

function HomeContent() {
  const { getWithToken } = useRequests();
  const [eventsData, setEventsData] = useState();

  const getDataEvent = async () => {
    const req = await getWithToken(`${process.env.REACT_APP_URI_API}/sites`);
    setEventsData(req);
  };

  useEffect(() => {
    if (!eventsData) {
      getDataEvent();
    }
  }, [eventsData]);

  return (
    <div>
      <h1 style={{ color: "white", marginLeft: "50px" }}>
        <FormattedMessage id="schedule.welcome" /> Virtuales
      </h1>
      <main className="container main-bg2 grid2">
        {!eventsData ? (
          <Loading></Loading>
        ) : (
          eventsData.map((event, index) => (
            <div className="card-home" key={index}>
              <Link to={`/${event.site}`}>
                <div className="logo-agenda2">
                  <img src={event.logo} className="logo-content" />
                </div>
                <div>
                  <h1 className="event-title">
                    {event.name} <br />
                    <span className="weight-normal">{event.title}</span>
                  </h1>
                </div>
              </Link>
            </div>
          ))
        )}
      </main>
    </div>
  );
}

export function isKeyExists(obj, key) {
  if (obj[key] == undefined) {
    return false;
  } else {
    return true;
  }
}
export default HomeContent;
