import React from "react";
import VideoCall from "./VideoCall";

function VideoInfo({ token, ids, setSelected, typeRoom }) {
  return (
    <section className="videoMain">
      <div className={"display-video"}>
        <VideoCall
          setSelected={setSelected}
          channelName={"principal"}
          token={token}
          ids={ids}
          typeRoom={typeRoom}
        />
      </div>
    </section>
  );
}

export default VideoInfo;
