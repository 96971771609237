import React from "react";
import { useContext } from "react";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import { AgoraVideoPlayer } from "agora-rtc-react";

function RoomAuditoriumRecording(props) {
  const { users, screens, controls } = props;
  const { dataUser } = useContext(dataUserContext);
 
  try {
    return (
      <div className="screen">
        {screens && users && (
          <div
            id={"videos"}
            className={
              screens && users && screens.length > 0
                ? `video-grid-auditorio-sreen`
                : `video-grid-auditorio-${users.length - 1}`
            }
          >
            {screens && screens.length > 0 && (
              <div style={{ width: "100%", height: "100%" }}>
                {screens[0].videoTrack !== undefined ? (
                  <AgoraVideoPlayer
                    style={{ height: "calc(100% - 40px)", width: "100%" }}
                    videoTrack={screens[0].videoTrack}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                    alt="main screen"
                    className="screenVideo"
                  />
                )}
              </div>
            )}

            {controls.tracks.length > 0 &&
              !controls.localScreen &&
              screens.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  {controls.trackState.video ? (
                    <AgoraVideoPlayer
                      style={{ height: "calc(100% - 40px)", width: "100%" }}
                      videoTrack={controls.tracks[1]}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                      alt="main screen"
                      className="screenVideo"
                      style={{ height: "calc(100% - 40px)", width: "100%" }}
                    />
                  )}

                  <div className="name-user">{dataUser.name}</div>
                </div>
              )}

            {users.length > 0 &&
              screens.length === 0 &&
              users.map((user) => (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                  key={user.uid}
                >
                  {!user._video_muted_ && user.videoTrack !== undefined ? (
                    <AgoraVideoPlayer
                      style={{ height: "calc(100% - 40px)", width: "100%" }}
                      videoTrack={user.videoTrack}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                      alt="main screen"
                      className="screenVideo"
                    />
                  )}
                  <div className="name-user">{user.userName}</div>
                </div>
              ))}

            {users.length === 0 && screens.length === 0 && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_EVENT_IMG}/fondo.jpeg`}
                  alt="main screen"
                  className="screenVideo"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  } catch (error) {
    console.error(error);
  }
}

export default RoomAuditoriumRecording;
