import React, { useContext, useEffect } from "react";
import "./style.css";
import { useState } from "react";
import { EventData } from "../../context/EventData/EventData";
import { useParams } from "react-router-dom";
import CreateSurvey from "./CreateSurvey";
import { surveyContext } from "../../context/SurveyContext/SurveyContext";
import useRequests from "../../Hooks/UseRequests";
import { FormattedMessage } from "react-intl";

function Survey({ rol, sendMsg }) {
  const { userSocket, setUserSocket, setActionsUsers, event } =
    useContext(EventData);
  const { surveys, setSurveys, votado, setVotado } = useContext(surveyContext);
  const { id, attendeeid } = useParams();
  const { postWithToken, getWithToken } = useRequests();
  const [openCreateSurvey, setOpenCreateSurvey] = useState(false);

  const votar = (surveyIndex, optionIndex, itemSurvey, option) => {
    setVotado({ ...votado, [surveys[surveyIndex].id]: true });

    postWithToken(
      `${process.env.REACT_APP_URI_API}/${id}/pools/${itemSurvey.id}/response/${attendeeid}`,
      { response: [option] }
    );

    const nuevosVotos = surveys.map((survey) => {
      if (survey.id === itemSurvey.id) {
        const nuevoVoto = survey["votes"];
        nuevoVoto[optionIndex]++;
        delete survey["votes"];
        survey.votes = nuevoVoto;
        return survey;
      } else {
        return survey;
      }
    });
    setSurveys(nuevosVotos);

    setActionsUsers({
      type: "VOTE",
      command: "SURVEY",
      id: attendeeid,
      optionIndex,
      idSurvey: itemSurvey.id,
    });
  };

  async function getResponses() {
  
    const copySurveys = [...surveys];
    const surveysTemp = copySurveys.filter(
      (survey) =>
        event.event.pools.findIndex(
          (prevSurvey) => prevSurvey.id === survey.id
        ) === -1
    );
  
    const votesRegistered = [...surveysTemp];
  
    const promises = event.event.pools.map((survey) =>
      getWithToken(
        `${process.env.REACT_APP_URI_API}/${id}/pools/${survey.id}?att=${attendeeid}`
      )
        .then((result) => {
          votesRegistered.push({
            ...survey,
            votes: survey.responses.map((res) => (result[res] ? result[res] : 0)),
          });
        })
        .catch(() => {
          votesRegistered.push({
            ...survey,
            votes: survey.responses.map(() => 0),
          });
        })
    );
  
    await Promise.all(promises);
    setSurveys(votesRegistered);
  }
  
  function SurveysAndResponsesManager() {
    return (
      <div>
        {(event.event.pools.length > 0 || surveys.length > 0 )? (
          surveys.map((itemSurvey) => {
            let totalVotosSurvey = 0;
            const search = surveys.find(
              (survey) => survey.id === itemSurvey.id
            );

            if (search) {
              totalVotosSurvey = search.votes.reduce(
                (acc, cont) => acc + cont,
                0
              );
            }

            return (
              <div key={itemSurvey.id}>
                <h4>{itemSurvey.question}</h4>
                <ul>
                  {itemSurvey.responses.map((option, optionIndex) => {
                    const porcentajeVoto =
                      totalVotosSurvey > 0
                        ? (itemSurvey.votes[optionIndex] / totalVotosSurvey) *
                          100
                        : 0;
                    return (
                      <li key={optionIndex} className="respuestas">
                        <div className="resultado">
                          <div
                            className="barra"
                            style={{
                              width:
                                porcentajeVoto > 0
                                  ? `${porcentajeVoto}%`
                                  : "100%",
                              backgroundColor:
                                porcentajeVoto > 0
                                  ? "var(--social_bg)"
                                  : "transparent",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <span>{option}:</span>
                              <span>{Math.round(porcentajeVoto)}%</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        ) : (
          <h2>
            <FormattedMessage id="Loading" />
          </h2>
        )}
      </div>
    );
  }

  function SurveysAndResponsesAttendee() {
    return (
      <div>
        {surveys.map((itemSurvey, surveyIndex) => {
          let totalVotosSurvey = 0;
          const search = surveys.find((survey) => survey.id === itemSurvey.id);

          if (search) {
            totalVotosSurvey = search.votes.reduce(
              (acc, cont) => acc + cont,
              0
            );
          }

          return (
            <div key={itemSurvey.id}>
              <h4>{itemSurvey.question}</h4>
              <ul>
                {itemSurvey.responses.map((option, optionIndex) => {
                  const porcentajeVoto =
                    totalVotosSurvey > 0
                      ? (itemSurvey.votes[optionIndex] / totalVotosSurvey) * 100
                      : 0;
                  return (
                    <li key={optionIndex} className="respuestas">
                      {!votado[itemSurvey.id] ? (
                        <button
                          onClick={() =>
                            votar(surveyIndex, optionIndex, itemSurvey, option)
                          }
                          className="btn-options"
                        >
                          {option}
                        </button>
                      ) : (
                        <div className="resultado">
                          <div
                            className="barra"
                            style={{
                              width:
                                porcentajeVoto > 0
                                  ? `${porcentajeVoto}%`
                                  : "100%",
                              backgroundColor:
                                porcentajeVoto > 0
                                  ? "var(--social_bg)"
                                  : "transparent",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <span>{option}:</span>
                              <span>{Math.round(porcentajeVoto)}%</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    if (rol === "MANAGER" && surveys.length === 0) {
      getResponses()
    }
  }, []);

  useEffect(() => {
    if (userSocket) {
      if (userSocket.command === "SURVEY") {
        if (userSocket.type === "CREATE") {
          setSurveys([userSocket.data]);
        }
        if (userSocket.type === "VOTE") {
          const nuevosVotos = surveys.map((survey) => {
            if (survey.id === userSocket.idSurvey) {
              const nuevoVoto = survey["votes"];
              nuevoVoto[userSocket.optionIndex]++;
              delete survey["votes"];
              survey.votes = nuevoVoto;
              return survey;
            } else {
              return survey;
            }
          });
          setSurveys(nuevosVotos);
        }
      }
    }
    return () => {
      setUserSocket(false);
    };
  }, [userSocket]);

  try {
    return (
      <div className="content-surveys">
        {rol === "MANAGER" ? (
          <div>
            <button
              onClick={() => setOpenCreateSurvey(!openCreateSurvey)}
              className="btn-create"
            >
              {!openCreateSurvey ? (
                <FormattedMessage id="survey.create" />
              ) : (
                <FormattedMessage id="survey.cancel" />
              )}
            </button>

            {openCreateSurvey ? (
              <CreateSurvey
                setSurveys={setSurveys}
                sendMsg={sendMsg}
                setOpenCreateSurvey={setOpenCreateSurvey}
              />
            ) : (
              <div>
                {event.event.pools.length > 0 || surveys.length > 0 ? (
                  SurveysAndResponsesManager()
                ) : (
                  <h2>
                    <FormattedMessage
                      id="survey.nosurvey"
                      defaultMessage="Nombre"
                    />
                  </h2>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>{surveys.length > 0 && SurveysAndResponsesAttendee()}</div>
        )}
      </div>
    );
  } catch (error) {
    console.error(error);
  }
}

export default Survey;
