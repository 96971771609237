import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import Button from "../button/button";
import { isKeyExists } from "../HomeContent/HomeContent";
// import { consoleLogFunction } from "../../functions/functionLog";
import Loading from "../Loading/Loading";
import useRequests from "../../Hooks/UseRequests";

function StatisticsCircle({ EventId }) {
  const { attendeeid } = useParams();
  const { getWithToken } = useRequests();
  const [data, setData] = useState();
  const [dataSponsorxTeam, setDataSponsorxTeam] = useState();
  const [stateViewSponsor, setStateViewSponsor] = useState(false);
  const [stateViewRooms, setStateViewRooms] = useState(false);

  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleTimeString("en-US")
  );

  async function getDataSponsor() {
    const response = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${EventId}/statistics/attendees/sponsors?att=${attendeeid}&page=HOME_STATISTICS`,
    );
    const newData = response.map((stcs) => {
      if (stcs.asistentes > 0) setStateViewSponsor(true);
      return {
        ...stcs,
        value: stcs.asistentes,
        label: stcs.title,
        id: stcs.title,
      };
    });
    setDataSponsorxTeam(newData);
  }

  const getUsersConnected = async () => {
  
    const result = await getWithToken(
      `${process.env.REACT_APP_URI_API}/${EventId}/statistics/attendees/rooms?att=${attendeeid}&page=HOME_STATISTICS`,
    );
    
    const newData = result.map((stcs) => {
      if (stcs.asistentes > 0) setStateViewRooms(true);
      return {
        ...stcs,
        value: stcs.asistentes,
        label: stcs.title,
        id: stcs.title,
      };
    });
    setData(newData);
  };

  const updateStatistics = () => {
    setCurrentDate(new Date().toLocaleTimeString("en-US"));
    getUsersConnected();
    getDataSponsor();
    setStateViewRooms(false);
    setStateViewSponsor(false);
  };

  useEffect(() => {
    getUsersConnected();
    getDataSponsor();
    return () => {};
  }, []);

  return data && dataSponsorxTeam ? (
    <div className="statistics">
      <div className="statistics-item-head">
        <p className="text-date">
          <FormattedMessage id="boton.messageUpdate" /> {currentDate}
        </p>
        <Button
          variant="early"
          icon={`${process.env.REACT_APP_EVENT_IMG}/live.svg`}
          onClick={() => updateStatistics()}
        >
          <FormattedMessage id="boton.update" />
        </Button>
      </div>
      <div className="stadistics-item-body">
        <div
          className={stateViewRooms ? "statistics-item" : "statistics-item-box"}
        >
          <h3 className="statistics-items-title">
            <FormattedMessage id="statistics.titleNumberAsistans" />
          </h3>
          {stateViewRooms ? (
            <ResponsivePie
              data={data}
              margin={{ top: 30, right: 120, bottom: 120, left: 120 }}
              innerRadius={0.35}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={5}
              arcLinkLabelsTextOffset={0}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
            />
          ) : (
            <h3 className="message-statistics">
              <FormattedMessage id="statistics.messageViewRooms" />
            </h3>
          )}
        </div>
        <div
          className={
            stateViewSponsor ? "statistics-item" : "statistics-item-box"
          }
        >
          <h3 className="statistics-items-title">
            <FormattedMessage id="statistics.titleNumberAsistansSponsors" />
          </h3>
          {stateViewSponsor ? (
            <ResponsivePie
              data={dataSponsorxTeam}
              margin={{ top: 30, right: 120, bottom: 120, left: 120 }}
              innerRadius={0.35}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={5}
              arcLinkLabelsTextOffset={0}
              arcLinkLabelsTextColor="#fff"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
            />
          ) : (
            <h3 className="message-statistics">
              <FormattedMessage id="statistics.messageViewSponsors" />
            </h3>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default StatisticsCircle;
