import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import Button from "../../button/button";
import React, { useContext, useEffect, useState } from "react";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import { EventData } from "../../../context/EventData/EventData";
import { actionsContext } from "../../../context/Actions/ActionsControl";
import UseRecording from "../../../Hooks/UseRecording";
import useRegisterActivity from "../../../Hooks/useRegisterActivity";

function Controls(props) {
  const { variant, controls } = props;
  const { room, attendeeid, room_type } = useParams();
  const { registerActivity } = useRegisterActivity();
  const { dataUser } = useContext(dataUserContext);
  const { event, userSocket, setActionsUsers, rolAuditorium } =
    useContext(EventData);

  const { cloudRecording, isCloudRecording } = UseRecording({
    room,
    streaming: { uid: controls.ids.uid, token: controls.token.token },
  });
  const { exitRoomSponsor } = useContext(actionsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenControls, setIsOpenControls] = useState(false);

  const handDown = () => {
    setActionsUsers({
      command: "HAND-DOWN-GUEST",
      name: dataUser.name,
      id: controls.ids.uid,
    });

    setTimeout(() => {
      setActionsUsers({
        command: "HAND-DOWN",
        name: dataUser.name,
        id: attendeeid,
        uid: controls.ids.uid,
        uid_desktop: controls.ids.uid_desktop,
      });
    }, 2000);
  };

  const mute = async (type) => {
    if (type === "audio") {
      await controls.tracks[0].setEnabled(!controls.trackState.audio);
      controls.setTrackState((ps) => {
        return { ...ps, audio: !controls.trackState.audio };
      });
    } else if (type === "video") {
      await controls.tracks[1].setEnabled(!controls.trackState.video);
      controls.setTrackState((ps) => {
        return { ...ps, video: !controls.trackState.video };
      });
    }
  };

  const deleteConexion = () => {
    var attendee = {
      command: "attendee",
      name: dataUser.name,
      room: room,
      action: "OUT", // Para indicar que ingreso  "OUT" Para indicar que salio
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
  };

  const leaveChannel = async () => {
    await controls.client.leave();
    controls.client.removeAllListeners();
    controls.desktop.leave();
    controls.desktop.removeAllListeners();
    // we close the tracks to perform cleanup
    controls.tracks[0].close();
    controls.tracks[1].close();
    deleteConexion();
    controls.setSelected("networking");
  };

  const handleClientJoin = (command) => {
    setTimeout(() => {
      var attendee = {
        type: "join-client",
        name: dataUser.name,
        room: room,
        command: command,
      };
      setTimeout(() => {
        setActionsUsers({
          type: "controls",
          room: room,
          command: "STATUS-STREAMING-ALL",
          product: "EVENTS",
          product_id: event.event.id,
          id: attendeeid,
          to: controls.ids.uid,
          value:
            controls.isStreaming === "DISCONNECTED"
              ? "CONNECTED"
              : "DISCONNECTED",
        });
      }, 1000);
      setActionsUsers(attendee);
    }, 1000);

    controls.setIsStreaming(command);

    if (controls.localScreen && controls.isStreaming === "CONNECTED") {
      var attendee = {
        type: "screen",
        name: dataUser.name,
        room: room,
        command: "DESKTOP-UNSHARING",
        id: attendeeid,
        product: "EVENTS",
        product_id: event.event.id,
      };
      setActionsUsers(attendee);
    } else if (
      controls.localScreen &&
      controls.isStreaming === "DISCONNECTED"
    ) {
      var attendee = {
        type: "screen",
        name: dataUser.name,
        room: room,
        command: "DESKTOP-SHARING",
        id: attendeeid,
        product: "EVENTS",
        product_id: event.event.id,
      };
      setActionsUsers(attendee);
    }
  };

  const muteAll = () => {
    var attendee = {
      type: "controls",
      name: dataUser.name,
      room: room,
      command: "MUTE-AUDIO-ALL",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);
  };

  useEffect(() => {
    if (controls.ids)
      setTimeout(() => {
        setActionsUsers({
          type: "controls",
          room: room,
          command: "STATUS-STREAMING-CONTROL",
          id: attendeeid,
          to: controls.ids.uid,
          value: controls.isStreaming,
        });
      }, 2000);
  }, []);

  useEffect(() => {
    if (exitRoomSponsor) deleteConexion();
  }, [exitRoomSponsor]);

  useEffect(() => {
    if (
      userSocket.type === "controls" &&
      userSocket.command === "MUTE-AUDIO-ALL" &&
      controls.trackState.audio
    ) {
      mute("audio");
    }
    if (userSocket.type === "controls" && userSocket.command === "MUTE-AUDIO") {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      (userSocket.command === "MIC-OFF" || userSocket.command === "MIC-ON") &&
      userSocket.to === controls.ids.uid
    ) {
      mute("audio");
    }
    if (
      userSocket.type === "controls" &&
      (userSocket.command === "CAMERA-OFF" ||
        userSocket.command === "CAMERA-ON") &&
      userSocket.to === controls.ids.uid &&
      controls.trackState.video
    ) {
      mute("video");
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "END" &&
      userSocket.to === controls.ids.uid
    ) {
      leaveChannel();
    }

    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING-CONTROL"
    ) {
      setActionsUsers({
        type: "controls",
        room: room,
        command: "STATUS-STREAMING",
        id: attendeeid,
        to: userSocket.to,
        value: controls.isStreaming,
      });
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING"
    ) {
      controls.setIsStreaming(userSocket.value);
    }
    if (
      userSocket.type === "controls" &&
      userSocket.command === "STATUS-STREAMING-ALL"
    ) {
      controls.setIsStreaming(userSocket.value);
    }
  }, [userSocket]);

  try {
    return (
      <>
        {!isOpenControls && <div className={`controls${variant}`}>
          <Button
            variant={"controls-menu"}
            onClick={() => setIsOpenControls(true)}
            
          >
            <p className="tip-text">
              Expandir controles
            </p>
          </Button>
        </div>}

        {isOpenControls && (
          <div className={`controls${variant}`}>
            <Button
              variant={controls?.trackState?.audio ? "controls-menu" : "off"}
              onClick={() => {
                mute("audio");
                registerActivity(
                  "PRINCIPAL",
                  controls?.trackState?.audio ? "MIC_OFF" : "MIC_ON"
                );
              }}
              icon={
                controls?.trackState?.audio
                  ? `${process.env.REACT_APP_EVENT_IMG}/mic.svg`
                  : `${process.env.REACT_APP_EVENT_IMG}/mic-off.svg`
              }
            >
              <p className="tip-text">
                {controls?.trackState?.audio ? (
                  <FormattedMessage id="controls.disabledMic" />
                ) : (
                  <FormattedMessage id="controls.enabledMic" />
                )}
              </p>
            </Button>
            <Button
              variant={controls?.trackState?.video ? "controls-menu" : "off"}
              onClick={() => {
                mute("video");
                registerActivity(
                  "PRINCIPAL",
                  controls?.trackState?.video ? "CAM_OFF" : "CAM_ON"
                );
              }}
              icon={
                controls?.trackState?.video
                  ? `${process.env.REACT_APP_EVENT_IMG}/cam.svg`
                  : `${process.env.REACT_APP_EVENT_IMG}/cam-off.svg`
              }
            >
              <p className="tip-text">
                {controls?.trackState?.video ? (
                  <FormattedMessage id="controls.disabledCam" />
                ) : (
                  <FormattedMessage id="controls.enabledCam" />
                )}
              </p>
            </Button>
            {rolAuditorium.rol !== "SPEAKER-GUEST" && (
              <Button
                variant={controls.localScreen ? "off-social" : "controls-menu"}
                onClick={
                  controls.localScreen
                    ? () => {
                        controls.unSharedScreen();
                        registerActivity("PRINCIPAL", "SCREEN_OFF");
                      }
                    : () => {
                        controls.sharedScreen();
                        registerActivity("PRINCIPAL", "SCREEN_ON");
                      }
                }
                icon={
                  !controls.localScreen
                    ? `${process.env.REACT_APP_EVENT_IMG}/pantalla.svg`
                    : `${process.env.REACT_APP_EVENT_IMG}/pantalla-of.svg`
                }
              >
                <p className="tip-text">
                  {controls.localScreen ? (
                    <FormattedMessage id="controls.unshareScreen" />
                  ) : (
                    <FormattedMessage id="controls.shareScreen" />
                  )}
                </p>
              </Button>
            )}
            <Button
              variant={
                !controls.fullScreen.active ? "controls-menu" : "off-social"
              }
              onClick={
                !controls.fullScreen.active
                  ? () => {
                      controls.fullScreen.enter();
                      registerActivity("PRINCIPAL", "FSCREEN_ON");
                    }
                  : () => {
                      controls.fullScreen.exit();
                      registerActivity("PRINCIPAL", "FSCREEN_OFF");
                    }
              }
              icon={
                !controls.fullScreen.active
                  ? `${process.env.REACT_APP_EVENT_IMG}/full.svg`
                  : `${process.env.REACT_APP_EVENT_IMG}/full-of.svg`
              }
            >
              <p className="tip-text">
                {!controls.fullScreen.active ? (
                  <FormattedMessage id="controls.fullScreen" />
                ) : (
                  <FormattedMessage id="controls.closeFullScreen" />
                )}
              </p>
            </Button>
            <div
              className={"button--controls-menu mobile-none"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen && (
                <p className="tip-text2">
                  <Button
                    variant={"controls-menu2"}
                    onClick={() => {
                      controls.setBackgroundImage(
                        event.event.images[0].background
                      );
                      registerActivity("PRINCIPAL", "BGROUND_ON");
                    }}
                    icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-image.svg`}
                  >
                    <p className="tip-text3">Background image</p>
                  </Button>
                  <Button
                    variant={"controls-menu2"}
                    onClick={() => {
                      controls.setBackgroundBlurring();
                      registerActivity("PRINCIPAL", "BGROUND_ON");
                    }}
                    icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-blur.svg`}
                  >
                    <p className="tip-text3">Background Blur</p>
                  </Button>
                  <Button
                    variant={"controls-menu2"}
                    onClick={() => {
                      controls.setBackgroundColor();
                      registerActivity("PRINCIPAL", "BGROUND_ON");
                    }}
                    icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-color.svg`}
                  >
                    <p className="tip-text3">Background Color</p>
                  </Button>
                  {/* <Button
                  variant={"controls-menu"}
                  onClick={() => setBackgroundVideo()}
                >
                  video
                </Button> */}
                  <Button
                    variant={"controls-menu2"}
                    onClick={() => {
                      controls.backgroundDisable();
                      registerActivity("PRINCIPAL", "BGROUND_OFF");
                    }}
                    icon={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-disabled.svg`}
                  >
                    <p className="tip-text3">Background Disable</p>
                  </Button>
                </p>
              )}
              <p className="tip-text" style={{ fontWeight: "700" }}>
                Background
              </p>
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/backgrounds-off.svg`}
                width="30"
              ></img>
            </div>
            {rolAuditorium.rol !== "MANAGER" &&
              rolAuditorium.rol !== "SPEAKER" && (
                <Button
                  variant="off-social"
                  onClick={handDown}
                  icon={`${process.env.REACT_APP_EVENT_IMG}/manoarriba.svg`}
                >
                  <p className="tip-text">
                    <FormattedMessage id="controls.handDown" />
                  </p>
                </Button>
              )}
            {rolAuditorium.rol !== "SPEAKER-GUEST" && (
              <Button
                variant={
                  controls.isStreaming === "CONNECTED" ? "controls-menu" : "off"
                }
                onClick={
                  controls.isStreaming === "CONNECTED"
                    ? () => handleClientJoin("DISCONNECTED")
                    : () => handleClientJoin("CONNECTED")
                }
                icon={
                  controls.isStreaming === "DISCONNECTED"
                    ? `${process.env.REACT_APP_EVENT_IMG}/broadcast-on.svg`
                    : `${process.env.REACT_APP_EVENT_IMG}/broadcast-off.svg`
                }
              >
                <p className="tip-text">
                  {controls.isStreaming === "DISCONNECTED" ? (
                    <FormattedMessage id="controls.startStreaming" />
                  ) : (
                    <FormattedMessage id="controls.stopStreaming" />
                  )}
                </p>
              </Button>
            )}
            {room_type === undefined &&
              rolAuditorium.rol !== "SPEAKER-GUEST" && (
                <Button
                  variant="controls-menu"
                  onClick={() => {
                    muteAll();
                    registerActivity("PRINCIPAL", "MIC_OFF_ALL");
                  }}
                  icon={`${process.env.REACT_APP_EVENT_IMG}/mics-off.svg`}
                >
                  <p className="tip-text">
                    <FormattedMessage id="controls.muteAllMics" />
                  </p>
                </Button>
              )}
            <Button
              variant="controls-menu"
              onClick={() => {
                registerActivity("PRINCIPAL", "EXIT_ROOM");
                leaveChannel();
              }}
              icon={`${process.env.REACT_APP_EVENT_IMG}/salir.svg`}
            >
              <p className="tip-text">
                <FormattedMessage id="controls.exit" />
              </p>
            </Button>
            {rolAuditorium.rol === "MANAGER" && (
              <Button
                variant={!isCloudRecording ? "off-social" : "off"}
                onClick={
                  isCloudRecording
                    ? () => {
                        cloudRecording();
                        registerActivity("PRINCIPAL", "REC_OFF");
                      }
                    : () => {
                        cloudRecording();
                        registerActivity("PRINCIPAL", "REC_ON");
                      }
                }
                icon={
                  !isCloudRecording
                    ? `${process.env.REACT_APP_EVENT_IMG}/record-on.svg`
                    : `${process.env.REACT_APP_EVENT_IMG}/record-off.svg`
                }
              >
                <p className="tip-text">
                  {!isCloudRecording ? (
                    <FormattedMessage id="controls.startRecording" />
                  ) : (
                    <FormattedMessage id="controls.stopRecording" />
                  )}
                </p>
              </Button>
              
            )}
            <Button
              variant="controls-menu"
              onClick={() => setIsOpenControls(false)}
            >
              <p className="tip-text">
                Ocultar controles
              </p>
            </Button>
          </div>
        )}
      </>
    );
  } catch (error) {
    console.error(error);
  }
}

export default Controls;
