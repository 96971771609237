import { AgoraVideoPlayer } from "agora-rtc-react";
import React from "react";
import { useContext } from "react";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import ControlsStatusAudioSistribution from "../MainScreen/ControlStatusAudioDistribution";

function CameraDistributionNetwork() {
  const { speakers, screens, tracks, localScreen } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);

  return (
    <div
      className={`marginTop video-grid-auditorio-${
        screens.length > 0 || localScreen ? speakers.length : 0
      }`}
    >
      {tracks &&
        tracks.length > 0 &&
        (screens.length > 0 || localScreen) &&
        speakers.length > 0 && (
          <div className="miniCams">
            <AgoraVideoPlayer className="vid-minicams" videoTrack={tracks[1]} />

            <div className="minicams-name">{dataUser.name}</div>
          </div>
        )}

      {tracks &&
        tracks.length > 0 &&
        tracks[1].enabled &&
        (screens.length > 0 || localScreen) &&
        speakers.length === 0 && (
          <div className="vid-minicams-local">
            <AgoraVideoPlayer
              className="vid-minicams-localscreen"
              videoTrack={tracks[1]}
            />
            <div className="minicams-name">{dataUser.name}</div>
          </div>
        )}

      {(screens.length > 0 || localScreen) &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {user.videoTrack !== undefined && (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            )}
            <ControlsStatusAudioSistribution userUid={user.uid} />
            <div className="minicams-name">{user.userName}</div>
          </div>
        ))}
    </div>
  );
}

export default CameraDistributionNetwork;
