import React from 'react'

export default function ScheduleSponsor({ data }) {
    return (
        <div className='schedule-info' >
            {data && data.schedule.map((sche, i) =>
                <div key={i}>
                    <ul className="list-schedule-info">
                        <li>
                            <h2>{sche.date}{" "}<br></br><span>{sche.hour}</span></h2>
                        </li>
                        <li>
                            <h1>{sche.subject}</h1>
                            <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "-25px" }}>
                                <img
                                    src={
                                        `${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`
                                    }
                                    alt="imagen de speaker"
                                    width={"30"}

                                />
                                <p>{sche.speaker}</p>
                            </div>
                        </li>
                        <li>
                            <p>{sche.description}</p>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}
