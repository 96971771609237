import React from 'react'
import useWindowSize from '../../utils/useWindowSize';
import AgendaDesktopUser from './AgendaDesktopUser';
import AgendaMobile from './agendaMobile';

function AgendaMainUser() {
    const { width } = useWindowSize();
    const breakpointWidth = 768;
  
    return <>{width >= breakpointWidth ? <AgendaDesktopUser /> : <AgendaMobile />}</>;
}

export default AgendaMainUser