import React from 'react'
import Footer from '../components/Footer/Footer';
import Header from '../components/header/header';
import MainAuditoriumNetworking from '../components/RoomAuditoriumNetworking/MainAuditoriumNetworking';
import ActionsProvider from '../context/Actions/ActionsControl';
import CamsConected from '../context/CamsConected/CamsConected';

function ViewAuditoriumNetworking() {
    return (
        <CamsConected>
          <div className="App">
            <Header />
            <div className="container" style={{ margin: "auto" }}>
              <ActionsProvider>
                <MainAuditoriumNetworking />
              </ActionsProvider>
            </div>
            <Footer />
          </div>
        </CamsConected>
      );
}

export default ViewAuditoriumNetworking