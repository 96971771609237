import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { EventData } from "../../../context/EventData/EventData";
import ScheduleSponsor from "../../RoomAuditorium/ScheduleSponsor";
import StatisticsCircleSponsor from "../../Statistics/StatisticsCircleSponsor";
import "./infoSpeaker.css";

function DescriptionEvent({ data, getDataSponsor }) {
  const { event } = useContext(EventData);
  const [isSelected, setIsSelected] = useState("info");
  const { rolAuditorium } = useContext(EventData);
  const { attendeeid, room_type } = useParams();

  return (
    <>
      <div className="list-infoSpeaker">
        <ul className="lista-selects">
          {data && data.schedule && (
            <li
              className={isSelected === "schedule" ? "active" : "disabled"}
              onClick={() => setIsSelected("schedule")}
            >
              <FormattedMessage
                id="schedule.schedule"
                defaultMessage="Agenda"
              />
            </li>
          )}
          {data && data.schedule && <li>|</li>}
          <li
            className={isSelected === "info" ? "active" : "disabled"}
            onClick={() => setIsSelected("info")}
          >
            <FormattedMessage id="infoSpeaker.info" defaultMessage="Nombre" />
          </li>
          {data && data.resources && data.resources.length > 0 && <li>|</li>}
          {data && data.resources && data.resources.length > 0 && (
            <li
              className={isSelected === "resources" ? "active" : "disabled"}
              onClick={() => setIsSelected("resources")}
            >
              <FormattedMessage
                id="infoSpeaker.recursos"
                defaultMessage="Nombre"
              />
            </li>
          )}
          {data && data.team && (
            <li style={{ backgroundColor: "transparent" }}>|</li>
          )}
          {data && data.team && (
            <li
              className={isSelected === "team" ? "active" : "disabled"}
              onClick={() => {
                setIsSelected("team");
                getDataSponsor();
              }}
            >
              <FormattedMessage id="infoSpeaker.team" />
            </li>
          )}
          {rolAuditorium &&
            (rolAuditorium.rol === "SPONSOR" ||
              rolAuditorium.rol === "MANAGER") &&
            room_type === "sponsor" && (
              <li style={{ backgroundColor: "transparent" }}>|</li>
            )}
          {rolAuditorium &&
            (rolAuditorium.rol === "SPONSOR" ||
              rolAuditorium.rol === "MANAGER") &&
            room_type === "sponsor" && (
              <li
                className={isSelected === "statistics" ? "active" : "disabled"}
                onClick={() => {
                  setIsSelected("statistics");
                }}
              >
                <FormattedMessage id="infoSpeaker.statistics" />
              </li>
            )}
        </ul>

        <div
          className="space-infoSpeaker"
          style={{ backgroundColor: "#12133f" }}
        >
          {isSelected === "info" && (
            <div className="data-information">
              {data && data.information && (
                <div
                  className="data-info"
                  dangerouslySetInnerHTML={{ __html: data.information }}
                ></div>
              )}
              {data &&
                data.speakers_detail &&
                data.speakers_detail.map((speaker, index) => (
                  <div key={index} className="container-speaker">
                    <img
                      src={speaker.photo}
                      className="photo-team-speakers"
                    ></img>
                    <div>
                      <div className="container-speaker">
                        <img
                          src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                          width={50}
                        ></img>
                        <h1 style={{ color: "#fff" }}>{speaker.name}</h1>
                      </div>
                      <h2>{speaker.title}</h2>
                      <p
                        style={{ color: "#fff" }}
                        dangerouslySetInnerHTML={{ __html: speaker.biography }}
                      ></p>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {isSelected === "resources" && (
            <div className="grid-resource">
              {data &&
                data.resources &&
                data.resources.map((resource, index) => (
                  <a
                    href={resource.url}
                    key={index}
                    download={resource.name}
                    target="_blank"
                    className="resource"
                  >
                    <img
                      src={`${process.env.REACT_APP_EVENT_IMG}/${resource.type}.svg`}
                      width={50}
                    ></img>
                    <div>
                      <h4>{resource.name}</h4>
                      <span>
                        {resource.type} {resource.size}
                      </span>
                    </div>
                  </a>
                ))}
            </div>
          )}
          {isSelected === "team" && (
            <div className="team">
              {data &&
                data.team &&
                data.team.map((person, index) => (
                  <div key={index} className={"flex-team"}>
                    <div className="img-team">
                      <img src={person.photo}></img>
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "-25px" }}>{person.name}</h3>
                      <h4>
                        {person.rol}
                        <br></br>
                        <span style={{ textTransform: "lowercase" }}>
                          {person.email}
                        </span>
                      </h4>
                      <div className="buttons">
                        {person.room_active &&
                          person.private &&
                          rolAuditorium &&
                          rolAuditorium.rol === "ATTENDEE" && (
                            <a
                              href={`/${event.event.site}/networking/networking-private/${person.room}/attendee/${attendeeid}/active/${person.room_active}`}
                            >
                              <button>
                                <FormattedMessage
                                  id="boton.private"
                                  defaultMessage="Agenda"
                                />
                              </button>
                            </a>
                          )}
                        {person.room_active &&
                          person.networking &&
                          rolAuditorium &&
                          rolAuditorium.rol === "ATTENDEE" && (
                            <a
                              href={`/${event.event.site}/networking/networking/${person.room}/attendee/${attendeeid}/active/${person.room_active}`}
                            >
                              <button>
                                <FormattedMessage
                                  id="boton.networking"
                                  defaultMessage="Agenda"
                                />
                              </button>
                            </a>
                          )}
                        {rolAuditorium &&
                          person.private &&
                          rolAuditorium.rol !== "ATTENDEE" && (
                            <a
                              href={`/${event.event.site}/networking/networking-private/${person.room}/attendee/${attendeeid}/active/${person.room_active}`}
                            >
                              <button>
                                <FormattedMessage
                                  id="boton.private"
                                  defaultMessage="Agenda"
                                />
                              </button>
                            </a>
                          )}
                        {rolAuditorium &&
                          person.networking &&
                          rolAuditorium.rol !== "ATTENDEE" && (
                            <a
                              href={`/${event.event.site}/networking/networking/${person.room}/attendee/${attendeeid}/active/${person.room_active}`}
                            >
                              <button>
                                <FormattedMessage
                                  id="boton.networking"
                                  defaultMessage="Agenda"
                                />
                              </button>
                            </a>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {isSelected === "schedule" && <ScheduleSponsor data={data} />}
          {isSelected === "statistics" && (
            <StatisticsCircleSponsor EventId={event.event.id} />
          )}
        </div>
      </div>
    </>
  );
}

export default DescriptionEvent;
