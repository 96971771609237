import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useContext, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router-dom";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import useBackgroundVideo from "../../Hooks/useBackgroundVideo";
import Controls from "../main/MainScreen/Controls";
import AuditoriumSponsor from "./AuditoriumSponsor";

const config = {
  mode: "live",
  codec: "vp8",
};
let configCamera = {
  encoderConfig: "1080_p",
};

let useMicrophoneAndCameraTracks = null;

useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks(configCamera);

const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallSponsor(props) {
  const { setInCall, channelName, token, validateRol } = props;
  let microphoneAndCamera = useMicrophoneAndCameraTracks();
  let ready = false;
  let tracks = false;
  ready = microphoneAndCamera.ready;
  tracks = microphoneAndCamera.tracks;
  const { room, attendeeid } = useParams();
  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
  } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);
  const { userSocket, event, usersName, setActionsUsers } =
    useContext(EventData);
  const [start, setStart] = useState(false);
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [isStreaming, setIsStreaming] = useState("DISCONNECTED");

  const client = useClient();
  let screenClient = useDesktopClient();
  const handle = useFullScreenHandle();
  const { setBackgroundColor, setBackgroundImage, setBackgroundBlurring } = useBackgroundVideo(tracks);
  let screen = null;

  const loginScreenJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCallSponsor:76", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCallSponsor:81", "::desconectado::");
      });
  };

  const sharedScreen = async () => {
    if (!loginScreen) {
      screenClient.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCallSponsor:89", "::setHost success");
        } else {
          consoleLogFunction("VideoCallSponsor:91", "::setHost error", e);
        }
      });
      loginScreenJoin();
    }
    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: token.resolution_desktop,
      },
      "auto"
    );

    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-SHARING",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);

    await screenClient.publish(screen);

    if (screen instanceof Array)
      screen[0].on("track-ended", () => {
        unSharedScreen();
      });
    else
      screen.on("track-ended", () => {
        unSharedScreen();
      });
  };

  const unSharedScreen = async () => {
    await screenClient.leave();
    screenClient.removeAllListeners();

    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-UNSHARING",
      id: attendeeid,
      product: "EVENTS",
      product_id: event.event.id,
    };
    setActionsUsers(attendee);

    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }
    setLocalScreen(false);
    setLoginScreen(false);
  };

  const getUserName = async (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);

    return await response;
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        const userName = await getUserName(user.uid);
        await client.subscribe(user, mediaType);
        user.userName = userName.name;

        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
        if (mediaType === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (token && !loginUser) {
        client.setClientRole("host", function (e) {
          if (!e) {
            consoleLogFunction("VideoCallSponsor:226", "::setHost success");
          } else {
            consoleLogFunction("VideoCallSponsor:228", "::setHost error", e);
          }
        });
        await client
          .join(token.app, token.channel, token.token, token.uid)
          .then(() => {
            setLoginUser(true);
            consoleLogFunction("VideoCallSponsor:235", "::conectado camara::");
          })
          .catch(() => {
            setLoginUser(false);
            consoleLogFunction("VideoCallSponsor:239", "::desconectado::");
          });
      }

      if (tracks && ready) {
        await client.publish(tracks[0]);
        await client.publish(tracks[1]);
      }

      setStart(true);
    };

    if (token && ready && tracks) {
      tracks[1].setEncoderConfiguration(token.resolution);
      tracks[1].userName = dataUser.name;
      setTracks(tracks);
      init(channelName);
    }

    return () => {
      return setStart(true);
    };
  }, [token, ready, tracks]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      const userName = getUserName(user.uid_desktop);
      await screenClient.subscribe(user, mediaType);
      user.userName = userName.name;
      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    if (userSocket) {
      consoleLogFunction("VideoCallSponsor:297", userSocket);
      if (userSocket.command === "ATTENDEE" && localScreen) {;
        var attendee = {
          type: "screen",
          name: userSocket.name,
          room: room,
          command: "DESKTOP-SHARING",
          id: attendeeid,
        };
        setActionsUsers(attendee);
      }
      if (userSocket.command === "ATTENDEE" && isStreaming === "CONNECTED") {
        var attendee = {
          type: "join-client",
          name: userSocket.name,
          room: room,
          command: "CONNECTED",
          id: attendeeid,
        };
        setTimeout(() => {
          setActionsUsers(attendee);
        }, 2000);
      }
      if (userSocket.type === "screen") {
        if (userSocket.command === "DESKTOP-SHARING") {
          loginScreenJoin();
        } else if (userSocket.command === "DESKTOP-UNSHARING") {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  return (
    <div style={{ width: "100%", height: "70vh" }}>
      {start && validateRol && (
        <FullScreen handle={handle}>
          <AuditoriumSponsor
            userConnectedImages={[]}
            users={speakers}
            localScreen={localScreen}
            tracks={tracks}
            rol={validateRol.rol}
            screens={screens}
            fullScreen={handle}
            trackState={trackState}
          />
          <Controls
            variant={""}
            controls={{
              tracks: tracks,
              setStart: setStart,
              setInCall: setInCall,
              sharedScreen: sharedScreen,
              unSharedScreen: unSharedScreen,
              setBackgroundColor,
              setBackgroundImage,
              setBackgroundBlurring,
              users: speakers,
              localScreen: localScreen,
              client: client,
              desktop: screenClient,
              fullScreen: handle,
              trackState: trackState,
              setTrackState: setTrackState,
              isStreaming: isStreaming,
              setIsStreaming: setIsStreaming,
              ids: { uid: token.uid, uid_desktop: token.uid_desktop },
              token
            }}
          />
        </FullScreen>
      )}
    </div>
  );
}

export default VideoCallSponsor;
