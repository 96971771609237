import AgoraRTC, {
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router-dom";
import { usersConected } from "../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../context/DataUser/DataUser";
import { EventData } from "../../context/EventData/EventData";
import { consoleLogFunction } from "../../functions/functionLog";
import useBackgroundVideo from "../../Hooks/useBackgroundVideo";
import ControlsAuditoriumNetworking from "./ControlsAuditoriumNetworking";
import RoomAuditoriumNetworking from "./RoomAuditoriumNetworking";

const config = {
  mode: "live",
  codec: "vp8",
};
let configCamera = {
  encoderConfig: "720p_1",
};

let devices = null;
let useMicrophoneAndCameraTracks = null;

async function getDevices() {
  devices = await navigator.mediaDevices.enumerateDevices();
  useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks(configCamera);
}
getDevices();

const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallAuditoriumNetworking(props) {
  const { channelName, token, ids } = props;
  let microphoneAndCamera = useMicrophoneAndCameraTracks();
  let ready = false;
  let tracks = false;

  ready = microphoneAndCamera.ready;
  tracks = microphoneAndCamera.tracks;

  const { room, attendeeid } = useParams();
  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
  } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);
  const {
    event,
    usersName,
    userSocket,
    setActionsUsers,
    setRolAuditorium,
    rolAuditorium,
  } = useContext(EventData);

  const [start, setStart] = useState(false);
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [isStreaming, setIsStreaming] = useState("DISCONNECTED");

  const client = useClient();
  let screenClient = useDesktopClient();
  const handle = useFullScreenHandle();
  const { setBackgroundColor, setBackgroundImage, setBackgroundBlurring } =
    useBackgroundVideo(tracks);
  let screen = null;

  const sharedScreen = async () => {
    if (!loginScreen) {
      screenClient.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCall:84", "::setHost success");
        } else {
          consoleLogFunction("VideoCall:86", "::setHost error" + e);
        }
      });
      screenClientJoin();
    }

    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: token.resolution_desktop,
      },
      "auto"
    );
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-SHARING",
      id: attendeeid,
    };
    setActionsUsers(attendee);

    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    screenClient.publish(screen);

    if (screen instanceof Array)
      screen[0].on("track-ended", () => {
        unSharedScreen();
      });
    else
      screen.on("track-ended", () => {
        unSharedScreen();
      });
  };

  const unSharedScreen = async () => {
    screenClient.leave();
    screenClient.removeAllListeners();
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-UNSHARING",
      id: attendeeid,
    };
    setActionsUsers(attendee);
    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }
    setLocalScreen(false);
    setLoginScreen(false);
  };

  const getUserName = async (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return await response;
  };

  const init = async () => {
    client.on("user-published", async (user, mediaType) => {
      await getUserName(user.uid).then(async (result) => {
        await client.subscribe(user, mediaType);
        user.userName = result.name ? result.name : "";
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    client.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      setSpeakers((prevUsers) => {
        if (!prevUsers.find((usr) => usr.uid === user.uid)) {
          return [...prevUsers, user];
        } else {
          const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
          return [...usrs, user];
        }
      });
    });

    client.on("user-left", (user) => {
      setSpeakers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    if (token && !loginUser) {
      client.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCall:223", "::setHost success");
        } else {
          consoleLogFunction("VideoCall:225", "::setHost error", e);
        }
      });
      await client
        .join(token.app, token.channel, token.token, token.uid)
        .then(() => {
          setLoginUser(true);
          consoleLogFunction("VideoCall:232", "::conectado camara::");
        })
        .catch(() => {
          setLoginUser(false);
          consoleLogFunction("VideoCall:236", "::desconectado::");
        });
    }

    if (
      tracks &&
      ready &&
      rolAuditorium.rol !== "ATTENDEE" &&
      rolAuditorium.rol !== "SPONSOR"
    ) {
      await client.publish(tracks[0]);
      await client.publish(tracks[1]);
    }
    setStart(true);
  };

  const screenClientJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCall:165", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCall:169", "::desconectado::");
      });
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    if (token && ready && tracks) {
      tracks[1].setEncoderConfiguration(token.resolution);
      tracks[1].userName = dataUser.name;
      if (rolAuditorium.rol !== "MANAGER" && rolAuditorium.rol !== "SPEAKER") {
        tracks[1].setEnabled(false);
        tracks[0].setEnabled(false);
        setTrackState({ video: false, audio: false });
      }

      setTracks(tracks);
      if (rolAuditorium.rol === "MANAGER" || rolAuditorium.rol === "SPEAKER")
        init();
    }

    return () => {
      return setStart(true);
    };
  }, [channelName, client, ready, tracks, token, screenClient, screen]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      await screenClient.subscribe(user, mediaType);
      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio") {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    consoleLogFunction("VideoCall:289", userSocket);
    if (userSocket) {
      if (
        userSocket.command === "ATTENDEE" &&
        userSocket.action === "IN" &&
        localScreen
      ) {
        setTimeout(() => {
          var attendee = {
            type: "screen",
            name: dataUser.name,
            room: room,
            command: "DESKTOP-SHARING",
            id: attendeeid,
            product: "EVENTS",
            product_id: event.event.id,
          };
          setActionsUsers(attendee);
        }, 2000);
      }
      if (userSocket.command === "ATTENDEE" && isStreaming === "CONNECTED") {
        var attendee = {
          type: "join-client",
          name: dataUser.name,
          room: room,
          command: "CONNECTED",
          id: attendeeid,
          product: "EVENTS",
          product_id: event.event.id,
        };
        setActionsUsers(attendee);
      }
      if (
        userSocket.command === "CONNECTED" &&
        userSocket.type === "join-client"
      ) {
        init();
      }
      if (
        userSocket.command === "STATUS-STREAMING-ALL" &&
        userSocket.type === "controls"
      ) {
        if (userSocket.value === "CONNECTED") {
          init();
        } else {
          client.leave();
          client.removeAllListeners();
          screenClient.leave();
          screenClient.removeAllListeners();
          // we close the tracks to perform cleanup
          setSpeakers([]);
          setLoginUser(false);
        }
      }
      if (
        userSocket.command === "ACCESS-DEVICES" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "SPEAKER", name: dataUser.name });
        }, 1000);
      }
      if (userSocket.command === "ACCESS-FINISH" && userSocket.to === ids.uid) {
        setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        }, 1000);
        tracks[0].setEnabled(false);
        tracks[1].setEnabled(false);
        client.unpublish();
        setTrackState({ video: false, audio: false });
        setActionsUsers({
          command: "HAND-DOWN",
          name: dataUser.name,
          id: attendeeid,
          uid: ids.uid,
          uid_desktop: ids.uid_desktop,
        });
      }

      if (userSocket.command === "ACCESS-FINISH" && userSocket.to !== ids.uid) {
        setTimeout(() => {
          setSpeakers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== userSocket.to);
          });
        }, 5000);
      }
      if (
        userSocket.command === "ACCESS-DEVICES-M" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "SPEAKER", name: dataUser.name });
        }, 1000);
      }
      if (
        userSocket.command === "ACCESS-FINISH-M" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        }, 1000);
        tracks[0].setEnabled(false);
        tracks[1].setEnabled(false);
        client.unpublish();
        setTrackState({ video: false, audio: false });
      }

      if (
        userSocket.command === "ACCESS-FINISH-M" &&
        userSocket.to !== ids.uid
      ) {
        setTimeout(() => {
          setSpeakers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== userSocket.to);
          });
        }, 5000);
      }
      if (userSocket.type === "screen") {
        if (
          userSocket.command === "DESKTOP-SHARING" &&
          userSocket.name !== dataUser.name
        ) {
          screenClientJoin();
        } else if (
          userSocket.command === "DESKTOP-UNSHARING" &&
          userSocket.name !== dataUser.name
        ) {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  return (
    <div className="vid">
      {start && tracks && (
        <FullScreen handle={handle}>
          <div>
            <RoomAuditoriumNetworking
              users={speakers.length > 0 ? speakers : []}
              screens={screens}
              controls={{
                setBackgroundColor,
                setBackgroundImage,
                setBackgroundBlurring,
                tracks: tracks,
                setStart: setStart,
                sharedScreen: sharedScreen,
                unSharedScreen: unSharedScreen,
                users: speakers,
                localScreen: localScreen,
                client: client,
                desktop: screenClient,
                fullScreen: handle,
                trackState: trackState,
                setTrackState: setTrackState,
                isStreaming: isStreaming,
                setIsStreaming: setIsStreaming,
                ids: ids,
              }}
            />
            {rolAuditorium.rol !== "MANAGER" &&
              rolAuditorium.rol !== "SPEAKER" && (
                <ControlsAuditoriumNetworking
                  variant={""}
                  controls={{
                    setBackgroundColor,
                    setBackgroundImage,
                    setBackgroundBlurring,
                    tracks: tracks,
                    setStart: setStart,
                    sharedScreen: sharedScreen,
                    unSharedScreen: unSharedScreen,
                    users: speakers,
                    localScreen: localScreen,
                    client: client,
                    desktop: screenClient,
                    fullScreen: handle,
                    trackState: trackState,
                    setTrackState: setTrackState,
                    isStreaming: isStreaming,
                    setIsStreaming: setIsStreaming,
                    ids: ids,
                  }}
                />
              )}
          </div>
        </FullScreen>
      )}
    </div>
  );
}

export default VideoCallAuditoriumNetworking;
