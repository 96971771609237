import React, { createContext, useState } from 'react';

export const roomTypeContext = createContext()

function RoomType({ children }) {
    const [type, setType] = useState("")
    return <roomTypeContext.Provider value={{ type, setType }}>
        {children}
    </roomTypeContext.Provider>;
}

export default RoomType;
