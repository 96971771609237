import { useContext } from "react";
import { EventData } from "../context/EventData/EventData";
import { usersConected } from "../context/CamsConected/CamsConected";
import { dataUserContext } from "../context/DataUser/DataUser";
import useRequests from "./UseRequests";

function UseRecording({ room, streaming }) {
  const { setActionsUsers, event } = useContext(EventData);
  const { tokenCognito } = useRequests();
  const {
    speakers,
    isCloudRecording,
    setIsCloudRecording,
    sID,
    setSID,
    setInProcess,
  } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);

  const cloudRecording = async () => {
    const token = await tokenCognito()
    if (!isCloudRecording) {
      const suscribesDevices = speakers.map((user) => user.uid);
      suscribesDevices.push(streaming.uid);

      const bodyResquestCloud = {
        uids: suscribesDevices,
        token: streaming.token,
      };

      const requestCloud = await fetch(
        `${process.env.REACT_APP_URI_API}/${event.event.id}/recording`,
        {
          method: "POST",
          body: JSON.stringify(bodyResquestCloud),
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            "Content-type": "application/json",
          },
        }
      );

      const result = await requestCloud.json();

      if (requestCloud.status === 200) {
        setIsCloudRecording(true);
        setSID(result.id);

        var attendeeIO = {
          command: "RECORDING",
          name: dataUser.name,
          room: streaming.channel,
          value: true,
        };
        setActionsUsers(attendeeIO);
      }
    }

    if (isCloudRecording) {
      const suscribesDevices = speakers.map((user) => user.uid);
      suscribesDevices.push(streaming.uid);

      setInProcess(true);

      await fetch(
        `${process.env.REACT_APP_URI_API}/${event.event.id}/recording/${sID}/end`,
        {
          method: "POST",
          body: {},
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            "Content-type": "application/json",
          },
        }
      ).then(() => {
        setIsCloudRecording(false);
        var attendeeIO = {
          command: "RECORDING",
          name: dataUser.name,
          room: streaming.channel,
          id: room,
          value: false,
        };
        setActionsUsers(attendeeIO);
        setTimeout(() => {
          setInProcess(false);
        }, 4000);
      });
    }
  };

  return { cloudRecording, isCloudRecording };
}

export default UseRecording;
