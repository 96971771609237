import React from 'react'
import { useParams } from 'react-router-dom';
import VideoCallAuditoriumNetworking from './VideoCallAuditoriumNetworking';

function VideoAuditoriumNetworking({token, ids, children}) {
    const { room } = useParams();
  
    return (
      <section className="videoMain">
        <div>
          <div>
              <div className={"display-video"}>
                <VideoCallAuditoriumNetworking
                  channelName={room}
                  token={token}
                  ids={ids}
                />
              </div>
          </div>
          {children}
        </div>
      </section>
    );
}

export default VideoAuditoriumNetworking