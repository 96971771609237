import Footer from "../components/Footer/Footer";
import EventDetail from "../components/EventDetail/EventDetail";
import React from "react";
import HeaderEmpty from "../components/header/HeaderEmpty";

function Event() {
  return (
    <div>
      <HeaderEmpty />
      <EventDetail />
      <Footer />
    </div>
  );
}

export default Event;
