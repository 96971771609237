import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { actionsContext } from '../../context/Actions/ActionsControl';
import { EventData } from '../../context/EventData/EventData';

function CountDownNetwork() {
    const {
        setExitRoomSponsor,
        setExitRoomNetworking,
        startCounter,
        setStartCounter,
      } = useContext(actionsContext);
      const { event } = useContext(EventData);
      const { attendeeid, id} = useParams();
      const [timerDays, setTimerDays] = useState("00");
      const [timerHours, setTimerHours] = useState("00");
      const [timerMinutes, setTimerMinutes] = useState("00");
      const [timerSeconds, setTimerSeconds] = useState("00");
      const [statusClock, setStatusClock] = useState(true);
      const [date, setDate] = useState();
      let interval = useRef();

      const startTimer = () => {
        const countDownDate = new Date(date).getTime();
        interval = setInterval(() => {
          const now = new Date().getTime();
          const distance = countDownDate - now;
    
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
          if (distance < 0) {
            setStatusClock(false);
    
            setExitRoomNetworking(true);
            setExitRoomSponsor(true);
          } else {
            setTimerDays(days);
            setTimerHours(hours);
            setTimerMinutes(minutes);
            setTimerSeconds(seconds);
          }
        }, 1000);
      };
    
      useEffect(() => {
        const remain = new Date();
        const sumMinutes = remain.getMinutes();
    
        if (event)
          remain.setMinutes(sumMinutes + event.event.plan.networking.minutes);
    
        setDate(remain);
    
      }, []);
    
      useEffect(() => {
        if (date) startTimer();
      }, [date]);
    
      useEffect(() => {
        clearInterval(interval.current);
      }, [statusClock]);
    
      return (
        <div>
          <div className={`box-countdown`}>
            <div className={`Countdown-item`}>
              <h1>{timerMinutes}</h1>
              <h3>
                <FormattedMessage id={"count-down.minutes"}></FormattedMessage>
              </h3>
            </div>
            <div className={`Countdown-item`}>
              <h1>:</h1>
            </div>
            <div className={`Countdown-item`}>
              <h1>{timerSeconds}</h1>
              <h3>
                <FormattedMessage id={"count-down.seconds"}></FormattedMessage>
              </h3>
            </div>
          </div>
        </div>
      );
}

export default CountDownNetwork