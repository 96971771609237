import React, { useContext, useEffect, useState } from "react";
import useRegisterActivity from "../../../Hooks/useRegisterActivity";
import { EventData } from "../../../context/EventData/EventData";
import AgoraRTC from "agora-rtc-sdk-ng";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { dataUserContext } from "../../../context/DataUser/DataUser";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useBackgroundVideo from "../../../Hooks/useBackgroundVideo";
import { useParams } from "react-router-dom";
import { consoleLogFunction } from "../../../functions/functionLog";
import { createClient } from "agora-rtc-react";
import { FormattedMessage } from "react-intl";
import ControlAttende from "./ControlAttende";
import RoomAuditorioMobile from "./RoomAuditorioMobile";
import DropdownMenuMobile from "../../agenda/DropdownMenuMobile";

const config = {
  mode: "live",
  codec: "vp8",
};

const useClient = createClient(config);
const useDesktopClient = createClient(config);
let namesUsers = [];

function VideoCallMobile(props) {
  const { token, ids, setSelected, typeRoom } = props;
  const [tracks, setTracks2] = useState(false);
  const [popupMicrophones, setPopupMicrophones] = useState(false);
  const { registerActivity } = useRegisterActivity();
  const [microphones, setMicrophones] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordingText, setRecordingText] = useState(false);
  const {
    usersName,
    userSocket,
    setActionsUsers,
    rolAuditorium,
    setRolAuditorium,
    event,
    setSelectedchat,
  } = useContext(EventData);

  async function getDevices() {
    const tracks2 = [
      await AgoraRTC.createMicrophoneAudioTrack({ ANS: true }),
      await AgoraRTC.createCameraVideoTrack(),
    ];
    setTracks2(tracks2);
  }

  useEffect(() => {
    if (rolAuditorium.rol !== "ATTENDEE") {
      getDevices();
    } else {
      setTracks2([]);
    }
  }, [rolAuditorium]);

  const { room, attendeeid } = useParams();
  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
    isCloudRecording,
    inProcess,
  } = useContext(usersConected);
  const { dataUser } = useContext(dataUserContext);

  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [isStreaming, setIsStreaming] = useState("DISCONNECTED");
  const client = useClient();
  let screenClient = useDesktopClient();
  const handle = useFullScreenHandle();
  const {
    setBackgroundColor,
    setBackgroundImage,
    setBackgroundBlurring,
    backgroundDisable,
  } = useBackgroundVideo(tracks);
  let screen = null;

  const sharedScreen = async () => {
    if (!loginScreen) {
      screenClient.setClientRole("host", function (e) {
        if (!e) {
          consoleLogFunction("VideoCall:84", "::setHost success");
        } else {
          consoleLogFunction("VideoCall:86", "::setHost error" + e);
        }
      });
      screenClientJoin();
    }

    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: token.resolution_desktop,
      },
      "auto"
    );
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-SHARING",
      id: attendeeid,
    };
    setActionsUsers(attendee);

    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    screenClient.publish(screen);

    if (screen instanceof Array)
      screen[0].on("track-ended", () => {
        unSharedScreen();
      });
    else
      screen.on("track-ended", () => {
        unSharedScreen();
      });
  };

  const unSharedScreen = async () => {
    screenClient.leave();
    screenClient.removeAllListeners();
    var attendee = {
      type: "screen",
      name: dataUser.name,
      room: room,
      command: "DESKTOP-UNSHARING",
      id: attendeeid,
    };
    registerActivity("PRINCIPAL", "SCREEN_OFF");
    setActionsUsers(attendee);
    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }
    setLocalScreen(false);
    setLoginScreen(false);
  };

  const getUserName = async (uid) => {
    const response = namesUsers.find((user) => user.uid === uid);
    return await response;
  };

  const clientJoin = async () => {
    if (token && !loginUser)
      await client
        .join(token.app, token.channel, token.token, token.uid)
        .then(() => {
          setLoginUser(true);
          consoleLogFunction("VideoCallNotCamera:96", "::conectado::");
        })
        .catch(() => {
          setLoginUser(false);
          consoleLogFunction("VideoCallNotCamera:100", "::desconectado::");
        });
  };

  const screenClientJoin = async () => {
    await screenClient
      .join(
        token.app,
        token.channel_desktop,
        token.token_desktop,
        token.uid_desktop
      )
      .then(() => {
        setLoginScreen(true);
        consoleLogFunction("VideoCall:165", "::conectado::");
      })
      .catch(() => {
        setLoginScreen(false);
        consoleLogFunction("VideoCall:169", "::desconectado::");
      });
  };

  const getMicrophone = async () => {
    const microphones = await AgoraRTC.getMicrophones();
    let listMicrophones;
    if (microphones.length > 3) {
      listMicrophones = microphones
        .filter((micro) => micro.deviceId !== "default")
        .filter((micro) => micro.deviceId !== "communications");
      setMicrophones(listMicrophones);
      setTimeout(() => {
        setPopupMicrophones(true);
      }, 1000);
    }
  };

  const changeMicrophone = (deviceId) => {
    tracks[0]
      .setDevice(deviceId)
      .then(() => {
        setPopupMicrophones(false);
      })
      .catch((e) => {
        console.error("set device error", e);
      });
  };

  useEffect(() => {
    namesUsers = [...usersName];
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        setTimeout(async () => {
          const userName = await getUserName(user.uid);

          await client.subscribe(user, mediaType);

          user.userName = userName.name;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 1000);
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (token && !loginUser) {
        client.setClientRole("host", function (e) {
          if (!e) {
            consoleLogFunction("VideoCall:223", "::setHost success");
          } else {
            consoleLogFunction("VideoCall:225", "::setHost error", e);
          }
        });

        if (rolAuditorium.rol !== "ATTENDEE") {
          clientJoin().then(() => {
            setTimeout(async () => {
              await client.publish(tracks[0]);
              await client.publish(tracks[1]);
              tracks[0].setEnabled(!trackState.audio);
              setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
              });
              tracks[1].setEnabled(!trackState.video);
              setTrackState((ps) => {
                return { ...ps, video: !ps.video };
              });
            }, 2000);
          });
        }
      }
    };

    if (token && tracks) {
      if (rolAuditorium.rol !== "ATTENDEE" && tracks.length > 0) {
        tracks[1].setEncoderConfiguration(token.resolution);
        tracks[1].userName = dataUser.name;
        setTracks(tracks);
      }
      init();
    }
  }, [client, tracks, token, rolAuditorium, loginUser]);

  useEffect(() => {
    screenClient.on("user-published", async (user, mediaType) => {
      await screenClient.subscribe(user, mediaType);
      if (mediaType === "video")
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      if (mediaType === "audio") {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.stop();
      }
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      }
    });
  }, [loginScreen]);

  useEffect(() => {
    consoleLogFunction("VideoCall:325", userSocket);
    if (userSocket) {
      if (userSocket.command === "ATTENDEE" && localScreen) {
        setTimeout(() => {
          var attendee = {
            type: "screen",
            name: dataUser.name,
            command: "DESKTOP-SHARING",
            id: attendeeid,
          };
          setActionsUsers(attendee);
        }, 2000);
      }
      if (userSocket.type === "join-client") {
        if (
          userSocket.command === "CONNECTED" &&
          userSocket.name !== dataUser.name &&
          rolAuditorium.rol === "ATTENDEE"
        ) {
          clientJoin();
        }
        if (
          userSocket.command === "DISCONNECTED" &&
          userSocket.name !== dataUser.name &&
          rolAuditorium.rol === "ATTENDEE"
        ) {
          client.leave();
          client.removeAllListeners();
          setSpeakers([]);
          setLoginUser(false);
        }
      }
      if (userSocket.command === "ATTENDEE" && isStreaming === "CONNECTED") {
        var attendee = {
          type: "join-client",
          name: dataUser.name,
          command: "CONNECTED",
          id: attendeeid,
        };
        setActionsUsers(attendee);
      }
      if (userSocket.command === "RECORDING") {
        setRecording(userSocket.value);
      }
      if (
        userSocket.command === "ACCESS-DEVICES" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "SPEAKER-GUEST", name: dataUser.name });
          setTimeout(() => {
            client.leave();
            client.removeAllListeners();
            setSpeakers([]);
            setLoginUser(false);
          }, 1000);
        }, 1000);
      }
      if (userSocket.command === "ACCESS-FINISH" && userSocket.to === ids.uid) {
        setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        }, 1000);
        tracks[0].setEnabled(false);
        tracks[1].setEnabled(false);
        client.unpublish();
        setTrackState({ video: false, audio: false });
        setActionsUsers({
          command: "HAND-DOWN",
          name: dataUser.name,
          id: attendeeid,
          uid: ids.uid,
          uid_desktop: ids.uid_desktop,
        });
      }

      if (userSocket.command === "ACCESS-FINISH" && userSocket.to !== ids.uid) {
        setTimeout(() => {
          setSpeakers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== userSocket.to);
          });
        }, 5000);
      }
      if (
        userSocket.command === "ACCESS-DEVICES-M" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "MANAGER", name: dataUser.name });
          setTimeout(() => {
            client.leave();
            client.removeAllListeners();
            setSpeakers([]);
            setLoginUser(false);
          }, 1000);
        }, 1000);
      }
      if (
        userSocket.command === "ACCESS-FINISH-M" &&
        userSocket.to === ids.uid
      ) {
        setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        setTimeout(() => {
          setRolAuditorium({ rol: "ATTENDEE", name: dataUser.name });
        }, 1000);
        tracks[0].setEnabled(false);
        tracks[1].setEnabled(false);
        client.unpublish();
        setTrackState({ video: false, audio: false });
      }

      if (
        userSocket.command === "ACCESS-FINISH-M" &&
        userSocket.to !== ids.uid
      ) {
        setTimeout(() => {
          setSpeakers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== userSocket.to);
          });
        }, 5000);
      }
      if (userSocket.type === "screen") {
        if (
          userSocket.command === "DESKTOP-SHARING" &&
          userSocket.name !== dataUser.name
        ) {
          screenClientJoin();
        } else if (
          userSocket.command === "DESKTOP-UNSHARING" &&
          userSocket.name !== dataUser.name
        ) {
          setScreens([]);
          screenClient.leave();
          screenClient.removeAllListeners();
          setLoginScreen(false);
        }
      }
    }
  }, [userSocket]);

  useEffect(() => {
    if (rolAuditorium.rol !== "ATTENDEE") getMicrophone();
    return () => {
      screenClient.removeAllListeners();
      screenClient.leave();
      client.removeAllListeners();
      client.leave();
    };
  }, []);

  useEffect(() => {
    setRecordingText(true);
    setTimeout(() => {
      setRecordingText(false);
    }, 5000);
  }, [isCloudRecording]);

  try {
    return (
      <div className="vid-mobile">
        <div className="header-screen">
          <div style={{ width: "90%" }}>
            <div className="title-screen-mobile">{event.event.title}</div>
            <div className="subtitle-screen-mobile">
              <img
                src={`${process.env.REACT_APP_EVENT_IMG}/human-greeting.svg`}
                width={25}
              />
              {event.event.subtitle}
            </div>
          </div>
          <DropdownMenuMobile />
        </div>
        {isCloudRecording && (
          <div className={`popup-rec ${!recordingText ? "dinamyc-width" : ""}`}>
            <span style={{ color: "red" }}>◉</span>{" "}
            <span
              className={`text-recording ${
                !recordingText ? "dinamyc-width-text" : ""
              }`}
            >
              <FormattedMessage id="screen.popupRec" />
            </span>
          </div>
        )}
        {inProcess && (
          <div className={`popup-rec`}>
            <span style={{ color: "red" }}>◉</span>{" "}
            <span className={`text-recording`}>
              <FormattedMessage id="screen.processVideo" />
            </span>
          </div>
        )}
        {tracks && rolAuditorium && rolAuditorium.rol !== "ATTENDEE" ? (
          <FullScreen handle={handle}>
            {popupMicrophones && (
              <div className="popup-mic">
                <button
                  className="btn-close"
                  onClick={() => setPopupMicrophones(false)}
                >
                  X
                </button>
                <p style={{ textAlign: "center", marginTop: "0" }}>
                  <FormattedMessage id="videocall.mics" />
                </p>
                {microphones.map((mic) => (
                  <div
                    key={mic.deviceId}
                    className="items-popup-mic"
                    onClick={() => changeMicrophone(mic.deviceId)}
                  >
                    <div className="mic-icon">
                      <img src={`${process.env.REACT_APP_EVENT_IMG}/mic.svg`} />
                    </div>{" "}
                    {mic.label}
                  </div>
                ))}
              </div>
            )}
            <div>
              <RoomAuditorioMobile
                users={speakers.length > 0 ? speakers : []}
                screens={screens}
                type={"PRINCIPAL"}
                controls={{
                  tracks: tracks,
                  setBackgroundColor,
                  setBackgroundImage,
                  setBackgroundBlurring,
                  backgroundDisable,
                  setSelected,
                  sharedScreen: sharedScreen,
                  unSharedScreen: unSharedScreen,
                  users: speakers,
                  localScreen: localScreen,
                  client: client,
                  desktop: screenClient,
                  fullScreen: handle,
                  trackState: trackState,
                  setTrackState: setTrackState,
                  isStreaming: isStreaming,
                  setIsStreaming: setIsStreaming,
                  ids: ids,
                  token: token,
                }}
              />
            </div>
            {recording && (
              <div className="text-rec">
                <FormattedMessage id="screen.textRec" />
              </div>
            )}
          </FullScreen>
        ) : (
          <FullScreen handle={handle}>
            <RoomAuditorioMobile
              users={speakers}
              localScreen={localScreen}
              screens={screens}
              controls={{
                fullScreen: handle,
                tracks: [],
                token: true,
                ids: true,
                setIsStreaming: setIsStreaming,
              }}
            />
            {rolAuditorium.rol === "ATTENDEE" && (
              <ControlAttende
                handle={handle}
                client={client}
                screenClient={screenClient}
                setSelected={setSelected}
                setSpeakers={setSpeakers}
                setLoginUser={setLoginUser}
                setLoginScreen={setLoginScreen}
                setScreens={setScreens}
                ids={ids}
                typeRoom={typeRoom}
              />
            )}
            {recording && (
              <div className="text-rec">
                <FormattedMessage id="screen.textRec" />
              </div>
            )}
          </FullScreen>
        )}
      </div>
    );
  } catch (error) {
    return <h1>Ocurrio un error</h1>;
  }
}

export default VideoCallMobile;
