import React from "react";
import { useParams } from "react-router-dom";
import ChannelForm from "../main/MainScreen/ChannelForm";
import VideoCallNetworkings from "./VideoCallNetworkings";

function VideoNetworkings({ token, ids, children }) {
  const { room } = useParams();

  return (
    <section className="videoMain">
      <div>
        <div>
          <div className={"display-video"}>
            <VideoCallNetworkings
              channelName={room}
              token={token}
              ids={ids}
            />
          </div>
        </div>
        {children}
      </div>
    </section>
  );
}

export default VideoNetworkings;
