import React from 'react'
import { FormattedMessage } from 'react-intl';

function ChannelFormSponsor(props) {
    const { setInCall } = props;
    
    return (
      <div className="joi" >
        <button
          className='join-sponsor'
          onClick={(e) => {
            e.preventDefault();
            setInCall(true);
          }}
        >
          <FormattedMessage id="controls.join" />
        </button>
      </div>
    );
}

export default ChannelFormSponsor